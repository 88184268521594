import React, { Component } from "react";

import SettingsList from "./settings/SettingsList";
import PageHeader from 'common/PageHeader';
import TTPTab from "ui/tabs/TTPTab";
import TextModelList from "../text-model/TextModelList";

import _ from 'i18n';
import {connect} from "react-redux";
import {setCurrentNavPage} from "../../actions/actions/params";


@connect()
export default class GenericEmailPage extends Component {

  state = {
    activeTab: MODELS_TEMPLATES_TAB,
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);

    this.props.dispatch(setCurrentNavPage("GENERIC_EMAILING"));

    if ([MODELS_TEMPLATES_TAB, MODELS_TAB].indexOf(query.get('tab')) > -1) {
      this.selectTab(query.get('tab'));
    }
  }

  selectTab = (activeTab) => {
    this.setState({ activeTab });
  }

  render() {
    const { activeTab } = this.state;
    let tabs = [
      {
        id: MODELS_TEMPLATES_TAB,
        label: "library",
      },
      {
        id: MODELS_TAB,
        label: "texts",
      }
    ];

    return <div id="generic-emailing-page">
      <PageHeader header="Generic Emailing" subHeader="Create and manage your transactionnal emails ." icon="GENERIC_EMAIL">
        <button className="btn secondary text-uppercase"
          onClick={() => this.props.history.push("/email")}>
          <img src="img/icons/add.svg" />
          {_('Manage your emails')}
        </button>
      </PageHeader>
      <div className="ttp-tab-container">
        <TTPTab tabs={tabs} activeTab={activeTab} selectTab={this.selectTab} />
        <SettingsList visible={activeTab == MODELS_TEMPLATES_TAB} />
        <div className={`${activeTab != MODELS_TAB ? "hide" : ""}`}>
          <TextModelList isGenericEmailPage={true}/>
        </div>
      </div>
    </div>
  }
}

export const MODELS_TAB = "texts";
export const MODELS_TEMPLATES_TAB = "library";
