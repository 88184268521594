import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { saveTheme, fetchTemplatesCategories } from 'thunks';
import { onError, onSuccess, modalStyle, getLanguages, ucFirst } from 'utils';
import TTPSelect from "common/TTPSelect";
import _ from 'i18n';
import { deductTemplateType, prepareHtml } from "../../services/block/theme";
import TTPModal from "../ui/TTPModal";
import { getSupportedAppsOptions } from "../../services/apps";
import { hasValidLength } from "../../services/validation";
import { NotificationManager } from "react-notifications";

class SaveThemeForm extends PureComponent {

  constructor(props) {
    super(props);

    this.handleSubjectChange = (e) => this.setState({ object: e.target.value });
    this.handleLanguageChange = (language) => this.setState({ language });
    this.handleTargetAppChange = (targetApp) => this.setState({ targetApp });
    this.handleNameChange = (e) => this.setState({ name: e.target.value, nameHasError: !hasValidLength(e.target.value, 2, 50) });
    this.handleCategoryChange = (themeCategory) => this.setState({ themeCategory });
    this.handleMainChange = () => this.setState({ main: (this.state.main) ? 0 : 1 });

    this.state = {
      ...props,
      targetApp: props.targetApp || 'EMAILING',
      value: props.categories && this.getCategoryValue(),
      options: props.categories && this.getCategories(),
      themeObject: props.currentTheme?.themeObject,
      themeCategory: null,
      nameHasError: false
    };
    if (!props.language) {
      this.state.language = 'all';
    }
  }

  componentDidMount() {
    this.props.dispatch(fetchTemplatesCategories());
  }

  getCategories = () => {
    const { lng, categories } = this.props;
    let lang = ucFirst(lng);
    return categories.filter(category => category['organization']).map(category => {
      let label = category[`name${lang}`] || category['nameFr'];
      return { id: category.id, value: label, label };
    })
  };

  getCategoryValue = () => {
    const { lng, themeCategory, categories } = this.props;
    let lang = ucFirst(lng);
    if (!themeCategory || !categories?.length) {
      return null;
    }
    return categories.find(category => category.id === themeCategory)[`name${lang}`];
  };

  saveTheme() {
    const { onCloseModal, dispatch, currentClient } = this.props;
    const { id, name, object, language, isPublic, themeCategory, targetApp, organization, nameHasError, themeObject } = this.state;

    if (nameHasError) {
      NotificationManager.info(_('please fill in all the required fields'));
      return;
    }

    unlayer.exportHtml(({ html, design }) => {
      const type = deductTemplateType(html);

      let data = { name, themeObject, object, content: prepareHtml(html), design, language, themeCategory, type: type || "DRAG_AND_DROP", targetApp };
      if (!isPublic && organization && organization.id === currentClient.id) {
        data.id = id;
      }

      dispatch(saveTheme(data)).then(
        (res) => {
          let theme = null;
          if (res.value && res.value.data && res.value.data.data) {
            theme = res.value.data.data;
          }
          onCloseModal(theme);
          onSuccess(res);
        },
        (err) => onError(err)
      )
    });
  }

  handleChange = (option) => {
    this.setState({ themeCategory: option ? option.id : "", value: option.value });
  };

  render() {
    const { onCloseModal, updating } = this.props;
    const { name, language, type, targetApp, value, options, nameHasError } = this.state;

    return <TTPModal isOpen={true} onClose={() => onCloseModal(null)}
      modalStyle={{ ...modalStyle, content: { ...modalStyle.content, overflow: "visible", padding: 0 } }}
      title={_('Save template')}>
      <div id="theme-form" className="templates-settings__body">
        <div className="row align-center">
          <div className="row small-8 align-justify">
            <div className="column small-6">
              <span className="param-label" >{_('language')}</span>
              <div className="setting-param">
                <TTPSelect
                  simple={true}
                  notClearable={true}
                  values={language}
                  placeholder={_('lang')}
                  options={getLanguages(true).map(({ key, value }) => { return { id: key, label: _(value).toUpperCase() }; })}
                  onChange={(lng) => this.handleLanguageChange(lng)} />
              </div>
            </div>
            <div className="columns small-6">
              <span className="param-label">{_('Category')}</span>
              <div className="setting-param">
                <TTPSelect
                  simple={true}
                  isObjects={true}
                  notClearable={true}
                  values={value}
                  options={options}
                  placeholder={_('Category')}
                  onChange={this.handleChange} />
              </div>
            </div>
            <div className="columns small-6">
              <span className="param-label">{_('application')}</span>
              <TTPSelect
                simple={true}
                notClearable={true}
                values={targetApp}
                options={getSupportedAppsOptions()}
                onChange={this.handleTargetAppChange.bind(this)} />
            </div>
            <div className="column small-6">
              <span className="param-label">{_('title')} <i className="asterisk">*</i></span>
              <div className="setting-param">
                <input id="theme-name" type="text" value={name}
                  className={`${nameHasError ? 'is-invalid-input' : ""}`}
                  onChange={this.handleNameChange}
                  onFocus={e => e.target.classList.remove('border-danger')} />
              </div>
              <span className={nameHasError ? 'form-error is-visible' : 'form-error'}> {_('invalidName')}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="templates-settings__footer">
        <button onClick={() => onCloseModal(null)} className="btn secondary">{_('cancel')}</button>
        <button onClick={!updating ? this.saveTheme.bind(this) : undefined} className="btn primary">{updating ? _('Processing...') : _("save")}</button>
      </div>
    </TTPModal>
  }
}

const mapStateToProps = store => ({
  updating: store.themes.updating,
  categories: store.themes.categories.items,
  navigateBackUrl: store.themes.navigateBackUrl,
  lng: store.auth.user.language,
  currentClient: store.auth.currentClient,
})


export default compose(
  connect(mapStateToProps),
  withRouter
)
  (SaveThemeForm);
