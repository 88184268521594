import axios from '../axios-api';
import { throwCatchedError } from 'utils';

export const getMediaImages = ({
  token,
  clientId,
  pageSize,
  paginationPage,
  searchWord,
  language,
  tags,
  scope,
  docType
}) => {

  const fields = ["*"];

  let filter = [{
    property: "docType",
    operator: "eq",
    value: docType
  }];

  if (clientId && scope == "private") {
    filter.push(
      {
        property: "organization",
        operator: "eq",
        value: clientId
      }
    )
  } else if (scope == "public") {
    filter.push(
      {
        property: "isPrivate",
        operator: "eq",
        value: false
      }
    )
  }

  if (searchWord) {
    filter.push({
      property: "titleFr",
      operator: "like",
      value: searchWord
    });
  }

  if (language && language.length > 0) {
    filter.push({
      property: "languages",
      operator: "in",
      value: language,
    });
  }

  if (tags && tags.length > 0) {
    filter.push({
      property: "tag.id",
      operator: "in",
      value: tags
    })
  }

  const sort = [
    {
      property: "createdAt",
      dir: "desc"
    }
  ];

  const requestUrl = `/media/media`;

  let params = {
    access_token: token,
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort),
    fields: fields.join(","),
  };

  if (pageSize && paginationPage) {
    params = { ...params, limit: pageSize, start: (paginationPage - 1) * pageSize };
  } else {
    params.nolimit = 1;
  }

  return axios.get(requestUrl, { params }).catch(function (thrown) {
    throwCatchedError(thrown)
  });
};

export const getMailingImages = ({
  token,
  clientId,
  pageSize,
  paginationPage,
  searchWord,
  language,
  mediaType }) => {
  const fields = ['*'];
  const requestUrl = `/mailing/campaign-media`;

  let filter = [
    {
      property: 'client',
      value: clientId,
      operator: 'eq'
    },
    {
      property: 'mediaType',
      value: (mediaType && mediaType.length > 0) ? mediaType : "THEME_IMAGE",
      operator: 'in'
    }
  ];

  let sort = [];
  sort.push({
    property: "createdAt",
    dir: "desc"
  });


  if (searchWord) {
    filter.push({
      property: 'label',
      value: searchWord,
      operator: 'like'
    });
  }

  if (language && language.length > 0) {
    filter.push({
      property: "language",
      operator: "in",
      value: language
    })
  }

  let params = {
    access_token: token,
    fields: fields.join(','),
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort)
  };

  if (pageSize && paginationPage) {
    params = { ...params, limit: pageSize, start: (paginationPage - 1) * pageSize };
  } else {
    params.nolimit = 1;
  }

  return axios.get(requestUrl, { params });
};

export const persistMedia = (token, data) => {
  const requestUrl = `/mailing/campaign-media`;
  var formData = new FormData();

  formData.append('access_token', token);
  formData.append('label', data.label);
  formData.append('language', data.language);
  formData.append('mediaType', data.mediaType ? data.mediaType : "THEME_IMAGE");
  formData.append('client', data.clientId);
  formData.append('type', "image");

  if (data.attachment instanceof File) {
    formData.append('file', data.attachment, data.attachment.name);
  }
  if (data.id) {
    formData.append('id', data.id);
  }
  return axios.post(requestUrl, formData);
};
export const persistMediaImage = (token, file) => {
  const requestUrl = `/media/media/upload-media`;

  var formData = new FormData();
  formData.append("access_token", token);
  if (file instanceof File) {
    formData.append('file', file);
  }
  return axios.post(requestUrl, formData);
};

