import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from "html-to-draftjs";
import { MANUAL_RECIPIENT_TYPE, PAYMENT_RECIPIENT_TYPES, SURVEY_RECIPIENT_TYPES } from "../config/Common";
import { resolvePaymentType } from './payment';

export const getStatusColorClass = (status, isOpened = 0, clickedAt = null, filterStatus = null, approved = 0, lastTestversion = null, scheduledDeliveryAt = null, isScheduledAtExpired = false) => {
  let colorClass = "draft";
  if (((["RECIPIENT_LIST_CREATED", "SUCCESS", "UNREAD", "SENT"].indexOf(status) > -1 || (["CREATED"].indexOf(status) > -1 && !scheduledDeliveryAt)) && isOpened === 0 && !clickedAt && (!filterStatus || filterStatus && ["SENT"].indexOf(filterStatus) === -1)) || (["CREATED"].indexOf(status) > -1 && !lastTestversion && scheduledDeliveryAt)) {
    colorClass = "primary";
  } else if (["PAUSE", "READY"].indexOf(status) > -1) {
    colorClass = "warning";
  } else if (["OPEN"].indexOf(status) > -1 && isOpened !== 0 && !clickedAt) {
    colorClass = "success-opened";
  } else if (["HALT", "FAILED", "REFUSED", "ERROR"].indexOf(status) > -1) {
    colorClass = "alert";
  } else if (isOpened !== 0 && clickedAt) {
    colorClass = "info";
  } else if (["DELETED"].indexOf(status) > -1) {
    colorClass = "deleted";
  } else if (["SENT"].indexOf(filterStatus) > -1) {
    colorClass = "sent";
  } else if ((["TEST"].indexOf(status)> -1 && lastTestversion)) {
    switch (approved) {
      case 0: colorClass = "warning"; break;
      case 1: colorClass = "primary"; break;
      case 2: colorClass = "alert"; break;
    }
  } else if (["CREATED"].indexOf(status) > -1 && lastTestversion && scheduledDeliveryAt && !isScheduledAtExpired) {
    switch (approved) {
      case 0|1: colorClass = "primary"; break;
      case 2: colorClass = "alert"; break;
    }
  } else if (["CREATED"].indexOf(status) > -1 && isScheduledAtExpired) {
    colorClass = "warning";
  }

  return colorClass;
};

export const getCampaignStatus = (campaignItem, isExpiredScheduledDate = false) => {
  let status = campaignItem.status;
  if (status === "CREATED" && !campaignItem.scheduledDeliveryAt) {
    status = "READY";
  } else if (status === "CREATED" && !campaignItem.lastTestversion && campaignItem.scheduledDeliveryAt) {
    status = "scheduled sending";
  } else if (status === "TEST" && campaignItem.lastTestversion) {
    switch (campaignItem.approved) {
      case 0: status = "APPROVAL REQUEST"; break;
      case 1: status = "approved"; break;
      case 2: status = "APPROVAL REJECTED"; break;
    }
  } else if (status === "CREATED" && campaignItem.lastTestversion && campaignItem.scheduledDeliveryAt && !isExpiredScheduledDate) {
    switch (campaignItem.approved) {
      case 0: status = "APPROVAL REQUEST"; break;
      case 1: status = "scheduled sending"; break;
      case 2: status = "APPROVAL REJECTED"; break;
    }
  } else if (["CREATED"].indexOf(status) > -1 && isExpiredScheduledDate) {
    switch (campaignItem.approved) {
      case 0: status = 'scheduled_date_expired'; break;
      case 2: status = "APPROVAL REJECTED"; break;
    }
  }
  return status;
};

export const createEditorState = (html) => {
  if (!html) {
    return EditorState.createEmpty();
  }
  const blocksFromHTML = htmlToDraft(html);
  const editorState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );

  return EditorState.createWithContent(editorState);
}

export const initGECampaign = (query) => {
  const targetApp = query.get('targetApp');
  const targetType = query.get('targetType');

  switch (targetApp) {
    case "SURVEY": {
      return {
        recipientType: SURVEY_RECIPIENT_TYPES[0],
        surveyType: {
          "SURVEY_INVITATION": "INVITATION",
          "SURVEY_REMINDER": "REVIVAL",
          "SURVEY_PARTIAL_REMINDER": "PARTIAL_REVIVAL",
          "SURVEY_RESULTS": "SURVEY_RESULTS",
          "SURVEY_THANK": "PARTICIPATIONS",
        }[targetType] || "INVITATION",
        surveyInstance: null,
      };
    }
    case "PAYMENT": {
      let eventFilters = [];
      if (query.get('rappelType')) {
        eventFilters.push({ property: 'rappelType', operator: 'eq', value: query.get('rappelType') })
      }
      if (query.get('appRef')) {
        eventFilters.push({ property: 'appRef', operator: 'in', value: JSON.parse(query.get('appRef')) || [] })
      }
      if (query.get('appType')) {
        eventFilters.push({ property: 'appType', operator: 'eq', value: query.get('appType') })
      }
      if (query.get('discriminant')) {
        eventFilters.push({ property: 'discriminant', operator: 'eq', value: query.get('discriminant') })
      }

      return {
        recipientType: PAYMENT_RECIPIENT_TYPES[0],
        eventFilters

      };
    }
    default:
      return {
        recipientType: MANUAL_RECIPIENT_TYPE,
      }
  }
};


export const resolveTargetTypeFromQuery = (query) => {
  const targetApp = query.get('targetApp');
  let targetType = query.get('targetType');

  if (targetApp == 'PAYMENT' && !query.get('targetType') && query.get('rappelType') && query.get('discriminant')) {
    targetType = resolvePaymentType(query.get('discriminant'), query.get('rappelType'));
  }

  if (targetApp == 'PAYMENT' && !query.get('targetType') && query.get('rappelType') == 'manual' && query.get('discriminant')) {
    targetType = resolvePaymentType(query.get('discriminant'), query.get('rappelType'));
  }

  return targetType
}
