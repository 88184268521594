export * from './block';

export const initTemplates = () => {
  return {
    type: 'INIT_THEMES',
  };
};

export const initTemplatesList = () => {
  return {
    type: 'INIT_THEMES_LIST',
  };
};

export const fetchTemplates = (resp) => {
  return {
    type: 'FETCH_THEMES',
    payload: resp
  };
};

export const saveTheme = (resp) => {
  return {
    type: 'SAVE_THEME',
    payload: resp
  };
};

export const sendThemeTest = (resp) => {
  return {
    type: 'SEND_THEME_TEST',
    payload: resp
  };
};

export const multiSaveTheme = (resp) => {
  return {
    type: 'MULTI_SAVE_THEME',
    payload: resp
  };
};

export const setThemeSearchWord = (searchWord) => {
  return {
    type: 'SET_THEME_SEARCH_WORD',
    searchWord
  }
};

export const initThemeFilters = () => {
  return {
    type: "INIT_THEME_FILTERS"
  }
}

export const setIsPublicTheme = (isPublicTheme) => {
  return {
    type: 'SET_THEME_IS_PUBLIC',
    isPublicTheme
  }
};

export const setThemeListSort = (sortDirection) => {
  return {
    type: 'SET_THEME_LIST_SORT',
    sortDirection
  }
};

export const setThemeLanguage = (language) => {
  return {
    type: 'SET_THEME_LANGUAGE',
    language
  }
};

export const setThemeType = (typeT) => {
  return {
    type: 'SET_THEME_TYPE',
    typeT
  }
};

export const setThemeApplication = (application) => {
  return {
    type: 'SET_THEME_APPLICATION',
    application
  }
};

export const setThemesPaginationPage = (pageNumber) => {
  return {
    type: 'SET_THEMES_PAGINATION_PAGE',
    pageNumber
  };
};

export const setThemesPageSize = (pageSize) => {
  return {
    type: 'SET_THEMES_PAGE_SIZE',
    pageSize
  };
};

export const setThemeVisibility = (visibility) => {
  return {
    type: 'SET_THEME_VISIBILITY',
    visibility
  };
};

export const updateThemeVisibility = (resp) => {
  return {
    type: 'UPDATE_THEME_VISIBILITY',
    payload: resp
  };
};

export const softDeleteThemesWithAssociatedCampaigns = (resp) => {
  return {
    type: 'SOFT_DELETE_THEMES_WITH_ASSOCIATED_CAMPAIGNS',
    payload: resp
  };
};

export const fetchTemplatesCategories = (resp) => {
  return {
    type: 'FETCH_THEMES_CATEGORIES',
    payload: resp
  };
};

export const setThemeContent = (content) => {
  return {
    type: 'SET_THEME_CONTENT',
    payload: content
  };
};

export const setNavigateBackUrl = (url) => {
  return {
    type: 'SET_NAVIGATE_BACK_URL',
    payload: url
  };
};

export const setCurrentDndTheme = (theme) => {
  return {
    type: 'SET_CURRENT_DND_THEME',
    theme
  };
};

export const setCurrentSimpleTheme = (theme) => {
  return {
    type: 'SET_CURRENT_SIMPLE_THEME',
    theme
  };
};

export const setThemeScope = (scope) => {
  return {
    type: 'SET_THEME_SCOPE',
    scope
  }
}

export const setThemeCategory = (category) => {
  return {
    type: 'SET_THEME_CATEGORY',
    category
  }
}

export const setSelectedTheme = (theme) => {
  return {
    type: 'SET_SELECTED_THEME',
    theme
  }
}

export const setThemeIsSaved = (isSaved) => ({
  type: "SET_THEME_IS_SAVED",
  isSaved
});

export const saveThemeCategory = (resp) => {
  return {
    type: 'SAVE_THEME_CATEGORY',
    payload: resp
  };
};

export const removeThemeCategory = (resp) => {
  return {
    type: 'REMOVE_THEME_CATEGORY',
    payload: resp
  };
};

export const editThemeCategory = (resp) => {
  return {
    type: 'EDIT_THEME_CATEGORY',
    payload: resp
  };
};

export const fetchTemplate = (resp) => {
  return {
    type: 'FETCH_THEME',
    payload: resp
  };
};
