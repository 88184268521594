import ThemeSelector from "./ThemeSelector";
import DragDropEditor from "../../editor/DragDropEditor";
import BlocksContainer from "../../blocks/BlocksContainer";
import React from "react";
import CampaignPage from "../CampaignPage";
import NSTemplateSelector from "./NSTemplateSelector";
import WrapperSelector from "./WrapperSelector";
import { SVG_VALID_ICON } from "../../../services/svgIcones";

const CHOOSE_SAMPLE_STEP = {
  label: "Choose a model",
  actions: [{
    label: "proceed",
    cssClass: "success",
    key: "secondaryAction",
  }],
  body: ThemeSelector,
};

const DND_EDITOR_STEP = {
  label: "Compose my email",
  body: ThemeSelector,
  actions: [
    {
      label: "{{}}",
      cssClass: "success",
      key: "openDynamicFieldsAction",
    },{
      label: "save the template",
      cssClass: "primary-steps",
      key: "primaryAction",
      icon: SVG_VALID_ICON
    },
    {
      label: "proceed",
      cssClass: "success",
      key: "secondaryAction",
    }
  ],
};

const ADD_NEWSLETTER_CONTENT = {
  label: "Add content",
  actions: [
    {
      label: "save template",
      cssClass: "primary-steps",
      key: "primaryAction",
      icon: SVG_VALID_ICON
    }, {
      label: "proceed",
      cssClass: "success",
      key: "secondaryAction",
    }
  ],
  body: () => <h1>NS STEP</h1>,
};

const SEND_CAMPAIGN_STEP = {
  label: "Send campaign",
  actions: [
    {
      label: "save campaign",
      cssClass: "primary-steps",
      key: "primaryAction",
      icon: SVG_VALID_ICON
    }, {
      label: "send",
      cssClass: "primary",
      key: "sendAction",
    }
  ],
  body: CampaignPage,
};

const NEWSLETTER_DND_EDITOR_STEP = {
  label: "Adapt my email",
  body: ThemeSelector,
  actions: [{
    label: "proceed",
    cssClass: "success",
    key: "secondaryAction",
  }],
};

export const getCampaignTypeData = (type = "text") => {
  return {
    dnd: {
      icon: "DRAG_DROP",
      title: "Emailing editor",
      steps: [
        {
          ...CHOOSE_SAMPLE_STEP,
          body: ThemeSelector,
        },
        {
          ...DND_EDITOR_STEP,
          body: DragDropEditor,
        },
        {
          ...SEND_CAMPAIGN_STEP,
        },
      ],
    },
    newsletter: {
      icon: "NEWSLETTER",
      title: "Newsletter",
      steps: [
        {
          ...CHOOSE_SAMPLE_STEP,
          body: NSTemplateSelector,
        }, {
          ...ADD_NEWSLETTER_CONTENT,
          body: BlocksContainer,
        },
        {
          ...NEWSLETTER_DND_EDITOR_STEP,
          body: DragDropEditor,
        },
        {
          ...SEND_CAMPAIGN_STEP,
        },
      ],
    },
    text: {
      icon: "TEXT_EDITOR",
      title: "Rich text editor",
      steps: [
        {
          ...CHOOSE_SAMPLE_STEP,
          body: WrapperSelector,
        },
        {
          ...DND_EDITOR_STEP,
          body: DragDropEditor,
        },
        {
          ...SEND_CAMPAIGN_STEP
        }
      ]
    },
    web: {
      icon: "FROM_URL",
      title: "From URL",
      steps: [
        {
          ...SEND_CAMPAIGN_STEP
        }
      ]
    },
  }[type];
};

