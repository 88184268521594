export default {
  home: "E-mailen",
  "Send your emailings": "Stuur uw e-mailings",
  "to the look & feel of your community ":
    "naar de look & feel van je community",
  "with little effort!": "met weinig moeite!",
  "Create and send": "Maken en verzenden",
  signIn: "Aanmelden / Inschrijven",
  "About Tamtam": "Over Tamtam",
  owrApps: "OWR Apps",
  pageNotFound: "zou deze pagina kan niet gevonden!",
  goBackToHome: "Ga terug naar de startpagina",
  logout: "uitloggen",
  help: "helpen",
  replayable: "Bevat de optie: Herbekijken",
  free: "Vrij",
  Speakers: "luidsprekers",
  Dashboard: "Dashboard",
  Communities: "Gemeenschappen",
  campaigns: "Campagnes",
  webPage: "webpagina",
  previous: "Voorgaand",
  next: "Volgend",
  filter: "Filter",
  Barchart: "het aantal verzonden campagnes",
  filters: "Filters",
  "invitation type": "Type uitnodiging",
  "roles status": "Rollen status",
  status: "Staat",
  DELETED: "DROOGTE",
  ERROR: "FOUT",
  RECEIVED: "ONTVANGEN",
  UNREAD: "ONGELEZEN",
  SENT: "VERZONDEN",
  RECIPIENT_LIST_CREATED: "Wordt uitgevoerd",
  CREATED: "CREËERT",
  language: "Taal",
  reset: "Reset",
  searchBySubject: "Zoek op onderwerp",
  cancel: "annuleer",
  save: "Opslaan",
  codeOwn: "je eigen code",
  createdAt: "Gemaakt bij",
  groups: "Groepen",
  recipients: "Ontvangers",
  recipient: "ontvanger",
  reviews: "beoordelingen",
  sent: "Verzending",
  enterText: "Bericht...",
  enterName: "voer een naam in",
  deleteCampaignConfirm: "Wil je echt wilt de campagne wilt verwijderen?",
  deleteManualRecipientsConfirm:
    "Weet u zeker dat u alle e-mails wilt verwijderen?",
  deleteAll: "Verwijder alles X",
  delete: "verwijderen",
  fromEmail: "van email",
  fromName: "van naam",
  replyTo: "antwoord aan",
  recipientType: "Ontvanger Type",
  contactType: "Contacttype",
  settings: "settings",
  typeTestEmail:
    "Typ de auquelles mail adressen die u wilt op de proef te sturen",
  warning: "Warning",
  groupsIncluded: "groepen opgenomen",
  excludedGroups: "GROEPEN UITGESLOTEN",
  selectGroups: "Selecteer Groepen",
  excludeGroups: "uitsluiten groepen",
  "Select event": "Selecteer evenement",
  "Select invitation type": "Selecteer het uitnodigingstype",
  "Select visitor type": "Selecteer bezoeker type",
  "Select slots": "Selecteer slots",
  "Select activities": "Selecteer activiteiten",
  "Exclude activities": "Activiteiten uitsluiten",
  "Select status": "Selecteer de status",
  "Exclude status": "Status uitsluiten",
  "Select options": "Selecteer opties",
  "Select formulas": "Selecteer formules",
  sendTitle: "Je staat op het punt om een campagne naar te sturen",
  tested: "getest",
  attachments: "toebehoren",
  confirm: "BEVESTIGEN",
  confirmSendCampaign: "Weet u zeker dat u deze campagne wilt verzenden?",
  noNotNow: "NEE, NIET NU",
  yesSend: "JA, ZEND",
  sendTestTo: "Stuur de test",
  sendEmailTestTo: "Stuur test e-mail naar",
  sendTest: "STUUR TEST",
  send: "sturen",
  PLAIN_TEXT: "TEKST",
  DND_TEMPLATE: "MODEL",
  WEB_PAGE: "WEB PAGINA",
  selectTarget: "Selecteer gerichte doelgroepen",
  campaignInfo: "Campagne info",
  invalidObject:
    "object moet minimaal 5 tekens bevatten en mag niet meer dan 200 tekens bevatten",
  invalidFromName: "Ongeldige naam, vul dan meer dan 4 karakters",
  invalidEmail: "e-mailadres ongeldig",
  "invalid link": "ongeldige link",
  emailAdd: "Voeg e-mails toe en typ ENTER",
  userAdd: "Voeg een gebruiker toe en tik op ENTER",
  deleteCampaignSuccess: "succesvolle verwijdering",
  campaignDeleted: "De campagne werd succesvol verwijderd",
  campaignTestSent: "Een test e-mail is succesvol verzonden",
  campaignTestSuccess: "succesvolle test",
  campaignSent: "De campagne is verzonden",
  campaignSentSuccess: "het verzenden van een succesvolle",
  campaignAdded: "De campagne is opgeslagen",
  addCampaignSuccess: "toe te voegen",
  successfulAction: "Succesvolle actie",
  operationCompleted: "Actie uitgevoerd met succes",
  errorOccured: "Er is een fout opgetreden, Gelieve te proberen later.",
  "Sorry there was a problem processing your request":
    "Sorry, er is een probleem opgetreden bij het verwerken van uw verzoek",
  incompleteOperation:
    "Kan de operatie niet voltooien, onjuiste gegevens zijn ingediend",
  invalidUrl: "Kan de bewerking te voltooien, werd een ongeldige URL ingediend",
  error: "fout",
  enterUrl: "URL invoeren",
  search: "Zoeken",
  deliveredTo: "Bezorgd bij",
  emailsOpen: "E-mails open",
  linkClicks: "Links klikken",
  Bounce: "",
  open: "opening",
  complaint: "klacht",
  "bounce details": "rebound details",
  permanent_bounce: "nieuwe permanente rebound",
  old_permanent_bounce: "oude permanente rebound",
  transient_bounce: "tijdelijke bounce",
  undetermined_bounce: "onbepaalde rebound",
  bounced: "stuiterde",
  "bounce type": "type rebound",
  bounce_info:
    "BOUNCE beschrijft een niet-bezorgde e-mail vanwege: een persistent probleem (bijv .: domeinnaam bestaat niet meer of slechte syntaxis van e-mail), of een probleem tijdelijk (bijvoorbeeld: de server van de ontvanger is niet beschikbaar of de inbox is vol",
  Spam: "",
  recipientsMessage: "dit filter beschouwt taal :",
  pauseCampaign:
    "De onderbreking van de campagne voorkomt het verzenden <br> van meer e-totdat het wordt gereactiveerd",
  pauseSending: "elke onderbreking bevordert e-mails verzenden",
  stopCampaign: "zeker stoppen met het sturen campagne",
  resume: "overzicht",
  register_from_email:
    "Klik hier om via Amazon een e-mail te sturen via Amazon, klik op INDIENEN voor meer informatie over het proces.",
  reply_aws_email:
    "Er is een email gestuurd naar Amazon, Antwoord en Vernieuwen",
  replyToError:
    "Vul een geldig e-mail of tekst in de indeling : naam <email@example.com>",
  validate: "Bevestigen",
  refresh: "verversen",
  GUEST: "GAST",
  SPEAKER: "SPREKER",
  CONTACT: "",
  PARTNER: "",
  Download: "Downloaden",
  DownloadCSV: "CSV downloaden",
  notOpenYet: "Nog niet geopend",
  notClickedYet: "Nog niet geklikt",
  firstName: "Voornaam",
  lastName: "Naam",
  userGroup: "Gebruikersgroep",
  openedAt: "Zal openen",
  clickedAt: "Klik naar",
  live: "En Live",
  replay: "En Replay",
  this_week: "Deze week",
  Training_type: "Trainingstype",
  "send an email": "STUUR EEN EMAIL",
  PROSPECT: "VOORUITZICHT",
  MAILING_LIST: "mailinglijst",
  SURVEY_TARGETS: "onderzoeksdoelen",
  templateChoices: "Selecteer een sjabloon",
  "first name": "Voornaam",
  "last name": "achternaam",
  stopCampaignConfirm: "Wil je deze campagne zeker stoppen?",
  stop: "HOU OP",
  event: "Gebeurtenis",
  "Included activities": "Ingesloten activiteiten",
  "Excluded activities": "Uitgesloten activiteiten",
  "Included status": "Ingesloten status",
  "Excluded status": "Uitgesloten status",
  Formulas: "Formules",
  Slots: "Slots",
  Options: "Opties",
  all: "alle",
  templates: "De modellen",
  NEXT: "VOLGENDE",
  emailCampaigns: "e-mail campagne",
  "Are you sure you want to delete this email ?":
    "Weet je zeker dat je deze e-mail wilt verwijderen?",
  smsCampaigns: "sms campagne",
  "Enter your message": "vul je bericht in",
  fromPhoneNumber: "VAN PHONE NUMBER",
  smsMessage: "SMS bericht",
  searchByCampaignName: "Zoeken op campagnenaam",
  testRecipientsNameAdd: "Voeg de namen van de ontvangers toe en tik op ENTER",
  typeTestRecipients: "Voeg de ontvangers in waarnaar u de test wilt verzenden",
  campaignSMSTestSent: "Een test sms is succesvol verzonden",
  nothing_found: "Oeps, Er zijn geen resultaten gevonden voor uw zoekopdracht",
  empty_sms_campaigns_list: "Uw organisatie heeft nog geen sms-campagnes",
  empty_sms_bills_list: "Uw organisatie heeft nog geen sms-rekeningen",
  empty_sms_credit_purchase_list:
    "Op dit moment zijn er geen aanvragen voor sms-creditaankopen",
  empty_recipients_list: "Geen ontvangers om op dit moment weer te geven",
  yes: "ja",
  no: "neen",
  french: "frans",
  english: "engels",
  dutch: "nederlands",
  overview: "overzicht",
  view: "zien",
  "The campaign report": "Het campagnerapport",
  links: "links volgen",
  unapproved: "goedgekeurde",
  approved: "aangenomen",
  approvals: "goedkeuringen",
  approval_preferences: "Goedkeuringsvoorkeuren voor een e-mailcampagne",
  "send me a test at": "stuur me een test op",
  "send to approval teams": "stuur naar goedkeurders",
  "the approvers": "de fiatteurs",
  "Send your campaign immediately": "Verstuur uw campagne onmiddellijk",
  "Schedule updating of your campaign for a specific time":
    "Plan het updaten van uw campagne voor een specifieke tijd",
  "Add a comment": "Voeg een reactie toe",
  "Schedule sending of your campaign for a specific time":
    "Plan het verzenden van uw campagne voor een specifieke tijd",
  "approval is required": "goedkeuring is vereist",
  "automatic sending": "verzenden gebeurt automatisch",
  "the approval threshold": "de goedkeuringsdrempel",
  automatic_sending_paragraph:
    "stuur de campagne automatisch wanneer de goedkeuringsdrempel wordt bereikt zonder afwijzing",
  approval_obligatory_paragraph:
    "de e-mailcampagne kan niet zonder goedkeuring worden verzonden",
  minimum_acceptance_paragraph:
    "het minimumaantal goedkeuringen dat vereist is om de campagne goed te keuren",
  no_member_found:
    "Voeg leden toe aan uw team, leden beoordelen uw campagne voordat ze worden verzonden",
  "Configure your community's test  preferences":
    "Configureer de testvoorkeuren van uw community",
  "Oops no approval team found": "Er is geen goedkeuringsteam gevonden",
  teams: "teams",
  "Add team": "Voeg een team toe",
  "Are you sure you want to delete this team ?":
    "Weet je zeker dat je dit team wilt verwijderen ?",
  members: "lid",
  "Edit team": "Bewerk het team",
  "Create team": "Creëer een team",
  "Add a team description": "Voeg een beschrijving van het team toe",
  "Add a member": "Voeg een lid toe",
  "delivery date": "datum en tijdstip van verzending",
  "Drag and drop some files here, or click to select files to upload.":
    "Sleep enkele bestanden hier, of klik op om bestanden te selecteren om te uploaden.",
  Attachments: "toebehoren",
  attachment: "gehechtheid",
  reason: "reden",
  "Thank you for approving this campaign":
    "Bedankt voor het goedkeuren van deze campagne",
  "Your view is taken into consideration":
    "Uw mening wordt in overweging genomen",
  "You are about to reject the campaign, please specify the reason":
    "U staat op het punt de campagne af te wijzen, geef de reden op",
  "You are about to approve the campaign":
    "U staat op het punt de campagne goed te keuren",
  approve: "goedkeuren",
  about_us_content: `is een geheel van tools die een bepaalde groep toelaat om te communiceren, uitwisselen, organiseren en doeltreffend samenwerken. Het platform bevat verschillende applicaties samengevoegd naargelang de noden van de klant. Dankzij deze tools, beschikken onze klanten over de laatste technologie.`,
  createEmail: "maak een e-mailcampagne",
  createSms: "maak een sms-campagne",
  createCampaign: "Maak campagne",
  smsSendTitle: "U staat op het punt een sms-campagne te sturen naar",
  campaignName: "Naam van de campagne",
  smsOpen: "open SMS",
  unsent: "Niet verzonden",
  originator: "verzender",
  remainingChars: "Resterende karakters",
  chars: "Afdruk",
  pauseSendingSMS: "pauzeer het verzenden van verdere sms-berichten",
  pauseSMSCampaign:
    "De onderbreking van de campagne voorkomt het verzenden <br> van meer sms-berichten het wordt gereactiveerd",
  smsTotalCount: "totaal aantal SMS",
  poorCredit: "  Uw tegoed is onvoldoende om de campagne te verzenden!",
  richCredit: "  Uw tegoed is voldoende, u heeft nog steeds:",
  "You can send the campaign.": "U kunt de campagne verzenden.",
  " You only have: ": "  Je hebt alleen:",
  "No approval request is sent for this release":
    "Er is geen goedkeuringsverzoek verzonden voor deze release",
  "Sent to": "Verzonden naar",
  "Delivered to": "Bezorgd aan",
  "approvers feedback for the test version":
    "feedback van fiatteurs voor de testversie",
  version: "versie",
  preview: "voorvertoning",
  upload: "uploaden",
  apply: "toepassen",
  Close: "Dichtbij",
  "Once you close your changes will be lost. Do you really want to continue?":
    "Je wijzigingen gaan verloren. Wil je echt doorgaan?",
  "INSERT FIELDS": "VOEG VELDEN IN",
  "search by title": "zoeken op titel",
  none: "geen",
  "default model": "standaard sjabloon",
  "last modified on": "laatst gewijzigd op",
  by: "door",
  share: "aandeel",
  replicate: "kopiëren",
  original: "origineel",
  replication: "kopiëren",
  LEGAL_REPRESENTATIVE: "Wettelijke vertegenwoordiger",
  OFFICIAL: "Officieel",
  EXTERNAL: "Extern",
  MANAGER: "Manager",
  "Apply authorization to selected users":
    "Toestemming toepassen op geselecteerde gebruikers",
  Apply: "Toepassen",
  "New user": "Nieuwe gebruiker",
  "Select All": "Selecteer alles",
  "Deselect All": "Deselecteer alles",
  "allow deletion": "laat verwijdering toe",
  "allow change": "schakel verandering in",
  "In progress": "Bezig",
  Buffered: "gebufferd",
  "Are you sure you want to delete this model":
    "Weet je zeker dat je deze sjabloon wilt verwijderen",
  "Are you sure you want to hide this model":
    "Weet u zeker dat u dit model wilt verbergen",
  "sms finances": "sms financiën",
  "SMS Bills": "SMS-rekeningen",
  Recharge: "Koop tegoed",
  " Request": " Bestellen",
  buyCreditDesc:
    "Voer (in €) het aantal credits in waarmee u uw SMS-saldo opnieuw wilt laden in het volgende frame.",
  buyCreditInfo: "Met uw tegoeden kunt u berichten naar elk land verzenden.",
  requestSent: "Uw bestelling is succesvol verzonden",
  sendRequestSuccess: "Succesvol bestellen",
  PENDING: "WACHTEN",
  VALIDATED: "GEVALIDEERD",
  REJECTED: "VERWORPEN",
  "No, allow for all users": "Nee, sta alle gebruikers toe",
  "Yes, allow for certain users": "Ja, sta voor bepaalde gebruikers toe",
  "Restrict the use of this template": "Beperk het gebruik van deze sjabloon",
  "Select approval teams": "Selecteer goedkeuringsteams",
  "user preferences": "gebruikersvoorkeuren",
  "Add an image to the library": "Voeg een afbeelding toe aan de bibliotheek",
  privilege: "privilege",
  resend: "terugsturen",
  "Phone numbers with check mark": "Telefoonnummers met een vinkje",
  "have been verified by our system": "zijn geverifieerd door ons systeem",
  lists: "lijsten",
  "Create list": "Maak een lijst",
  "Edit list": "Om lijst te bewerken",
  "is sms list": "is een sms-lijst",
  "search by name": "zoeken op naam",
  subscribers: "abonnees",
  "mandatory list": "verplichte lijst",
  "no description": "geen beschrijving",
  name: "naam",
  Credit: "krediet",
  Cost: "kosten",
  remove_from_list:
    "Weet je zeker dat je de contactpersoon uit deze lijst wilt verwijderen?",
  "manage subscribers": "abonnees beheren",
  "add list": "Voeg een lijst toe",
  "mailing lists subscriptions": "abonnementen op mailinglijsten",
  "selected items": "geselecteerde items",
  buffered:
    "Message&nbsp;is&nbsp;on&nbsp;the&nbsp;network&nbsp;and&nbsp;awaiting&nbsp;delivery&nbsp;to&nbsp;the&nbsp;handset.&nbsp;Buffered&nbsp;will&nbsp;usually&nbsp;appear&nbsp;when&nbsp;there&nbsp;is&nbsp;either&nbsp;network&nbsp;or&nbsp;handset&nbsp;congestion",
  detailed_bill: "SMS-campagne gedetailleerde factuur",
  "exclude event registrants": "uitgesloten registranten van evenementen",
  subscribe: "inschrijven",
  options: "opties",
  "embedded form": "ingesloten vorm",
  "copy/paste onto your site": "kopiëren en plakken op uw site",
  copy: "kopiëren",
  "copied !": "gekopieerd !",
  campaign_name: "SMS Campagne naam",
  "TOTAL APPROXIMATE COST": "TOTALE AANPASSINGS KOSTEN",
  "invalid price": "ongeldige prijs",
  total_delivered_recipients: "Totaal ontvangers",
  total_delivered_sms: "Totaal SMS",
  amount_due: "Totaal bedrag",
  country: "Land",
  unit_price: "Eenheid prijs",
  delivered_by_country: "Bezorgde ontvangers",
  sms_delivered_by_country: "Verzonden sms'jes",
  total_amount_by_country: "Bedrag",
  sms_length_warning:
    "Speciale karakters<br>U hebt speciale tekens (unicode) gebruikt die niet in een standaard SMS-bericht kunnen worden weergegeven. Kies een optie hieronder:<br>Stuur een sms met alleen standaardtekens. De speciale tekens worden vervangen door hun standaard equivalenten of, indien nodig, verwijderd.<br>Toon speciale tekens in mijn SMS-bericht. Waarschuwing! In dit geval kan de sms slechts 70 tekens bevatten. De tekenteller past zich overeenkomstig aan.",
  approximateCostInfo:
    "De totale kosten zijn bij benadering na het invoegen van dynamische velden",
  "TOTAL COST": "TOTALE KOSTEN",
  sms_warning_lead: "Special characters",
  sms_warning_length_disclaimer:
    "Attention‼ the length of your SMS depends on the characters you use.",
  sms_warning_explanation:
    "Currently, you are using special characters that can not be displayed in a standard SMS message without affecting its size.",
  sms_waring_more_precisely: "More precisely",
  sms_warning_gsm_7bit_guideline:
    "these characters are counted for two characters in an SMS, instead of one :",
  sms_warning_utf16_guideline:
    "Special Characters (such as á é í ó ú à è ë ï ö ü ĳ …) reduce the capacity of the SMS to only 70 characters instead of 160.",
  sms_warning_advice:
    "In order to avoid the disadvantages of this particular use, we invite you to use only standard characters.",
  sms_warning_gsm_7bit_ext_list: "⏎  \\  ^  ~  [  ] {  } |  €",
  sms_warning_counter_disclaimer:
    "De tekenteller past zich dienovereenkomstig aan.",
  validationCreditRequestConfirm: "Wilt u de kredietaanvraag echt valideren?",
  creditRequestValidated: "Kredietaanvraag a is succesvol gevalideerd",
  validateCreditSuccess: "Succesvolle validatie",
  date: "datum",
  "client name": "klantnaam",
  Reject: "Afwijzen",
  rejectionCreditRequestConfirm:
    "Weet je zeker dat je het kredietverzoek wilt weigeren?",
  creditRequestRejected: "Het kredietverzoek werd succesvol afgewezen",
  rejectCreditSuccess: "Succesvolle afwijzing",
  updatedAt: "Bijgewerkt om",
  authorized_balance: "Toegestane saldo",
  current_balance: "Huidige saldo",
  "include form title": "inclusief formuliertitel",
  "show only required fields": "toon alleen verplichte velden",
  "show required field indicators": "toon vereiste veldindicatoren",
  "show language choices": "toon taalkeuzes",
  required: "verplicht",
  resend: "terugsturen",
  "Nothing to show": "Niets om te laten zien",
  "add new subscribers": "nieuwe abonnees toevoegen",
  "remove subscribers": "abonnees verwijderen",
  unsubscribe: "Uitschrijven",
  unsubscribed: "afgemeld",
  undelivered: "niet afgeleverd",
  "do you want to add new subscribers to this list?":
    "wil je nieuwe abonnees toevoegen aan deze lijst?",
  "do you want to remove subscribers from this list?":
    "wil je abonnees uit deze lijst verwijderen?",
  "Processing...": "Verwerken ...",
  Processing: "Verwerken",
  "Select the mailing lists in which your prospects will be registered":
    "Selecteer de mailinglijsten waarin uw prospects worden geregistreerd",
  "Select lists": "Selecteer lijsten",
  "Loading suggestions ...": "Loading suggesties ...",
  "search by label": "zoeken op label",
  buffered:
    "Het bericht bevindt zich op het netwerk en wacht op aflevering op de handset.<br>Gebufferd zal meestal verschijnen wanneer er een netwerk- of handopstopping is",
  containDynamicFields: "Bevat dynamische velden",
  CREDIT: "KREDIET",
  DEBIT: "DEBITEREN",
  "sms credit validation": "credit validatie sms",
  "send me a test email": "stuur me een testmail",
  "show mailing lists choice": "toon de keuze van mailinglijsten",
  "Please choose the lists to which you want to subscribe":
    "Kies de lijsten waarop u zich wilt abonneren",
  back: "Terug",
  campaign_created_at: "Gemaakt bij ",
  at: "op",
  "Emailing Chart": "",
  "filter by language": "filter op taal",
  "filter by status": "filter op status",
  "Nothing Found": "niets gevonden",
  Balance: "Saldo",
  no_approval_sent: "Geen goedkeuringsverzoek verzonden voor deze versie",
  "Sent at": "Verzonden op",
  Language: "Taal",
  Telefoonnummer: "Numéro de téléphone",
  "Copy/paste your target's emails":
    "Kopieer en plak de e-mails van je doelwit",
  create: "Maak een",
  Targets: "Targets",
  stats: "Statistieken",
  Name: "Naam",
  Country: "Land",
  "SMS Count": "Aantal SMS'jes",
  "not available": "niet beschikbaar",
  "IN PROGRESS": "Bezig",
  SUCCESS: "Geslaagd",
  READY: "IN AFWACHTING VAN",
  FAILED: "Mislukt",
  BUFFERED: "Gebufferd",
  approval: "Goedkeuring",
  "SMS approximate cost": "kosten van de campagne",
  "SMS cost": "Geschatte kosten van de campagne",
  edit: "bewerken",
  resume: "hervatten",
  "stop definitely": "stop met verzenden",
  report: "verslag",
  show: "Tonen",
  block: "Blokkeren",
  manage: "Beheren",
  "sms bill detail": "SMS factuur details",
  invalid_object_warning:
    "Voeg een <u>object</u> toe dat de inhoud van uw e-mail duidelijk beschrijft.",
  content_warning: "Voeg <u>inhoud</u> toe",
  from_name_warning:
    "Kies <u>een naam</u>, zoals uw bedrijfsnaam, die uw abonnees helpt u te herkennen.",
  invalid_from_email_warning:
    "Kies een <u>geldig e-mailadres</u> dat uw ontvangers zien wanneer ze uw campagne ontvangen.",
  recipient_count_warning:
    "U hebt minimaal 1 <u>ontvanger</u> nodig om een campagne te verzenden",
  approval_required_warning:
    "Uw campagne heeft <u>goedkeuring</u> nodig, het is niet mogelijk om deze te verzenden totdat uw fiatteurs deze hebben geaccepteerd",
  scheduled_approval_required_warning:
    "uw campagne kan worden gepland, maar deze wordt alleen verzonden op de ingevoerde datum als uw <u>goedkeurders</u> deze hebben geaccepteerd",

  pending: "In afwachting",
  select_formule: "Selecteer formule",
  cant_submit_approval:
    "Omdat deze campagne al is verzonden of verwijderd, kunt u uw goedkeuring niet indienen",
  "Thank you for your collaboration": "Bedankt voor de samenwerking",
  "see more": "bekijk meer",
  "see less": "zie minder",
  "approvers's feedback": "feedback van fiatteurs",
  "invalid phone number": "ongeldig telefoonnummer",
  smsManualTargets:
    "Voer de mobiele telefoonnummers van uw doelgroep in internationaal formaat in",
  invalidPhoneNumber: "ongeldig telefoonnummer of ongeldig formaat",
  "invalid campaign name": "ongeldige campagnenaam",
  empty_e_box: "Je e-box is op dit moment leeg",
  sender: "afzender",
  "Drag and drop a screenshot here, or click to download.":
    "Versleep hier een screenshot of klik om te downloaden.",
  "You rejected the campaign successfully, please add the reason for this rejection":
    "U hebt de campagne met succes afgewezen, voeg de reden voor deze afwijzing toe",
  "Sort by": "Sorteer op",
  "oldest first": "de oudste",
  "newest first": "de meest recente",
  FIDUCIARIES_CLIENT: "CLIENTS OF FIDUCIARY",
  from: "van",
  searchByMail: "Zoek per e-mail",
  "view profile": "bekijk profiel",
  "page url": "URL van de pagina",
  ebox_empty: "Oeps, E-box leeg voor het moment",
  "Find other Ebox": "Zoek andere Ebox",
  "Search for recipient": "Zoek naar ontvanger",
  change: "bewerken",
  "You haven't set a default image yet, choose one":
    "U hebt nog geen standaardafbeelding ingesteld, kies er een",
  "an image of  600 pixels in width is better displayed":
    "een afbeelding van 600 pixels breed wordt beter weergegeven",
  "content manager": "content manager",
  confirmResendEmail: "Weet u zeker dat u deze e-mail opnieuw wilt verzenden?",
  to: "naar",
  toMyEBox: "keer terug naar mijn ebox",
  of: "van",
  showing: " ",
  emails: "e-mails",
  no_attachments: "Geen bijlagen om te bekijken op dit moment!",
  Gallery: "Galerij",
  "List of attachments received": "Lijst met ontvangen bijlagen",
  fields: "velden",
  "add new model": "voeg een nieuwe sjabloon toe",
  "update the model": "update het model",
  models: "modellen",
  "The title is required!": "Onderwerp is verplicht!",
  title: "titel",
  "apply a model": "een model toepassen",
  "Duplication options": "Duplicatie-opties",
  content: "de inhoud",
  template: "het model",
  duplicate: "duplicaat",
  "recipients and filters": "ontvangers en filters",
  "sms models": "sms modellen",
  smsModelsPreviewMessage:
    "Handmatige modus: dynamische velden worden niet in aanmerking genomen.",
  web_version: "webversie",
  social_orientation_row: "Rij",
  social_orientation_column: "Kolom",
  social_theme_type_logo: "Logo",
  social_theme_type_square: "Plein",
  social_theme_type_rounded: "afgeronde",
  social_theme_type_circle: "Cirkel",
  social_theme_color_colored: "gekleurde",
  social_theme_color_black: "Zwart",
  social_theme_color_white: "Wit",
  social_theme_color_colored_bordered: "Gekleurde randen",
  social_theme_color_black_bordered: "Zwart omzoomd",
  social_theme_color_white_bordered: "Wit omzoomd",
  "Search by name": "Zoeken op naam",
  Customize: "Aanpassen",
  "Save template": "Bewaar sjabloon",
  "create the campaign": "maak de campagne",
  "save as template": "opslaan als sjabloon",
  test: "test",
  Static: "Statisch",
  Dynamic: "Dynamisch",
  "Rich Text": "Rich Text",
  "From URL": "Van URL",
  "Create your custom template email with simple drag&drop.":
    "Maak uw aangepaste sjabloon e-mail met eenvoudige drag & drop.",
  "Use the rich text editor to create simple emails.":
    "Gebruik de rich-texteditor om eenvoudige e-mails te maken.",
  "Easily create your own custom newsletter.":
    "Maak eenvoudig uw eigen aangepaste nieuwsbrief.",
  "Create a campaign by importing the HTML code directly from a hosted URL.":
    "Maak een campagne door de HTML-code rechtstreeks vanuit een gehoste URL te importeren.",
  "my templates": "mijn sjablonen",
  empty_recipients_list: "de lijst met ontvangers is leeg",
  "To get started, set a URL to import.":
    "Stel om te beginnen een URL in om te importeren.",
  "Drag and drop a screenshot here, or click to download.":
    "Versleep hier een screenshot of klik om te downloaden.",
  "You have already approved this version of campaign test on":
    "U heeft deze versie van campagnetest al goedgekeurd",
  "You have already rejected this version of campaign test on":
    "Je hebt deze versie van campagnetest al afgewezen",
  "you still want to resubmit?": "wil je nog steeds opnieuw indienen?",
  "main GDPR alert": "hoofd RGPD-waarschuwing",
  "image gallery": "afbeeldingen galerij",
  scheduledFor: "Gepland voor",
  header: "hoofd",
  footer: "footer",
  "campaign approval": "approval of email campaigns",
  "Manage email models and newsletters that are ready to be sent .":
    "Beheer e-mailmodellen en nieuwsbrieven die klaar zijn om te worden verzonden.",
  "Create, manage and send your email campaigns .":
    "Creëer, beheer en verzend uw e-mailcampagnes .",
  "Create, manage and send your sms campaigns .":
    "Maak, beheer en verzend uw sms-campagnes .",
  "Set the approval workflow settings for your campaigns .":
    "Stel de goedkeuringswerkstroominstellingen in voor uw campagnes.",
  "view your invoices and your payment history .":
    "bekijk uw facturen en uw betalingsgeschiedenis .",
  HALT: "bestel",
  "page size": "pagina grootte",
  share_template_info:
    "Gedeelde sjablonen zijn zichtbaar en kunnen door de hele community worden gebruikt, terwijl privé-sjablonen alleen toegankelijk zijn voor de community van de eigenaar.",
  shared: "gedeelde",
  private: "privaat",
  "editor theme": "redacteur thema",
  "tool alignment": "uitlijning van gereedschap",
  "start date": "begin datum",
  "end date": "einddatum",
  "sending date": "Verzenddatum",
  "date range": "datumbereik",
  no_images: "Geen afbeeldingen om te bekijken op dit moment!",
  "Images used": "Gebruikte afbeeldingen",
  "Images available": "Afbeeldingen beschikbaar",
  scope: "strekking",
  tags: "labels",
  DELIVERED: "GELEVERD",
  "NOT DELIVERED": "NIET GELEVERD",
  OPENED: "GEOPEND",
  "simple email": "eenvoudige e-mail",
  newsletter: "nieuwsbrief",
  "Oops, no layouts were found.": "Oeps, er zijn geen lay-outs gevonden.",
  categories: "categorieën",
  "Select a layout for your block first":
    "Selecteer eerst een lay-out voor uw blok",
  layouts: "lay-outs",
  "Choose the content and layout of your blocks":
    "Kies de inhoud en lay-out van uw blokken",
  "generate the blocks": "genereer de blokken",
  generate: "genereer",
  use: "gebruiken",
  "load blocks": "laad blokken",
  revival: "raise",
  "partial revival": "gedeeltelijke stimulus",
  participations: "Deelnemingen",
  "survey results": "onderzoeksresultaten",
  invitation: "Uitnodiging",
  application: "toepassing",
  survey: "overzicht",
  survey_invitation_detail:
    "je leden die later de basis zullen zijn van je opwekkingscampagnes",
  survey_revival_detail:
    "ontvangers zijn degenen die uw uitnodigingscampagnes hebben ontvangen maar niet op de enquête hebben gereageerd",
  survey_partial_revival_detail:
    "ontvangers die de enquête nog niet hebben ingevuld",
  survey_participations_detail:
    "ontvangers die hebben deelgenomen aan de enquête",
  survey_results_detail: "Ontvangers onderschreven het enquêteresultaat",
  "survey title": "titel van de enquête",
  "send a test": "stuur een test",
  discover: "Ontdek",
  favorites: "favorieten",
  featured: "uitgelicht",
  "List of NewsLetters received .": "Lijst met ontvangen nieuwsbrieven .",
  "survey url": "enquête url",
  add: "toevoegen",
  days: "dagen",
  "change the template": "verander de sjabloon",
  "Generic Emailing": "Generieke Emailing",
  combinations: "combinaties",
  "notify talk community channel":
    "meld het gesprek met het gemeenschapskanaal",
  "contains a newsletter ?": "bevat een nieuwsbrief ?",
  "Read more": "Lees verder",
  wrapper: "wikkel",
  "Manage your transactional mailings": "Beheer uw transactionele mailings",
  "Select a text model": "Selecteer een tekstmodel",
  "Choose a template (optional)": "Kies een model (optioneel)",
  "add text model": "tekstsjabloon",
  textModelAdded: "Er is een nieuwe tekstsjabloon toegevoegd",
  subject: "onderwerpen",
  body: "lichaam",
  "filled blocks": "gevulde blokken",
  speakers: "luidsprekers",
  "duplicate the model": "dupliceer het model",
  "edit the model": "bewerk het model",
  "Generic model": "Generiek model",
  textModelUsed: "dit tekstmodel wordt gebruikt in een combinatie",
  deleteTextModelConfirm:
    "Weet je zeker dat je dit tekstmodel wilt verwijderen?",
  PARTNER_GUEST: "partner gast",
  "visitor type": "type bezoeker",
  undefined: "niet gedefinieerd",
  invited: "uitgenodigd",
  congress: "congreslid",
  "staff member": "personeelslid",
  partner: "partner",
  institute: "institute",
  speaker: "woordvoerder",
  organization: "organisatie",
  guest: "uitgenodigd",
  target: "doelwit",
  "Select application": "Selecteer applicatie",
  "Select type": "Selecteer type",
  "program link": "Programma link",
  "Apply the model": "Pas de sjabloon toe",
  "Apply the model": "Pas het model toe",
  Applied: "Toegepast",
  Required: "Verplicht",
  Please: " ",
  apply: "Pas een",
  "a text model": "tekstmodel toe",
  step: "stap",
  updateSuccess: "Succesvolle verandering",
  duplicateSuccess: "Succesvolle duplicatie",
  textModelDuplicated: "Het tekstmodel is succesvol gedupliceerd",
  textModelUpdated: "Het tekstmodel is succesvol aangepast",
  invalidTitle:
    "Titel moet minimaal 3 tekens en niet meer dan 200 tekens bevatten.",
  campaign_duplicated_successfully: "de campagne is succesvol gedupliceerd",
  "user privileges": "gebruikersrechten",
  "Set the privileges of your users .":
    "Stel de rechten van uw gebruikers in .",
  ALL: "ALLE",
  user: "gebruiker",
  "Emailing roles": "e-mailrollen",
  "Organization roles": "Organisatierollen",
  "Legal Representative": "Wettelijke vertegenwoordiger",
  Official: "officieel",
  External: "Extern",
  USER: "GEBRUIKER",
  NONE: "GEEN",
  ADMIN: "BEHEERDER",
  Preferences: "Voorkeuren",
  "emailing privileges": "e-mailrechten",
  "SMS privileges": "SMS-rechten",
  "and ": " en ",
  "Create email campaigns": "Maak e-mailcampagnes",
  "Create sms campaigns": "Maak sms-campagnes",
  "View your emails.": "Bekijk uw e-mails.",
  "Create and manage your transactionnal emails .":
    "Maak en beheer uw transactionele e-mails .",
  "Create combination": "combinatie maken",
  "next step": "volgende stap",
  "Send campaigns's approvals requests to teams":
    "Verzend campagnes goedkeuringsverzoeken naar teams",
  "Notifications of updates": "Meldingen van updates",
  "TAMTAM applications": "TAMTAM-toepassingen",
  Event: "Evenement",
  Survey: "Enquête",
  continue: "doorgaan",
  editor: "editor",
  invalid_campaign_name_warning:
    "Voeg een <u>Naam van de campagne</u> toe dat de inhoud van uw e-mail duidelijk beschrijft.",
  sms_message_warning: "Voeg <u>een sms-bericht</u> toe",
  not_enough_credit_warning:
    "Het is niet mogelijk om uw sms-campagne te verzenden totdat u een tegoed hebt gekocht",
  "Phone numbers with check marks<br>have been verified by our system":
    "Telefoonnummers met vinkjes <br> zijn geverifieerd door ons systeem",
  "validate users sms credit requests.":
    "valideer sms-aanvragen van gebruikers.",
  "Your campaign will be sent in": "Uw campagne wordt verzonden",
  "Add this email to the user and marque it as main.":
    "Voeg deze e-mail toe aan de gebruiker en markeer deze als de primaire.",
  "The email has been successfully resend, but it can not be added to the user :":
    "de e-mail is opnieuw verzonden, maar kan niet worden toegevoegd aan de gebruiker: ",
  "that email is already assigned to another account.":
    "die e-mail is al toegewezen aan een ander account.",
  seconds: "seconden",
  hours: "uur",
  "events's guests": "evenementen gasten",
  notificationPushTitle: "Stuur de melding naar:",
  "Create notification": "Maak een melding",
  "Notifications list": "Meldingenlijst",
  "Create, manage and send mobile notifications .":
    "Maak, beheer en verzend mobiele meldingen .",
  createNotification: "melding maken",
  invalid_object_notification_warning:
    "Voeg een <u>object</u> toe  dat de inhoud van uw melding duidelijk beschrijft.",
  recipient_count_notification_warning:
    "U moet ten minste 1 <u>ontvanger</u> hebben om een campagne te verzenden",
  deleteNotificationConfirm: "Weet u zeker dat u de melding wilt verwijderen?",
  notificationDeleted: "De melding is succesvol verwijderd",
  notification_duplicated_successfully: "de melding is succesvol gedupliceerd",
  duplicateNotificationConfirm: "Wilt u de melding echt dupliceren?",
  "review not yet submitted": "beoordeling nog niet ingediend",
  "rejected at": "afgewezen om",
  comment: "commentaar",
  "Type your subject": "Typ je onderwerp",
  mailbox: "postbus",
  "list of emails and SMS.": "lijst met e-mails en SMS.",
  delivered: "geleverd",
  "view other email accounts": "bekijk andere e-mailaccounts",
  "find other E-box": "vind andere E-box",
  "E-Box not found": "E-Box niet gevonden",
  "no attachment found": "geen bijlagen gevonden",
  picture: "afbeelding",
  "attachment not found": "bijlage niet gevonden",
  "number of approvers": "aantal goedkeurders",
  "see attachment": "zie bijlage",
  params_lang_info: "de taal wordt geteld bij <br/> de selectie van ontvangers",
  users: "gebruikers",
  "You've moved so fast and got lost...":
    "Je bent zo snel gegaan en bent verdwaald...",
  Oops: "Oeps",
  "Confirmation of deletion": "Bevestiging van verwijdering",
  "Confirmation of duplication": "Bevestiging van duplicatie",
  "confirmation of sending": "Bevestiging van verzending",
  "Confirmation of rejection": "Weigering bevestiging",
  "Confirmation of validation": "Bevestiging van validatie",
  "Halt confirmation": "Bevestiging van het vonnis",
  Confirmation: "Bevestiging",
  ROLES_TYPES: "rollen typen",
  "send thank you": "stuur bedankt",
  SHAREHOLDER: "AANDEELHOUDER",
  QUALITY_MANAGER: "KWALITEITSMANAGER",
  "Select role status": "Selecteer rolstatus",
  ROLES_STATUS: "Rolstatus",
  COLLABORATOR: "MEDEWERKER",
  "Select a model to apply for each language":
    "Selecteer een sjabloon voor elke taal",
  informations: "nieuws",
  library: "bibliotheek",
  "Generic emails": "Generieke e-mails",
  UnavailableLanguagesNote: "Noot: vul de niet-beschikbare talen in",
  "The existing configuration has been loaded":
    "De bestaande configuratie is geladen",
  texts: "teksten",
  saveTextModelConfirmation:
    "Het doel is veranderd! Weet u zeker dat u het tekstmodel wilt opslaan?",
  "Data saved successfully": "Gegevens succesvol opgeslagen",
  "Add this language": "voeg deze taal toe",
  "Unavailable language": "Unavailable language",
  information: "informatie",
  "view preview": "bekijk voorbeeld",
  "emailing found": "e-mailen gevonden",
  "looks like you are not authorized to access this page":
    "het lijkt erop dat u geen toestemming heeft om deze pagina te openen",
  "contact your community admin": "neem contact op met uw communitybeheerder",
  "show all": "toon alles",
  "hide all": "verstop alles",
  "main email": "belangrijkste e-mailadres",
  submit: "indienen",
  "verified senders": "geverifierde e-mails",
  "reset emails": "e-mails resetten",
  reset_generic_emails:
    "Wanneer u de e-mails reset, laadt het platform de meest relevante e-mail opnieuw (die van uw community, of bij gebrek daaraan, die van het tamtam.pro-platform) en vervangt het de momenteel actieve e-mail",
  "Manage your emails": "Beheer uw e-mails",
  "emails types": "e-mailtypen",
  "Add your custom text": "Voeg uw eigen tekst toe",
  mainTextMessage: "Definieer deze modeltekst als de hoofdorganisatie",
  confirmDeleteEmail: "Weet je zeker dat je deze e-mail verwijdert?",
  yesDelete: "JA, VERWIJDEREN",
  "Manage your sending emails": "Beheer uw verzendende e-mails",
  complete: "voltooien",
  "Customize with dynamic fields": "Pas aan met dynamische velden",
  "list of campaigns": "lijst met campagnes",
  configuration: "configuraties",
  "campaign list": "campagnelijst",
  Main: "Belangrijkste",
  "allow create campaign": "toestaan om een campagne te maken",
  "allow send campaign": "Sta het verzenden van campagnes toe",
  role: "rol",
  " and ": " en ",
  DRAFT: "Droogte",
  organizer: "organisator",
  "main text": "hoofdteks",
  editMainTextConfirm: "Weet u zeker dat u deze hoofdtekst wilt maken?",
  UndoEditMainTextConfirm:
    "Weet u zeker dat u dit niet als hoofdtekst wilt gebruiken ?",
  mainTextModel: "Dit is een hoofdtekstmodel!",
  "save the template": "sla de sjabloon op",
  "template preview": "voorbeeld van sjabloon",
  "templates list": "sjablonen lijst",
  "load a template": "laad een sjabloon",
  "select an article layout": "selecteer een artikel layout ",
  "select a slot layout": "selecteer een slot layout ",
  "select a speaker layout": "selecteer een luidsprekeropstelling ",
  "select a static block": "selecteer een statisch blok ",
  "Send your sms campaigns": "Stuur uw sms-campagnes",
  "All blocks are filled": "Alle blokken zijn gevuld",
  newTemplate: "Naamloze sjabloon",
  "new message": "nieuw bericht",
  selectCommunity: "kies uw community",
  listCommunity: "Lijst van ledencommunitys",
  CommunityUnauthorised:
    "Deze community heeft geen toegang tot deze applicatie",
  small: "klein",
  normal: "normaal",
  large: "groot",
  huge: "reusachtig",
  partner_guest: "partner gast",
  coupon_model: "couponmodel",
  new: "nieuwe",
  administration: "toediening",
  "Attach a file": "Voeg een bestand toe",
  "Drag and drop a picture here, or click to select picture to upload.":
    "Sleep een afbeelding hiernaartoe en klik erop om een afbeelding te selecteren om te uploaden.",
  "Link title": "Titel van de link",
  "Link target": "Linkdoel",
  "Edit text": "Tekst bewerken",
  reset_generic_email:
    "Wanneer u de e-mails reset, laadt het platform de meest relevante e-mail opnieuw (die van uw community, of bij gebrek daaraan, die van het tamtam.pro-platform) en vervangt deze",
  "reset email": "e-mail opnieuw instellen",
  Context: "",
  reset_generic_email:
    "Wanneer u de e-mails reset, laadt het platform de meest relevante e-mail opnieuw (die van uw community, of bij gebrek daaraan, die van het tamtam.pro-platform) en vervangt deze",
  "reset email": "e-mail opnieuw instellen",
  ATTESTATION: "certificaat",
  ORDER: "Volgorde",
  INVOICE: "Factuur",
  TAG: "LABEL",
  ARTICLE: "ARTIKEL",
  CLICKED: "GEKLIKT",
  "my models": "mijn modellen",
  "shared models": "gedeelde modellen",
  width: "breedte",
  height: "hoogte",
  gravity: "zwaartekracht",
  North: "Noorden",
  South: "Zuiden",
  East: "Oosten",
  West: "West",
  Centre: "Centrum",
  Smart: "Slim",
  Blur: "Vervagen",
  BW: "zwart wit",
  Sharp: "Scherp",
  Category: "Categorie",
  "send a test": "stuur een test",
  desktop: "bureaublad",
  mobile: "mobiel",
  "write your email": "schrijf uw e-mail",
  "give a subject to your campaign": "geef een onderwerp voor uw campagne",
  "please fill in all the required fields": "vul alle verplichte velden in",
  proceed: "doorgaan",
  "Emailing editor": "E-maileditor",
  Newsletter: "Nieuwsbrief",
  "Rich text editor": "Editeur de texte enrichi",
  "Choose a model": "Kies een model",
  "Compose my email": "Stel mijn e-mail op",
  "Send campaign": "Stuur campagne",
  "Add content": "Content toevoegen",
  "Confirmation of switching models":
    "Bevestiging van schakelen tussen modellen",
  confirmSwitchTheme:
    "U staat op het punt uw model te wijzigen. Stijlen en inhoud worden gereset zodat ze overeenkomen met het nieuwe model! Weet je zeker dat je door wilt gaan ?",
  invalidName:
    "de titel moet minstens 20 karakters lang zijn en niet meer dan 50 karakters",
  "Please select a template to proceed":
    "Selecteer een sjabloon om door te gaan",
  "Are you sure you want to save this theme category ?":
    "Weet u zeker dat u deze themacategorie wilt opslaan?",
  "mailing lists": "Mail lijst",
  "New list": "Nieuwe lijst",
  Description: "Beschrijving",
  "Mandatory mailing list": "Verplichte mailinglijst",
  mailingListAdded: "De mailinglijst is succesvol opgeslagen",
  "Add new mailing list": "Nieuwe mailinglijst toevoegen",
  "Manage mailing lists .": "Beheer mailinglijsten .",
  mandatory: "verplicht",
  optional: "optioneel",
  confirmDeleteCategory:
    "Weet u zeker dat u deze themacategorie wilt verwijderen ?",
  "Enter the name and description of your mailing list":
    "Voer de naam en beschrijving van uw mailinglijst in",
  "languages available": "beschikbare talen",
  "You must enter at least one name !": "U moet minimaal één naam invoeren !",
  "There is no description for this mailing list !":
    "Er is geen beschrijving voor deze mailinglijst !",
  "theme categories": "thema categorieën",
  "Are you sure you want to delete this mailing list":
    "Weet u zeker dat u deze mailinglijst wilt verwijderen",
  "Edit mailing list": "Bewerk mailinglijst",
  mailingListEdited: "De mailinglijst is succesvol gewijzigd",
  editSuccess: "Succesvolle bewerking",
  "Add new theme category": "Nieuwe themacategorie toevoegen",
  "New theme category": "Nieuwe themacategorie",
  return: "terugkeer",
  "Manage users": "Gebruikers beheren",
  "Manage the categories of your themes":
    "Beheer de categorieën van uw thema's",
  "Manage private texts": "Beheer privéteksten",
  "Edit link": "Link bewerken",
  mask: "maskeren",
  unmask: "ontmaskeren",
  masked: "gemaskeerd",
  visibility: "zichtbaarheid",
  "3 langues available": "3 talen beschikbaar",
  "Type the title of your category": "Typ de titel van uw categorie",
  "List of categories": "Lijst met categorieën",
  "Add, modify or delete your categories":
    "Voeg uw categorieën toe, wijzig of verwijder ze",
  "save campaign": "campagne opslaan",
  "save template": "sjabloon opslaan",
  "You have": "Je hebt",
  "empty link(s) in your model": "lege link(s) in je model",
  received: "ontvangen",
  "Adapt my email": "Pas mijn e-mail aan",
  emailing: "e-mailen",
  event_cycle: "gebeurtenis cyclus",
  "APPROVAL REQUEST": "AANVRAAG VOOR GOEDKEURING",
  "APPROVAL REJECTED": "GOEDKEURING AFGEWEZEN",
  order: "bestelling",
  invoice: "factuur",
  credit_note: "kredietnota",
  PAYMENT_REMINDER: "BETALINGSHERINNERING",
  reminder: "herinnering",
  "reminder type": "type terugbellen",
  folder: "dossier",
  individual: "individueel",
  "client folder": "klantenmap",
  "Select client folder": "Selecteer de klantenmap",
  "Select role": "Selecteer rol",
  FOLDER: "DOSSIER",
  "my folders": "Mijn bestanden",
  folders: "mappen",
  "Select folder": "Selecteer map",
  Community: "samfunnet",
  "view your campaigns stats": "bekijk uw campagnes statistieken",
  "The evolution of the number of clicks and openings":
    "De evolutie van het aantal klikken en openingen",
  "the number of campaigns sent": "Het aantal verzonden campagnes",
  "number of unsubscriptions": "aantal afmeldingen",
  "number of bounces": "aantal bounces",
  "number of complaints": "aantal klachten",
  "The Evolution in campaign opening percentages":
    "De evolutie in de openingspercentages van campagnes",
  month: new Array(
    "Januari",
    "Februari",
    "Maart",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Augustus",
    "September",
    "Oktober",
    "November",
    "December",
  ),
  "Most popular URL": "Meest populaire URL\n",
  Clicks: "klikken\n",
  "Reach graph per month": "Bereikgrafiek per maand",
  "Evolution graph of undelivered emails":
    "Trendgrafiek van het aantal niet afgeleverde e-mails",
  "Engagement graph: number of clicks per campaign":
    "Betrokkenheidsgrafiek: aantal klikken per campagne",
  "reload the page": "herlaad de pagina",
  "links inventory": "links inventaris",
  "no link found": "geen link gevonden",
  "Evolution graph of the community's average scores":
    "Evolutiegrafiek van de gemiddelde communityscores",
  "commitment score": "inzet score",
  "free section": "gratis gedeelte",
  "Add a free section": "Voeg een gratis sectie toe",
  Question: "Vraag",
  example: "voorbeeld",
  TEXT: "TEKST",
  NUMBER: "AANTAL",
  "LONG DATE": "LANGE DATUM",
  "SHORT DATE": "KORTE DATUM",
  DATE: "DATUM",
  "Your campaign is now based on the text model":
    "Uw campagne is nu gebaseerd op het tekstmodel",
  "Text model changed successfully": "Tekstmodel succesvol gewijzigd",
  "Text color": "Tekst kleur",
  "Background color": "Achtergrond kleur",
  "Border color": "Rand kleur",
  Members: "Leden",
  OPT_IN_SOFT: "OPT IN SOFT",
  OPT_IN: "OPT IN",
  OPT_OLD: "OPT OLD",
  DEFAULT_MODE: "Standaard modus",
  Default: "Standaard",
  oeccbbMembers: "Oeccbb leden",
  FEMALE: "Vrouwen",
  MALE: "Mannen",
  monthly: "maandelijks",
  annual: "jaarlijks",
  fr: "France",
  en: "Engels",
  nl: "Nederlands",
  future: "Toekomst",
  past: "Verleden",
  model_object: "Modelonderwerp : ",
  dynamic_fields: "Dynamische velden :",
  sender_name: "NAAM AFZENDER",
  guest_barcode: "GAST BARCODE",
  event_id: "EVENEMENT ID",
  event_banner: "EVENEMENTBANNER",
  event_name: "EVENEMENT NAAM",
  event_date: "EVENEMENT DATUM",
  event_start_time: "BEGINDATUM VAN HET EVENEMENT",
  event_end_time: "EINDDATUM VAN HET EVENEMENT",
  event_place: "EINDDATUM VAN HET EVENEMENT",
  event_user_documents_url: "GEBRUIKERSDOCUMENTEN PAGINA",
  event_offfcourse_home_url: "OFFFCOURSE STARTPAGINA",
  event_contact_email: "EVENEMENT CONTACT E-MAIL",
  event_end_if_has_documents: "END IF HAS DOCUMENTS",
  event_program_url: "EVENT PROGRAMMA PAGINA",
  event_inscription_url: "EVENT REGISTRATIE PAGINA",
  event_payment_url: "EVENT BETAALPAGINA",
  event_documents_url: "EVENT DOCUMENTEN PAGINA",
  event_start_if_has_documents: "START IF HAS DOCUMENTS",
  event_evaluation_url: "EVENT EVALUATIEPAGINA",
  event_certificate_url: "EVENT EVALUATIEPAGINA",
  organizer_iban: "ORGANISATOR IBAN",
  organizer_bic: "ORGANISATOR BIC",
  organizer_name: "ORGANISATOR NAAM",
  recipient_first_name: "EERSTE NAAM ONTVANGER",
  recipient_last_name: "NAAM ONTVANGER",
  recipient_email: "E-MAIL ONTVANGER",
  recipient_guest_barcode: "GAST BARCODE",
  recipient_user_barcode: "GEBRUIKERS STREEPJESCODE",
  recipient_plan: "ONTVANGER FORMULE",
  recipient_program: "ONTVANGER FORMULE",
  certificate_comment: "CERTIFICAAT OPMERKING",
  certificate_hours: "CERTIFICAAT UREN",
  partner_guest_coupon: "PARTNER GASTEN COUPON",
  partner_name: "PARTNER NAAM",
  coupon_model_name: "NAAM COUPON-MODEL",
  coupon_model_comment: "COUPON-MODEL OPMERKING",
  cycle_id: "CYCLE ID",
  cycle_name: "CYCLE NAAM",
  cycle_contact_email: "CYCLE CONTACT E-MAIL",
  cycle_home_url: "CYCLE STARTPAGINA",
  cycle_program_url: "CYCLE PROGRAMMA PAGINA",
  cycle_inscription_url: "CYCLE REGISTRATIE PAGINA",
  cycle_payment_url: "CYCLE BETAALPAGINA",
  cycle_documents_url: "CYCLE DOCUMENTEN PAGINA",
  invoice_credit_note_url: "URL VOOR CREDITNOTA",
  survey_title: "titel van de enquête",
  selectType: "SELECTEER EEN TYPE",
  survey_url: "SURVEY URL",
  copie: "kopiëren",
  copied: "gekopieerd",
  attachedCampaigns: "De volgende campagnes zijn aan de sjabloon gekoppeld :",
  deleteModel: "Sjabloon verwijderen",
  delete_all: "Alles verwijderen",
  "Number of hours": "Aantal uren",
  "Number of clicks": "Aantal klikken",
  "Number of subscribers / unsubscribers": "Aantal abonnees / afmeldingen",
  "Openings data": "Openingsgegevens",
  "Bounces data": "Bounce-gegevens",
  "Incidents data": "Incidentgegevens",
  "Percentage of complaints": "Percentage klachten",
  "Number of mailings per language": "Aantal mailings per taal",
  "Evolution of the number of clicks": "Evolutie van het aantal kliekjes",
  "Evolution of the number of new subscribers / unsubscribers":
    "Evolutie van het aantal nieuwe abonnees / uitschrijvers",
  "Evolution of active people": "Evolutie van actieve mensen",
  "Evolution of the average score over time of the community":
    "Evolutie van de gemiddelde score in de tijd van de gemeenschap",
  "Evolution of the number of openings": "Evolutie van het aantal openingen",
  "My community": "Mijn gemeenschap",
  "Performance of my emailings": "Prestaties van mijn e-mailings",
  "Emailing in sending": "E-mailen in verzenden",
  "Community engagement": "Betrokkenheid bij de gemeenschap",
  "Average score": "Gemiddelde score",
  "Very active": "Heel actief",
  "Moderately active": "Redelijk actief",
  Inactive: "Inactief",
  "Number of persons": "Aantal personen",
  "Not very active": "Niet erg actief",
  "Percentage of openings": "Percentage openingen",
  "Number of subscribers": "Aantal abonnees",
  "Number of unsubscribers": "Aantal afmeldingen",
  Today: "Vandaag",
  days: "dagen",
  hours: "uur",
  minutes: "minuten",
  seconds: "seconden",
  showOnlyDefaultModels: "Toon alleen de standaardmodellen",
  "All the time": "Altijd",
  "Last Year": "Afgelopen jaar",
  "Last Month": "vorige maand",
  "Last Week": "vorige week",
  "Number of emails by type": "Aantal e-mails per type",
  "Best clicked links": "Beste links waarop is geklikt",
  "text model": "tekstmodel",
  "event revival": "herlancering evenement",
  EVENT_REVIVAL: "herlancering evenement",
  "event reminder": "evenementherinnering",
  EVENT_REMINDER: "evenementherinnering",
  year: "Jaar",
  source: "Bron",
  paid: "betaald",
  "Reset filter": "filter resetten",
  CURRENCY: "VALUTA",
  "Enter the text": "Voer de tekst in",
  "Select a date": "Selecteer een datum",
  "Enter a time": "Voer een tijd in",
  "Selected Filters": "Geselecteerde filters",
  "Clear Selected Filters": "Geselecteerde filters wissen",
  "CONFIGURE YOUR COMMUNITY'S STATISTICS":
    "CONFIGUREER DE STATISTIEKEN VAN UW GEMEENSCHAP",
  "Dashboard Page": "Dashboardpagina",
  "Activate / Deactivate statistics calculation":
    "Statistiekberekening activeren / deactiveren",
  CONFIGURATIONS: "CONFIGURATIES",
  enterFreeSection: "Configureer uw gratis secties om door te gaan!",
  "Select an option": "Kies een optie",
  "Add option": "Optie toevoegen",
  "Delete option": "Optie verwijderen",
  "Please enter options": "Voer alstublieft opties in",
  LIST: "LIJST",
  CYCLE_GUEST: "CYCLE GAST",
  "scheduled sending": "geplande verzending",
  "change sending date": "De verzenddatum wijzigen",
  scheduled_date_expired: "niet goedgekeurd | geplande datum verlopen",
  "Save as text model": "Opslaan als tekstmodel",
  "Answer on Tamtam": "Antwoord op Tamtam",
  "Save text model": "Tekstmodel opslaan",
  optionalName: "optioneel",
  "create new": "Maak een nieuwe",
  "save as": "opslaan als",
  "Create a chat conversation": "Creëer een chatgesprek",
  yesContinue: "JA, DOORGAAN",
  "No, fill in the links": "Nee, vul de links in",
  confirmContinue: "Weet je zeker dat je door wilt gaan?",
  united_associates: "united associates",
  "Replace with": "Vervangen door",
  Fix: "Staren",
  "Invalid emails": "Ongeldige e-mails",
  "This email address is no longer valid":
    "dit e-mailadres is niet meer geldig",
  "Stop sharing": "Stop met delen",
  stop_share_template_info:
    "Dit model is niet langer zichtbaar voor alle community's en is alleen toegankelijk voor de eigenaar van de community.",
  Share_newsletter_campaign: "Nieuwsbrief campagne delen:",
  Anti_spam_test_01: "Test anti spam 01",
  Broadcast_link: "Broadcast link",
  To_copy: "Kopiëren",
  Add_your_broadcast_contacts: "Een contactpersoon toevoegen om door te sturen",
  This_campaign_is_shared_with: "Deze campagne wordt gedeeld met ",
  waiting_for_campaign_to_be_sent_to:
    "wachten op de verzending van de campagne ",
  Contacts: "contact(en)",
  clicks_count: "aantal klikken",
  Emails_opened: "E-mails geopend",
  Send: "Sturen",
  Description_add_your_broadcast_contacts:
    "Hier voeg je het e-mailadres toe van een contactpersoon voor wie je deze e-mail wilt verzenden.",
  Description_waiting_for_campaign_to_be_sent_to:
    "Deze campagne wordt veilig verzonden naar onderstaande personen zonder gebruik te maken van uw auto-login",
  steps_paragraph:
    "Om je nieuwsbriefcampagne te versturen moet je de volgende stappen volgen",
  sending_steps: "Stappen verzenden",
  newsletter_config_title: "Configureer je campagne-informatie",
  resendTest: "De test opnieuw verzenden",
  ignore: "negeren",
  aproveAndSave: "Goedkeuren en opslaan",
  See_more: "Bekijk meer",
  See_less: "zie minder",
  schedule_sending_campaign: "Plan het verzenden van je campagne",
  result_number: "Aantal resultaten",
  manualRecipientNullWarning:
    "Voeg handmatig een e-mail toe of deselecteer het handmatige type.",
  max_recipients_alert:
    "U hebt de toegestane limiet van ontvangers voor uw plan overschreden",
  max_recipients_alert_desc:
    "Overweeg je plan aan te passen om een hoger aantal ontvangers toe te staan.",
  selectAll: "Selecteer alle",
  info_selection: "De 20 ontvangers van deze pagina zijn geselecteerd.",
  desc_selection: "ontvangers in",
  desc_selection_1: "Selecteer alleen de ",
  desc_selection_2: "ontvangers van deze pagina",
  the: "De",
  its_selected: "zijn geselecteerd.",
  clear_selection: "Wis de selectie.",
  deselect: "Deselecteren",
  personalized: "Gepersonaliseerd",
  lite: "Lite",
  edito: "Bewerken",
  basic: "Basis",
  choose_recipients_warning: "Selecteer ten minste één ontvanger voor het type",
  number_of_newsletter: "Nieuwsbrief N°",
  introduction: "Introductie",
  invalid_introduction:
    "De inleiding moet minstens 5 tekens lang zijn en mag niet langer zijn dan 200 tekens",
  placeholder_intro: "Geef je campagne een introductieve tekst",
  duplicateNewsletterTitle: "De nieuwsbrief dupliceren",
  duplicateNewsletter: "Wil je de nieuwsbrief dupliceren?",
  list_campaigns: "Campagnes lijst",
  program_newsletters: "programma nieuwsbrieven",
  number_of_campaigns: "aantal campagnes",
  periods: "perioden",
  total: "Totaal",
  approval_threshold: "Goedkeuringsdrempel",
  principal: "Hoofdpagina",
  approved: "Goedgekeurd",
  rejected: "Rejected",
  sent: "Afgewezen",
  approvalAccepted: "Goedkeuring Aanvaard",
  approvalRejected: "Goedkeuring Afgewezen",
  pendingApproval: "In afwachting van goedkeuring",
  Reduce: "Verminder",
  readMore: "LeesMeer",
  resendApproval: "Opnieuw verzenden",
  cupboard: "Locker / Kast",
  profession: "Beroep",
  auto_send_approved_newsletter_message:
    "Verstuur de campagne zodra de goedkeuringsdrempel is bereikt",
  valid_emails: "Geldige e-mails",
  duplicate_users_alert:
    "Totaal aantal unieke ontvangers voor geselecteerde types (duplicaten zijn verwijderd).",
  send_approval: "goedkeuring verzenden",
  new_mail_address: "Nieuw e-mailadres",
  mail_address: "e-mailadres...",
  mail_address_invalid: "Voer een geldig e-mailadres in",
  confirm_unsubscribe_msg: "Bevestig dat u uw e-mailadres",
  from_future_mails_from: "wilt afmelden voor toekomstige e-mails van",
  unsubscribed_msg: "Je bent uitgeschreven !",
  warning_unsubscribe_msg: "U zult geen e-mails meer ontvangen van",
  unsubscribed_by_accident: ", per ongeluk uitgeschreven ?",
  subscribed_msg: "aanmelden",
  unsubscribe_from_ua: "Afmelden voor United Associate",
  subject_message_info_part_1: "Verwijder alstublieft ",
  subject_message_info_part_2: " tekens uit de tekst.",
  steps_paragraph_ritch_text:
    "Om je ritch tekstcampagne te versturen moet je de volgende stappen volgen",
  duplicateRitchTextTitle: "Rijke tekst dupliceren",
  duplicateRitchText: "Wil je de rijke tekst dupliceren?",
  list_campaigns_subtitle: "Je nieuwsbriefcampagnes bekijken en beheren",
  uploadImage: "Afbeelding toevoegen",
  imageUpload: "Afbeelding succesvol geüpload en opgeslagen",
  imageUploadSuccess: "Upload geslaagd",
  imageNoUpload: "Fout bij het uploaden van de afbeelding",
  imageUploadError: "Upload mislukt",
  secondSignatureEmail: "Tweede handtekening e-mail",
  enterProfession :"Voer uw beroep in",
  giveCampaignTitle: "Geef je campagne een titel",
  addsecondsignature: "Voeg een tweede handtekening toe",
  pasteimageurl: "Plak de afbeeldings-URL",
  imageurl: "Afbeeldings-URL",
};
