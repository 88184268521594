import axios from "../axios-api";

import { EVENT_RECIPIENT_TYPES, SURVEY_RECIPIENT_TYPES } from "Common";
import { TTP_API_URL } from "Config";
import moment from "moment";

const API_DATE_FORMAT = "YYYY-MM-DD HH:mm";
import { throwCatchedError, updateSourceToken } from "utils";
import { PAYMENT_RECIPIENT_TYPES } from "../../config/Common";

let getRecipientsSourceToken;

export const getEventRecipients = (filterData) => {
  const recipientUri = {
    SPEAKER: "/event/speaker",
    GUEST: "/event/guest",
    PARTNER: "/event/partner",
    PARTNER_GUEST: "/event/partner-guest",
    CYCLE_GUEST: "/event/guest",
  };

  const { recipientType, eventFilters, language, clientId } = filterData;
  const requestUrl = `${recipientUri[recipientType]}`;

  let fields = ["id", "firstName", "lastName", "email"];
  const filter = eventFilters.slice();

  if (recipientType === "PARTNER") {
    fields = [
      "id",
      "nameFr",
      "nameEn",
      "nameNl",
      "emailFr",
      "emailEN",
      "emailNl",
    ];
  } else if (recipientType === "SPEAKER") {
    fields = ["id", "firstName", "lastName", "email"];
    filter.push({
      property: "client.id",
      value: clientId,
      operator: "eq",
    });
  } else if (recipientType == "GUEST") {
    fields = ["guestUser"];
    filter.push({
      property: "guestUser.language",
      value: language == "all" ? ["fr", "nl", "en"] : language,
      operator: "in",
    });
  } else if (recipientType == "PARTNER_GUEST") {
    filter.push({
      property: "language",
      value: language == "all" ? ["fr", "nl", "en"] : language,
      operator: "in",
    });
  } else if (recipientType == "CYCLE_GUEST") {
    fields = ["guestUser"];
    filter.push({
      property: "cycleTypeRegistration",
      value: 1,
      operator: "eq",
    });
  }

  return {
    requestUrl,
    fields: fields.join(","),
    filter: JSON.stringify(filter),
    start: 0,
    limit: 10,
  };
};

export const getPaymentRecipients = (filterData) => {
  const { language, clientId, eventFilters } = filterData;
  let requestUrl = `/billing/document/get-rappel-emailing`;

  const discriminantFilter = eventFilters.find(
    (filter) => filter.property === "discriminant",
  );
  const rappelTypeFilter = eventFilters.find(
    (filter) => filter.property === "rappelType",
  );
  const appRefFilter = eventFilters.find(
    (filter) => filter.property === "appRef",
  );
  const appTypeFilter = eventFilters.find(
    (filter) => filter.property === "appType",
  );

  if (discriminantFilter?.value === 'manual'){
    requestUrl = 'billing/manual/get-rappel-emailing';
  }


  return {
    requestUrl,
    language,
    rappelType: rappelTypeFilter?.value,
    discriminant: discriminantFilter?.value,
    issuerId: clientId,
    appRef: appRefFilter?.value,
    appType: appTypeFilter?.value,
    app: "EVENT",
  };
};

export const getMailingListsSubscribers = (filterData) => {
  const requestUrl = `/mailing/mailing-list-subscriber`;
  const { clientId, mailingLists } = filterData;
  const filter = [
    {
      property: "client",
      value: clientId,
      operator: "eq",
    },
    {
      property: "status",
      value: "ACTIVE",
      operator: "eq",
    },
  ];
  let lists = [""];
  if (mailingLists && mailingLists.length > 0) {
    lists =
      mailingLists instanceof Array ? mailingLists : mailingLists.split(",");
  }
  filter.push({
    property: "mailingList",
    value: lists,
    operator: "in",
  });
  if (filterData.language && filterData.language != "all") {
    filter.push({
      property: "language",
      value: filterData.language,
      operator: "eq",
    });
  }
  return {
    requestUrl,
    fields: "*",
    filter: JSON.stringify(filter),
  };
};

export const getSurveyTargets = (filterData) => {
  const { surveyType, language, surveyInstance, clientId } = filterData;
  if ("INVITATION" == surveyType) {
    return getMembers({ ...filterData, recipientType: "CONTACT" });
  }
  let filter = [];

  switch (surveyType) {
    case "REVIVAL":
      filter = [
        {
          property: "participated",
          value: 0,
          operator: "eq",
        },
        {
          property: "invited",
          value: 1,
          operator: "eq",
        },
        {
          property: "responseStatus",
          value: "INVITED",
          operator: "eq",
        },
      ];
      if (surveyInstance && surveyInstance.revivalMinDays) {
        const date = moment()
          .add(-1 * surveyInstance.revivalMinDays, "days")
          .format(API_DATE_FORMAT);
        filter.push({
          property: "latestRevivalAt",
          value: `SPECIAL_CHAR_TO_REPLACEu0027${date}SPECIAL_CHAR_TO_REPLACEu0027`,
          operator: "lte",
        });
      }
      break;
    case "PARTIAL_REVIVAL":
      filter = [
        {
          property: "responseStatus",
          value: "INCOMPLETE",
          operator: "eq",
        },
      ];
      if (surveyInstance && surveyInstance.revivalMinDays) {
        const date = moment()
          .add(-1 * surveyInstance.revivalMinDays, "days")
          .format(API_DATE_FORMAT);
        filter.push({
          property: "latestPartialRevivalAt",
          value: `SPECIAL_CHAR_TO_REPLACEu0027${date}SPECIAL_CHAR_TO_REPLACEu0027`,
          operator: "lte",
        });
      }
      break;
    case "PARTICIPATIONS":
      filter = [
        {
          property: "responseStatus",
          value: "COMPLETED",
          operator: "eq",
        },
      ];
      break;
    case "SURVEY_RESULTS":
      filter = [
        {
          property: "resultsStatus",
          value: "ASKED",
          operator: "eq",
        },
      ];
      break;
    default:
      break;
  }

  filter.push(
    {
      property: "language",
      value: language,
      operator: "eq",
    },
    {
      property: "instance",
      value: surveyInstance && surveyInstance.id ? surveyInstance.id : 0,
      operator: "eq",
    },
  );

  return {
    requestUrl: `${TTP_API_URL}/survey/participant`,
    fields: "user",
    filter: JSON.stringify(filter).replace(/SPECIAL_CHAR_TO_REPLACE/g, "\\"),
    organization: clientId,
    instance: surveyInstance && surveyInstance.id ? surveyInstance.id : null,
  };
};

export const getMembers = (filterData) => {
  const {
    language,
    name,
    groupsNin,
    groupsIn,
    mailingLists,
    clientId,
    eventFilters,
    recipientType,
    commitmentScoreRange,
    isBouncedList,
  } = filterData;
  let requestUrl = "";
  let params = {};
  if (recipientType === "FIDUCIARIES_CLIENT") {
    requestUrl = `${TTP_API_URL}/organization/folder`;
  } else if (recipientType === "COLLABORATOR") {
    requestUrl = `${TTP_API_URL}/organization/user`;
    params["organization_id"] = clientId;
  } else if (recipientType === "FOLDER") {
    requestUrl = `${TTP_API_URL}/organization/user`;
  } else {
    requestUrl = `${TTP_API_URL}/mailing/${recipientType.toLowerCase()}`;
  }

  let fields = [];
  let filter = [];
  if (recipientType === "CONTACT") {
    let min = "",
      max = "";
    if (typeof commitmentScoreRange !== "string") {
      min = JSON.stringify(commitmentScoreRange.min);
      max = JSON.stringify(commitmentScoreRange.max);
    }

    const commitmentScore =
      typeof commitmentScoreRange === "string"
        ? commitmentScoreRange
        : [min, max].join(",");

    fields = ["id", "lastName", "firstName", "contactClient"];
    filter = [
      {
        property: "contactClient.client",
        value: clientId,
        operator: "eq",
      },
      {
        property: "contactClient.receiveNewsletters",
        value: 1,
        operator: "eq",
      },
      {
        property: "contactClient.actif",
        value: 1,
        operator: "eq",
      },
      {
        property: "email.isBounced",
        value: 0,
        operator: "eq",
      },
    ];

    if (isBouncedList) {
      filter.push({
        property: "contactClient.email",
        value: 1,
        operator: "eq",
      });
    }

    if (commitmentScore !== "0,100") {
      filter.push({
        property: "contactClient.commitmentScore",
        value: commitmentScore,
        operator: "between",
      });
    }

    if (
      eventFilters &&
      eventFilters.length == 1 &&
      eventFilters[0].value &&
      eventFilters[0].value.length
    ) {
      const { property, value, operator } = eventFilters[0];
      filter.push({
        property,
        value,
        operator,
      });
    }
    params["organization_id"] = clientId;
  } else if (recipientType === "PROSPECT") {
    fields = ["id", "email", "lastName", "firstName"];

    filter = [
      {
        property: "client",
        value: clientId,
        operator: "eq",
      },
      {
        property: "receiveNewsletters",
        value: 1,
        operator: "eq",
      },
      {
        property: "actif",
        value: 1,
        operator: "eq",
      },
    ];
    params["organization_id"] = clientId;
  } else if (recipientType === "FIDUCIARIES_CLIENT") {
    fields = ["*", "legalRepresentative"];

    filter = [
      {
        property: "owner",
        value: null,
        operator: "neq",
      },
    ];
    params["organization_id"] = clientId;
  } else if (recipientType === "COLLABORATOR") {
    fields = ["id", "firstName", "lastName", "mainEmail"];
    if (eventFilters && eventFilters.length > 0) {
      filter = eventFilters.filter(({ value }) => value && value.length > 0);
    }
    params["organization_id"] = clientId;
  } else if (recipientType === "FOLDER") {
    fields = ["id", "firstName", "lastName", "mainEmail", "role"];
    if (eventFilters && eventFilters.length > 0) {
      filter = eventFilters.filter(({ value }) => value && value.length > 0);
      const clientFolderFilter = eventFilters.filter(
        ({ property }) => property === "role.organization",
      );
      params["organization_id"] =
        clientFolderFilter && clientFolderFilter[0]
          ? clientFolderFilter[0]["value"]
          : clientId;
    }
  }

  if (recipientType !== "FIDUCIARIES_CLIENT") {
    if (language && language != "all") {
      filter.push({
        property: "language",
        value: language,
        operator: "eq",
      });
    }
    if (groupsIn && groupsIn.length > 0) {
      filter.push({
        property: "group.id",
        value: groupsIn,
        operator: "in",
      });
    }
    if (groupsNin && groupsNin.length > 0) {
      filter.push({
        property: "group.id",
        value: groupsNin,
        operator: "nin",
      });
    }
    if (name) {
      filter.push({
        property: "name",
        value: name,
        operator: "like",
        separator: ",",
      });
      if (recipientType == "CONTACT") {
        filter.push({
          property: "email.main",
          value: ["0", "1"],
          operator: "in",
        });
      }
    }
    if (mailingLists && mailingLists.length > 0) {
      filter.push(
        {
          property: "mailingListSubscribers.mailingList",
          value:
            mailingLists instanceof Array
              ? mailingLists
              : mailingLists.split(","),
          operator: "in",
        },
        {
          property: "mailingListSubscribers.status",
          value: "ACTIVE",
          operator: "eq",
        },
      );
    }
    if (
      eventFilters.length === 0 ||
      (eventFilters &&
        eventFilters.length > 0 &&
        !eventFilters.filter(
          ({ property }) => property === "role.organization",
        ))
    ) {
      params["organization_id"] = clientId;
    }
  }

  return {
    ...params,
    requestUrl,
    fields: fields.join(","),
    filter: JSON.stringify(filter),
  };
};

export const getRecipients = (
  token,
  page = 1,
  pageSize = 10,
  filterData,
  sourceToken = null,
) => {
  getRecipientsSourceToken = updateSourceToken(
    getRecipientsSourceToken,
    sourceToken,
  );
  const { recipientType } = filterData;
  let data = {};

  if (EVENT_RECIPIENT_TYPES.indexOf(filterData.recipientType) > -1) {
    data = getEventRecipients(filterData);
  } else if (recipientType === "MAILING_LIST") {
    data = getMailingListsSubscribers(filterData);
  } else if (SURVEY_RECIPIENT_TYPES.indexOf(filterData.recipientType) > -1) {
    data = getSurveyTargets(filterData);
  } else if (PAYMENT_RECIPIENT_TYPES.indexOf(filterData.recipientType) > -1) {
    data = getPaymentRecipients(filterData);
  } else {
    data = getMembers(filterData);
  }

  const { requestUrl, ...params } = data;

  return axios
    .get(requestUrl, {
      cancelToken: getRecipientsSourceToken.token,
      params: {
        access_token: token,
        ...params,
        limit: filterData.recipientType === "SPEAKER" ? 200 : pageSize,
        start: (page - 1) * pageSize,
      },
    })
    .catch((thrown) => {
      throwCatchedError(thrown);
    });
};

export const getUaRecipients = (
  token,
  page = 1,
  pageSize = 20,
  filterData,
  sourceToken = null,
) => {
  getRecipientsSourceToken = updateSourceToken(
    getRecipientsSourceToken,
    sourceToken,
  );
  const { recipientType } = filterData;
  let data = {};

  if (recipientType === "COLLABORATOR") {
    data = getCollaborators(filterData);
  } else if (recipientType === "CLIENT") {
    data = getLegalRepresentativeUsers(filterData);
  } else if (
    recipientType === "CONTACT" ||
    recipientType === "CONTACT_CLIENT"
  ) {
    data = getContactsList(filterData);
  }

  const { requestUrl, ...params } = data;

  return axios
    .get(requestUrl, {
      cancelToken: getRecipientsSourceToken.token,
      params: {
        access_token: token,
        ...params,
        limit: pageSize,
        start: (page - 1) * pageSize,
      },
    })
    .catch((thrown) => {
      throwCatchedError(thrown);
    });
};
export const getCollaborators = (filterData) => {
  const { clientId, name, uaRecipientType, bounced } = filterData;
  let requestUrl = "";
  let params = {};

  requestUrl = `${TTP_API_URL}/organization/user/getCollaborators`;

  let fields = [];

  fields = ["id", "firstName", "lastName", "mainEmail"];

  params["organization"] = clientId;
  params["search"] = name;
  params["uaRecipientType"] = uaRecipientType;
  params["bounced"] = bounced;
  return {
    ...params,
    requestUrl,
  };
};
export const getLegalRepresentativeUsers = (filterData) => {
  const {
    clientId,
    name,
    uaRecipientType,
    profession,
    cupboard,
    externalType,
    bounced,
  } = filterData;
  let requestUrl = "";
  let params = {};

  requestUrl = `${TTP_API_URL}/organization/user/getLegalRepresentativeUsers`;

  params["organization"] = clientId;
  params["search"] = name;
  params["bounced"] = bounced;

  params["uaRecipientType"] = uaRecipientType;
  if (profession) {
    params["profession"] = profession;
  }
  if (cupboard) {
    params["cupboard"] = cupboard;
  }
  if (externalType) {
    params["externalType"] = externalType;
  }

  return {
    ...params,
    requestUrl,
  };
};
export const getContactsList = (filterData) => {
  const {
    clientId,
    groupsNin,
    groupsIn,
    name,
    recipientType,
    uaRecipientType,
    bounced,
  } = filterData;
  let requestUrl = "";
  let params = {};

  requestUrl = `${TTP_API_URL}/mailing/contact-client-list`;

  let filter = [];

  let fields = ["id", "firstName", "lastName", "mainEmail", "name", "groups"];

  params["organization"] = clientId;
  params["search"] = name;
  params["groupsIn"] = groupsIn;
  params["groupsNotIn"] = groupsNin;
  params["recipientType"] = recipientType;
  params["uaRecipientType"] = uaRecipientType;
  params["bounced"] = bounced;

  return {
    ...params,
    requestUrl,
    fields: fields.join(","),
    filter: JSON.stringify(filter),
  };
};
export const getTotalRecipients = (
  token,
  page = 1,
  pageSize = 20,
  filterData,
  sourceToken = null,
) => {
  const { clientId, name, recipientType, uaRecipientType, uaFilters } =
    filterData;
  let requestUrl = "";
  let params = {};

  requestUrl = `${TTP_API_URL}/organization/user/get-all-recipients`;

  let fields = ["id", "firstName", "lastName", "mainEmail", "name", "groups"];
  params["organization"] = clientId;
  params["search"] = name;
  params["recipientType"] = recipientType;
  params["uaRecipientType"] = uaRecipientType;
  params["uaFilters"] = uaFilters;

  return axios.get(requestUrl, {
    params: {
      ...params,
      access_token: token,
      limit: pageSize,
      start: (page - 1) * pageSize,
    },
  });
};

export const deleteBlacklistedEmail = (token, email) => {
  const requestUrl = `${TTP_API_URL}/profile/email/update-is-deleted`;
  const formData = new FormData();
  formData.append("access_token", token);
  formData.append("email", email);

  return axios.post(requestUrl, formData);
};
