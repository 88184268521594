import React, { Component } from "react";
import { connect } from 'react-redux';

import {fetchEventActivities, fetchGroups, fetchEvents, fetchEventSlot} from 'thunks';
import {setEventsFilters} from 'actions';
import _ from 'i18n';
import TTPSelect from "common/TTPSelect";
import {
  sortEventByStartDateTime,
  transformDateFormat,
  ucFirst
} from "../../../../../services/common";

const mapStateToProps = (store) => {
  return {
    groups: store.filters.groups.items,
    events: store.filters.events.items,
    activities: store.filters.activities.items,
    slots: store.filters.slots.items,
    language: store.params.lng,
    eventFilters: store.currentCampaign.email.eventFilters,
    campaignLanguage: store.currentCampaign.email.language
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleEventChange: (eventId) => dispatch(setEventsFilters({property: 'event.id', operator: 'eq', value: eventId})),
    handleGroupsInChange: (groupsIn) => dispatch(setEventsFilters({property: 'group', operator: 'in', value: (groupsIn.length>0)?groupsIn:""})),
    handleGroupsNinChange: (groupsNin) => dispatch(setEventsFilters({property: 'group', operator: 'nin', value: (groupsNin.length>0)?groupsNin:""})),
    handleActivitiesInChange: (activitiesIn) => dispatch(setEventsFilters({property: 'activity', operator: 'in', value: (activitiesIn.length>0)?activitiesIn:""})),
    handleActivitiesNinChange: (activitiesNin) => dispatch(setEventsFilters({property: 'activity', operator: 'nin', value: (activitiesNin.length>0)?activitiesNin:""})),
    handleSlotChange: (slots) => dispatch(setEventsFilters({property: 'slot.id', operator: 'in', value: (slots.length>0)?slots:""})),
    fetchEvents: () => dispatch(fetchEvents()),
    fetchEventSlot: (eventId) => dispatch(fetchEventSlot(eventId)),
  }
}

@connect(mapStateToProps, mapDispatchToProps)
export default class SpeakerFilters extends Component {

  constructor(props) {
    super(props);
    this.state = {
      eventsType: 'future',
    };
  }

  componentWillMount() {
    const {fetchEvents, fetchEventSlot, eventFilters} = this.props;
    fetchEvents();
    for(let criteria of eventFilters){
      if(criteria.property == "event.id"){
        fetchEventSlot(criteria.value);
        break;
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const {events, eventFilters, handleEventChange, fetchEventSlot} = nextProps;
    if(events && events.length>0 && eventFilters.length == 0){
      handleEventChange(events[0]['id']);
      fetchEventSlot(events[0]['id']);
    }
  }

  handleEventChange(event) {
    const {handleEventChange, fetchEventSlot, handleSlotChange} = this.props;
    handleEventChange(event);
    handleSlotChange([]);
    fetchEventSlot(event);
  }

  render () {
    const {slots, events, groups, activities, eventFilters, language, handleSlotChange, campaignLanguage, handleGroupsInChange, handleGroupsNinChange, handleActivitiesInChange, handleActivitiesNinChange} = this.props;
    const { eventsType } = this.state;
    let filterMapping = eventFilters.reduce((filters, filter) => {
      let key = `${filter.property}-${filter.operator}`;
      filters[key] = filter.value;
      return filters;
    }, {});

    let selectedEvent = filterMapping['event.id-eq'] || "";
    let selectedSlot = filterMapping['slot.id-in'] || "";

    let lng = ucFirst(language);
    let campaignLng = ucFirst(campaignLanguage);

    let sortedEvents = sortEventByStartDateTime(events, 'DESC', eventsType)
    let eventOptions = sortedEvents?.map(event => {
      const getEventName = () => {
        if (campaignLng !== 'All' && event[`name${campaignLng}`]) {
          return event[`name${campaignLng}`];
        }
        // Fallback order: Fr -> Nl -> En
        return event['nameFr'] || event['nameNl'] || event['nameEn'] || 'Unnamed Event';
      };

      const eventName = getEventName();
      const eventStartDate = transformDateFormat(event['startDateTime']);

      return {
        label: `${eventStartDate} || ${eventName}`,
        id: event.id.toString()
      };
    });

    let allowedGroupsOptions = groups.map(group => {
      return {
        label: group.name,
        value: group.id+"",
      };
    });

    let deniedGroupsOptions = groups.map(group => {
      return {
        label: group.name,
        value: group.id+"",
      };
    });

    let allowedActivitiesOptions = activities.map(activity => {
      return {
        label: activity[`name${lng}`] || activity['nameFr'],
        value: activity.id+"",
      };
    });

    let deniedActivitiesOptions = activities.map(activity => {
      return {
        label: activity[`name${lng}`] || activity['nameFr'],
        value: activity.id+"",
      };
    });

    let slotOptions = slots.map(option => {
      return {
                label: option[`name${lng}`] || option['nameFr'] || option['nameNl'],
                id: option.id+""
              };
    });

    return (
      <div className="row align-center">
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('event')}
                <span style={{float: "right"}}>
                <span style={{marginLeft: '10px'}}>
                    <input type="radio" name='future' style={{margin: 'unset'}}
                           defaultChecked={true}
                           checked={this.state.eventsType === 'future'}
                           onChange={() => this.setState({
                             ...this.state,
                             eventsType: 'future'
                           })}/>
                    <span className="ttp-radio"></span>
                    <span style={{marginLeft: '5px'}}>{_('future')}</span>
                  </span>
                  <span style={{margin: '0 10px 0 10px '}}>
                    <input type="radio" name='replay' style={{margin: 'unset'}}
                           defaultChecked={false}
                           checked={this.state.eventsType === 'past'}
                           onChange={() => this.setState({
                             ...this.state,
                             eventsType: 'past'
                           })}/>
                    <span className="ttp-radio"></span>
                    <span style={{marginLeft: '5px'}}>{_('past')}</span>
                  </span>
                </span>
            </h1>
            <TTPSelect
              simple={true}
              notClearable={true}
              values={selectedEvent}
              placeholder={_('Select event')}
              options={eventOptions}
              onChange={this.handleEventChange.bind(this)}/>
          </div>
        </div>
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('Slots')}</h1>
            <TTPSelect
              values={selectedSlot}
              placeholder={_('Select slots')}
              options={slotOptions}
              onChange={handleSlotChange}/>
          </div>
        </div>
        {/*<div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('groupsIncluded')}</h1>
            <Select multi simpleValue value={apiFilter['filterOrateursGroups'] || []} placeholder={_('selectGroups')} options={allowedGroupsOptions} onChange={handleGroupsInChange} />
          </div>
        </div>
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('Included activities')}</h1>
            <Select multi simpleValue value={apiFilter['activites'] || []} placeholder={_('Select activities')} options={allowedActivitiesOptions} onChange={handleActivitiesInChange}/>
          </div>
        </div>
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('excludedGroups')}</h1>
            <Select multi simpleValue value={apiFilter['exceptOrateursGroups'] || []} placeholder={_('excludeGroups')} options={deniedGroupsOptions} onChange={handleGroupsNinChange}/>
          </div>
        </div>
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('Excluded activities')}</h1>
            <Select multi simpleValue value={apiFilter['activitesExcept'] || []} placeholder={_('Exclude activities')} options={deniedActivitiesOptions} onChange={handleActivitiesNinChange}/>
          </div>
        </div>*/}
      </div>
    );

  }
}
