import React from "react";
import _ from "i18n";
import { SVG_ICONS } from "../../../config/Common";

class ActionBar extends React.Component {

  render() {
    const { steps, currentStepIndex, onChange, icon, title, onAction, name, isSaved, isUpdating, onClose, campaignType } = this.props;
    const width = steps && steps[currentStepIndex] ? currentStepIndex * 100 / (steps.length - 1) + "%" : 0;
    const actions = steps && steps[currentStepIndex] ? steps[currentStepIndex].actions : [];

    return <div className="create-campaign__header">
      <div className="type-info">
        <div className="icon">
          <img src={"/img/icons/" + SVG_ICONS[icon]} alt="" />
        </div>
        <div className="title">
          {_(title)}
        </div>
      </div>
      {steps && steps.length > 1 ? <div className="type-steps">
        <div className={"active-bar"}
          style={{ width }} />
        {steps.map(({ label }, index) => {
          const onClick = index < currentStepIndex ? () => onChange(index) : undefined;
          return <div key={"step-" + index} className={currentStepIndex >= index ? "active" : ""} onClick={onClick}>
            <div />
            <span>{_(label)}</span>
          </div>
        })}
      </div> : <div className={"theme-name"}>{name || ''}</div>}
      <div className="type-actions" style={{flex: 1 + (actions && actions.length || 0)}}>
        {actions && actions.length > 0 && actions.map(({ label, key, cssClass, icon }) => <a key={key} className={`btn ${cssClass || ''}`} style={label === '{{}}' && campaignType !== "newsletter" ? {minWidth: '50px', fontSize: '18px'} : {} } onClick={icon && !isSaved && isUpdating ? undefined : () => onAction(key)}>
          {isSaved && icon}
          {icon && !isSaved && isUpdating && <img src={"/img/icons/tail-spin.svg"} className={"loading"}/>}
          {icon && !isSaved && isUpdating ? _("Processing") : _(label)}
        </a>)}
      </div>
      <div className="close" onClick={onClose}/>
    </div>
  }
}

export default ActionBar;
