import React from "react";
import {SVG_ICONS} from "../../config/Common";
import _ from "i18n";
import ApplyButton from "./ApplyButton";

export const ThemeCard = ({isActive, name, originality,isPublic, preview, onSelect}) => {
  return <div className={`theme-card ${isActive ? "active" : ""}`} onClick={onSelect} style={{backgroundImage: `url(${preview})`, cursor: 'pointer'}}>
    <div className={"theme-card__status"}>
      <div className={"theme-card__status__title"}>{name}</div>
      <div className={"theme-card__status__tags"}>
        <span><img src={"/img/icons/" + SVG_ICONS["TAG"]} alt=""/> {isPublic ? _('shared') : _('private')}</span>
        <span><img src={"/img/icons/" + SVG_ICONS["TAG"]} alt=""/> {_(originality)}</span>
      </div>
    </div>
    <ApplyButton checked={isActive} onToggleSelection={onSelect} label={"use"}/>
  </div>
};
