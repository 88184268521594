import React, { PureComponent } from "react";
import Select, { components } from "react-select";
import TagsInput from "react-tagsinput";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";

import TTPSelect from "common/TTPSelect";

import _ from "i18n";
import { CAMPAIGN_LANGUAGE } from "Common";
import {
  IS_EMAIL_VALID_UA,
  SVG_Image_UPLOAD_FILE,
} from "../../../services/svgIcones";
import { setCurrentEmailCampaignData } from "../../../actions/actions";
import { isValidEmail, hasValidLength, ucFirst } from "../../../services/utils";
import StepsFooter from "./StepsFooter";
import SunEdit from "../../common/editor/SunEdit";
import { fetchUserAvatar, fetchUser } from "../../../actions/thunks/user";
import UsersTagSuggestion from "common/UsersTagSuggestion";
import { saveMediaImage } from "thunks";
import Loader from "react-loaders";
import { NotificationManager } from "react-notifications";
import { onError, onSuccess } from "../../../services/utils";

@connect((store) => {
  return {
    client: store.auth.currentClient,
    registeredEmails: store.params.sesEmails.items,
    sesEmailsFetched: store.params.sesEmails.fetched,
    recipientType: store.currentCampaign.email.recipientType,
    language: store.currentCampaign.email.language,
    replyTo: store.currentCampaign.email.replyTo,
    fromName: store.currentCampaign.email.fromName,
    from: store.currentCampaign.email.from,
    attachments: store.currentCampaign.email.attachments,
    deletedAttachmentsIds: store.currentCampaign.email.deletedAttachmentsIds,
    verifiedEmails: store.auth.currentSetting.verifiedEmails,
    filters: store.filters,
    eventFilters: store.currentCampaign.email.eventFilters,
    object: store.currentCampaign.email.object,
    currentStep: store.currentCampaign.email.currentSideBar === "PARAMS",
    newsletterPreferences: store.auth.currentSetting.newsletterPreferences,
    numberOfNewsletter: store.currentCampaign.email.numberOfNewsletter,
    lastNumNewsletter: store.currentCampaign.email.lastNumNewsletter,
    html: store.currentCampaign.email.html,
    textContent: store.currentCampaign.email.textContent,
    profession: store.currentCampaign.email.profession,
    signature: store.currentCampaign.email.signature,
    avatar: store.currentCampaign.email.avatar,
    secondName: store.currentCampaign.email.secondName,
    secondProfession: store.currentCampaign.email.secondProfession,
    secondAvatar: store.currentCampaign.email.secondAvatar,
    secondFrom: store.currentCampaign.email.secondFrom,
    secondUserId: store.currentCampaign.email.secondUserId,
    secondId: store.currentCampaign.email.secondId,
    currentClient: store.auth.currentClient,
    imgPreview: store.currentCampaign.email.imgPreview,
  };
})
export default class ParamsTextStep extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      registrationStatus: "FAILED",
      registrationStatus2: "FAILED",
      tag: "",
      currentObject: this.props.object,
      profession: true,
      signature: "",
      localProfession: "",
      localSecondProfession: "",
      localSecondName: "",
      localSecondFrom: "",
      localSecondAvatar: "",
      localSecondId: "",
      localSecondUserId: "",
      secondFroms: [],
      avatar: "",
      processing: false,
      isSecondSignatureActive: !!this.props.secondName,
      localImageUrl: this.props.imgPreview || "",
      hasError: {
        from: false,
        fromName: false,
        replyTo: false,
        object: false,
        secondFrom: false,
        secondName: false,
      },
      senders: [],
      textContent: this.props.newhtml,
    };
  }

  componentDidMount() {
    const { newsletterPreferences } = this.props;
    if (newsletterPreferences["apps"]) {
      for (let i = 0; i < newsletterPreferences["apps"].length; i++) {
        if (newsletterPreferences["apps"][i].type === "BLOG_NL_CREATE")
          newsletterPreferences["apps"][i].senders &&
            this.setState({
              senders: newsletterPreferences["apps"][i].senders,
            });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      senders,
      currentObject,
      localProfession,
      localSecondProfession,
      localSecondName,
      localSecondFrom,
      localSecondAvatar,
      localImageUrl,
    } = this.state;
    const {
      from,
      object,
      lastNumNewsletter,
      signature,
      newhtml,
      secondFrom,
      secondName,
      secondId,
      secondUserId,
      imgPreview,
    } = this.props;
    if (
      (prevProps.secondFrom !== this.props.secondFrom &&
        this.props.secondFrom) ||
      (prevProps.secondName !== this.props.secondName && this.props.secondName)
    ) {
      if (secondFrom && secondName) {
        this.setState({
          secondFroms: [
            {
              email: secondFrom,
              name: secondName,
              id: secondId,
              userId: secondUserId,
            },
          ],
          isSecondSignatureActive: !!this.props.secondName,
        });
      }
    }

    if (prevProps.object !== object && object !== currentObject) {
      this.setState({ currentObject: object });
    }
    if (newhtml !== "undefined") {
      this.props.dispatch(
        setCurrentEmailCampaignData({ textContent: newhtml }),
      );
    }
    const newProfession = signature.profession;
    if (newProfession !== "undefined") {
      this.props.dispatch(
        setCurrentEmailCampaignData({ profession: localProfession }),
      );
    }
    if (
      prevProps.signature.profession !== newProfession &&
      newProfession !== ""
    ) {
      this.setState({ localProfession: newProfession });
    }
    const newSecondProfession = signature.secondProfession;
    if (newSecondProfession !== "undefined") {
      this.props.dispatch(
        setCurrentEmailCampaignData({
          secondProfession: localSecondProfession,
        }),
      );
    }
    if (
      prevProps.signature.secondProfession !== newSecondProfession &&
      newSecondProfession !== ""
    ) {
      this.setState({ localSecondProfession: newSecondProfession });
    }

    const newSecondName = signature.secondName;
    if (newSecondName !== "undefined") {
      this.props.dispatch(
        setCurrentEmailCampaignData({ secondName: localSecondName }),
      );
    }
    if (
      prevProps.signature.secondName !== newSecondName &&
      newSecondName !== ""
    ) {
      this.setState({ localSecondName: newSecondName });
    }
    const newSecondavatar = signature.secondAvatar;
    if (newSecondavatar !== "undefined") {
      this.props.dispatch(
        setCurrentEmailCampaignData({ secondAvatar: localSecondAvatar }),
      );
    }
    if (
      prevProps.signature.secondAvatar !== newSecondavatar &&
      newSecondavatar !== ""
    ) {
      this.setState({ localSecondAvatar: newSecondavatar });
    }
    const newImageUrl = imgPreview;
    if (newImageUrl !== "undefined") {
      this.props.dispatch(
        setCurrentEmailCampaignData({ imgPreview: localImageUrl }),
      );
    }
    if (prevProps.imgPreview !== newImageUrl && newImageUrl !== "") {
      this.setState({ localImageUrl: newImageUrl });
    }
    const newSecondFrom = signature.secondFrom;
    if (newSecondFrom !== "undefined") {
      this.props.dispatch(
        setCurrentEmailCampaignData({ secondFrom: localSecondFrom }),
      );
    }
    if (
      prevProps.signature.secondFrom !== newSecondFrom &&
      newSecondFrom !== ""
    ) {
      this.setState({ localSecondFrom: newSecondFrom });
    }
    if (from !== undefined || prevProps.from !== from) {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
      let sender = senders.filter((option) => option.email === from);
      if (sender.length > 0) {
        this.setState({
          registrationStatus:
            sender[0].status === "Success" ? "SUCCESS" : "FAILED",
        });
        this.props.dispatch(
          setCurrentEmailCampaignData({
            fromEmailValid: sender[0].status === "Success",
          }),
        );

        this.props
          .dispatch(fetchUserAvatar(sender[0].value))
          .then((response) => {
            const avatar = response.action.payload.data.avatarUrl;
            this.props.dispatch(setCurrentEmailCampaignData({ avatar }));
          })
          .catch((error) => {
            console.error("Error fetching user avatar:", error);
          });
      }
    }
    if (lastNumNewsletter && lastNumNewsletter !== 0) {
      let numNewsletter = lastNumNewsletter;
      if (/^\d+$/.test(numNewsletter)) {
        // If numNewsletter is just a number, increment it by one
        numNewsletter = (parseInt(numNewsletter) + 1)
          .toString()
          .padStart(numNewsletter.length, "0");
      } else if (/^\d{4}-\d+$/.test(numNewsletter)) {
        // If numNewsletter is in the format YYYY-CampaignNumber
        const [year, campaignNumber] = numNewsletter.split("-");
        const nextCampaignNumber = parseInt(campaignNumber) + 1;

        // Reconstruct the numNewsletter string with the incremented campaign number
        numNewsletter = `${year}-${nextCampaignNumber.toString().padStart(campaignNumber.length, "0")}`;
      } else if (/^#\d{4}-\d+$/.test(numNewsletter)) {
        // If numnl is in the format #YYYY-CampaignNumber
        const [year, campaignNumber] = numNewsletter.split("#")[1].split("-");
        if (year !== undefined && campaignNumber !== undefined) {
          const nextCampaignNumber = parseInt(campaignNumber) + 1;
          numNewsletter = `#${year}-${nextCampaignNumber.toString().padStart(campaignNumber.length, "0")}`;
        }
      }
      this.props.dispatch(
        setCurrentEmailCampaignData({ numberOfNewsletter: numNewsletter }),
      );
    }
  }

  handleLanguageChange = (language) => {
    this.props.dispatch(setCurrentEmailCampaignData({ language }));
  };

  handleTagsChange = (tags) => {
    if (tags.length === 0) {
      this.setState({
        secondFroms: [],
        localSecondFrom: "",
        localSecondName: "",
        localSecondProfession: "",
      });

      this.props.dispatch(
        setCurrentEmailCampaignData({
          secondFrom: "",
          secondName: "",
          testEmails: tags,
          secondProfession: this.state.localSecondProfession,
        }),
      );
    } else {
      const selectedTag = tags[0] || {};

      const localSecondFrom = selectedTag.email || "";
      const localSecondName = selectedTag.name;
      const localSecondId = selectedTag.id;
      const localSecondUserId = selectedTag.userId;

      const secondFroms = [
        {
          id: localSecondId,
          userId: localSecondUserId,
          email: localSecondFrom,
          name: localSecondName,
        },
      ];

      this.setState({
        secondFroms,
        localSecondFrom,
        localSecondName,
        localSecondId,
        localSecondUserId,
      });

      this.props.dispatch(
        setCurrentEmailCampaignData({
          secondFrom: localSecondFrom,
          secondName: localSecondName,
          secondId: localSecondId,
          secondUserId: localSecondUserId,
          testEmails: tags,
          secondProfession: this.state.localSecondProfession || "",
        }),
      );

      // Fetch avatar et profession si un utilisateur est sélectionné
      this.props
        .dispatch(fetchUserAvatar(selectedTag.userId))
        .then((response) => {
          const localSecondAvatar = response.action.payload.data.avatarUrl;
          this.setState({ localSecondAvatar });
          this.props.dispatch(
            setCurrentEmailCampaignData({
              secondAvatar: localSecondAvatar,
            }),
          );
        })
        .catch((error) => {
          console.error("Error fetching user avatar:", error);
        });

      this.props.dispatch(fetchUser(selectedTag.userId)).then((response) => {
        const localSecondProfession = response.value.data.data.function;
        this.setState({ localSecondProfession });
        this.props.dispatch(
          setCurrentEmailCampaignData({
            secondProfession: localSecondProfession,
          }),
        );
      });
    }
  };

  renderLang() {
    const { client, language } = this.props;
    let defaultLanguages = Object.keys(CAMPAIGN_LANGUAGE);
    let preferences =
      client &&
      client.languagePreferences &&
      client.languagePreferences.length > 0
        ? [...client.languagePreferences]
        : defaultLanguages;
    let options = [];
    if (preferences.indexOf("all") === -1) {
      preferences.push("all");
    }
    for (let i = 0; i < preferences.length; i++) {
      let lang = preferences[i];
      options.push({ id: lang, label: ucFirst(_(CAMPAIGN_LANGUAGE[lang])) });
    }
    return (
      <TTPSelect
        simple={true}
        notClearable={true}
        values={language}
        placeholder={_("lang")}
        options={options}
        onChange={this.handleLanguageChange}
      />
    );
  }

  onSuccess(res) {
    const { value } = res;
    if (res.value instanceof Error) {
      NotificationManager.error(_("incompleteOperation"), _("error"));
    } else if (value.data.result === "OK") {
      this.setState({ registrationStatus: "PENDING" });
    }
  }

  handleChangeInput(tag) {
    let tagPattern = new RegExp(TAG_REGEX);
    this.setState((prevState) => ({
      tag,
      hasError: {
        ...prevState.hasError,
        replyTo: tag && !tagPattern.test(tag),
      },
    }));
  }

  handleFromChange = (fromData) => {
    const from = fromData?.email || "";
    const fromName = fromData?.name ? fromData?.name : fromData?.label || "";
    const replyTo = [fromData?.email] || [];
    this.props.dispatch(
      setCurrentEmailCampaignData({
        from,
        fromName,
        replyTo,
      }),
    );
    this.setState((prevState) => ({
      hasError: {
        ...prevState.hasError,
        from: !isValidEmail(from),
        fromName: !hasValidLength(fromName, 3),
      },
      registrationStatus:
        fromData.status === "VALIDATED" || "Success" ? "SUCCESS" : "FAILED",
    }));

    this.props.dispatch(
      setCurrentEmailCampaignData({
        fromEmailValid: fromData.status === "VALIDATED",
      }),
    );

    if (fromData) {
      this.props
        .dispatch(fetchUserAvatar(fromData.value))
        .then((response) => {
          const avatar = response.action.payload.data.avatarUrl;
          this.props.dispatch(setCurrentEmailCampaignData({ avatar }));
        })
        .catch((error) => {
          console.error("Error fetching user avatar:", error);
        });
    }
  };

  handleDrop = (acceptedFiles) => {
    if (acceptedFiles.length === 0) return;
    const newAttachment = acceptedFiles[0];
    let attachments = [...this.props.attachments];
    attachments.push(newAttachment);

    this.props.dispatch(setCurrentEmailCampaignData({ attachments }));
  };

  handleDeleteAttachment = (index, id) => {
    let attachments = [...this.props.attachments];
    let deletedAttachmentsIds = [...this.props.deletedAttachmentsIds];

    if (attachments[index]) {
      attachments = attachments.filter((_, i) => i !== index);
    }
    if (id) {
      deletedAttachmentsIds.push(id);
    }

    this.props.dispatch(
      setCurrentEmailCampaignData({ attachments, deletedAttachmentsIds }),
    );
  };

  handleReplyToChange = (replyTo) => {
    this.props.dispatch(setCurrentEmailCampaignData({ replyTo }));
  };

  handleFromNameChange = (e) => {
    const fromName = e.target.value;
    this.props.dispatch(setCurrentEmailCampaignData({ fromName }));
    this.setState((prevState) => ({
      hasError: {
        ...prevState.hasError,
        fromName: !hasValidLength(fromName, 3),
      },
    }));
  };
  handleFromNameChange2 = (e) => {
    const localSecondName = e.target.value;
    this.setState({ localSecondName });
    this.props.dispatch(
      setCurrentEmailCampaignData({ secondName: localSecondName }),
    );
    this.setState((prevState) => ({
      hasError: {
        ...prevState.hasError,
        secondName: !hasValidLength(fromName, 3),
      },
    }));
  };
  handleProfessionChange2 = (e) => {
    const localSecondProfession = e.target.value;
    this.setState({ localSecondProfession });
    this.props.dispatch(
      setCurrentEmailCampaignData({ secondProfession: localSecondProfession }),
    );
  };
  handleObjectChange = (e) => {
    const object = (e.target ? e.target.value : e).trimStart();
    this.props.dispatch(setCurrentEmailCampaignData({ object }));
    this.setState((prevState) => ({
      hasError: {
        ...prevState.hasError,
        objectHasError: !hasValidLength(object, 5, 200),
      },
      currentObject: object,
    }));
  };
  handleProfessionChange = (e) => {
    const localProfession = e.target.value;
    this.setState({ localProfession });
    this.props.dispatch(
      setCurrentEmailCampaignData({ profession: localProfession }),
    );
  };

  subjectInputHandler = () => {
    let textArElement = document.getElementsByClassName("params-object");
    if (this.props.object.length && textArElement && textArElement.length > 0) {
      textArElement[0].style.height = "0px";
      textArElement[0].style.height = textArElement.item(0).scrollHeight + "px";
    }
  };
  handleNumberOfNewslettersChange = (e) => {
    this.props.handleGenerateHtml(false);
    const numberOfNewsletter = e.target.value;
    this.props.dispatch(setCurrentEmailCampaignData({ numberOfNewsletter }));
  };

  handleEditorChange = (newhtml) => {
    this.props.setNewHtml(newhtml);
    this.setState({ textContent: newhtml });
    this.props.dispatch(setCurrentEmailCampaignData({ textContent: newhtml }));
  };

  handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (file && file instanceof File) {
      // Remplacer les espaces par des tirets dans le nom du fichier
      const sanitizedFileName = file.name.replace(/\s+/g, "-");
      const sanitizedFile = new File([file], sanitizedFileName, {
        type: file.type,
      });

      const reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ processing: true });
        let notification = { body: "imageUpload", title: "imageUploadSuccess" };

        this.props
          .dispatch(saveMediaImage(sanitizedFile))
          .then((res) => {
            const imageUrl = res.value.data.data.image_url;
            if (imageUrl) {
              this.setState({
                imgPreview: imageUrl,
                processing: false,
              });
              onSuccess(res, notification);
              this.props.dispatch(
                setCurrentEmailCampaignData({
                  imgPreview: imageUrl,
                }),
              );
            } else {
              notification = {
                body: "imageNoUpload",
                title: "imageUploadError",
              };
              onError(res, notification);
              console.error("No image URL returned from the server.");
              this.setState({ processing: false });
            }
          })
          .catch((err) => {
            notification = { body: "imageNoUpload", title: "imageUploadError" };
            onError(res, notification);
            console.error(
              "Image upload to S3 failed:",
              err.response ? err.response.data : err.message,
            );
            this.setState({ processing: false });
          });
      };

      reader.readAsDataURL(file);
    } else {
      console.error("Invalid file or not a Blob.");
    }
  };

  handleImageUrlChange = (event) => {
    const url = event.target.value;
    this.setState({
      localImageUrl: url,
    });

    if (url.startsWith("http")) {
      this.props.dispatch(
        setCurrentEmailCampaignData({
          imgPreview: url,
        }),
      );
    }
  };

  handleClearUrl = () => {
    this.setState({
      localImageUrl: "",
    });

    this.props.dispatch(
      setCurrentEmailCampaignData({
        imgPreview: "",
      }),
    );
  };

  handleToggleSecondSignature = (event) => {
    this.setState({
      isSecondSignatureActive: event.target.checked,
    });
  };

  render() {
    const {
      from,
      fromName,
      replyTo,
      language,
      currentStep,
      object,
      isPlanPersonalized,
      newhtml,
      secondFrom,
      secondName,
      secondProfession,
      currentClient,
      imgPreview,
    } = this.props;

    const {
      registrationStatus,
      registrationStatus2,
      hasError,
      tag,
      currentObject,
      senders,
      localProfession,
      localImageUrl,
    } = this.state;
    const fromCssClass = `${from && !hasError.from && "filled-box"} ${!from && "empty-box"} ${hasError.from && "is-invalid-input"}`;
    const secondFromCssClass = `${secondFrom && !hasError.secondFrom && "filled-box"} ${!secondFrom && "empty-box"} ${hasError.secondFrom && "is-invalid-input"}`;
    const fromNameCssClass = `tab-input ${fromName && !hasError.fromName && "filled-box"} ${!fromName && "empty-box"} ${hasError.fromName && "is-invalid-input"}`;
    var isObjectInvalid = object.length < 5 || !hasValidLength(object, 5, 200);
    var disabled =
      isObjectInvalid ||
      from.length <= 0 ||
      fromName.length <= 0 ||
      language.length <= 0;
    return (
      <div id="send-tabs__tab" className={`${!currentStep && "hide"}`}>
        <div className="tab">
          <h4 className="tab-header">{_("newsletter_config_title")}</h4>

          <div className="row">
            <div className="columns small-12 columns-padding">
              <span className="tab-label">
                {ucFirst(_("subject"))} <span className="required">*</span>
              </span>

              <div className={`small-12`}>
                <textarea
                  style={{ paddingTop: "10px" }}
                  rows={1}
                  className={`tab-input ${object ? "filled-box" : "empty-box"} ${hasError.objectHasError ? "is-invalid-input" : ""}`}
                  placeholder={_("giveCampaignTitle")}
                  onChange={this.handleObjectChange}
                  onInput={this.subjectInputHandler}
                  value={currentObject}
                >
                  {object}
                </textarea>

                <span
                  className={
                    hasError.objectHasError
                      ? "form-error is-visible"
                      : "form-error"
                  }
                >
                  {_("invalidObject") + ". "}
                  {object.length - 200 > 0 &&
                    _("subject_message_info_part_1") +
                      (object.length - 200) +
                      _("subject_message_info_part_2")}
                </span>
              </div>
            </div>
          </div>

          {currentClient.id === 4 && (
            <div className="row">
              <div className="columns small-12 columns-padding">
                <span className="tab-label">
                  {ucFirst(_("imageurl"))} <span className="required">*</span>
                </span>
              </div>
              <div
                className="columns small-6 columns-padding"
                style={{ display: "flex", position: "relative" }}
              >
                <input
                  id="imageInput"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={this.handleImageUpload}
                />
                <input
                  type="text"
                  value={localImageUrl || ""}
                  onChange={this.handleImageUrlChange} // Nouveau gestionnaire de changement
                  className={fromNameCssClass}
                  placeholder={ucFirst(_("pasteimageurl"))}
                  style={{ paddingRight: "30px" }}
                />

                {/* Button "X" to clear the input */}
                {localImageUrl && (
                  <button onClick={this.handleClearUrl} className="btn-clear">
                    &times;
                  </button>
                )}
              </div>
              <div className="columns small-6 columns-padding">
                <button
                  onClick={() => document.getElementById("imageInput").click()}
                  className="btn-upload"
                >
                  {SVG_Image_UPLOAD_FILE}
                  <span className="label-text">
                    {ucFirst(_("uploadImage"))}
                  </span>

                  {this.state.processing && (
                    <div style={{ padding: "9px" }}>
                      <Loader
                        type="line-spin-fade-loader"
                        style={{ transform: "scale(-0.5)" }}
                      />
                    </div>
                  )}
                </button>
              </div>
            </div>
          )}
          <div className="separator"></div>
          <div className="row">
            <div className="columns small-6 columns-padding">
              <span className="tab-label email-field">
                {ucFirst(_("De l'email"))} <span className="required">*</span>
              </span>
              <div className="small-12 m-r-xs from-email-select">
                <span className="validation-icon">
                  {IS_EMAIL_VALID_UA(registrationStatus === "SUCCESS")}
                </span>
                <Select
                  isSearchable
                  placeholder=""
                  value={senders.filter((option) => option.email === from)}
                  onChange={this.handleFromChange}
                  options={senders}
                  components={{ Option: EmailOption }}
                  className={`ttp-select ${fromCssClass}`}
                  classNamePrefix="ttp-select"
                  getOptionValue={(option) => option.email}
                  getOptionLabel={(option) => option.email}
                />
                <span
                  className={
                    hasError.from ? "form-error is-visible" : "form-error"
                  }
                >
                  {_("invalidEmail")}
                </span>
              </div>
            </div>

            <div className="columns small-6 columns-padding">
              <span className="tab-label small-10">
                {ucFirst(_("fromName"))} <span className="required">*</span>
              </span>
              <div className="small-12">
                <input
                  className={fromNameCssClass}
                  type="text"
                  onChange={this.handleFromNameChange}
                  value={fromName}
                />
                <span
                  className={
                    hasError.fromName ? "form-error is-visible" : "form-error"
                  }
                >
                  {_("invalidFromName")}
                </span>
              </div>
            </div>
          </div>
          {/* Profession Field */}
          <div className="row">
            <div className="columns small-6 columns-padding">
              <span className="tab-label small-10">
                {ucFirst(_("profession"))}
                <span className="required">*</span>
              </span>
              <div className="small-12">
                <input
                  type="text"
                  className={`tab-input ${localProfession ? "filled-box" : "empty-box"} ${hasError.profession ? "is-invalid-input" : ""}`}
                  placeholder={_("enterProfession")}
                  onChange={this.handleProfessionChange}
                  value={localProfession}
                />
                <span
                  className={
                    hasError.profession ? "form-error is-visible" : "form-error"
                  }
                >
                  {_("invalidProfession")}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="columns small-12">
              <label
                className="tab-label small-10"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "1.5rem 0",
                }}
              >
                <input
                  type="checkbox"
                  checked={this.state.isSecondSignatureActive}
                  onChange={this.handleToggleSecondSignature}
                  style={{
                    display: "inline-block",
                    margin: "0",
                    marginRight: "10px",
                  }}
                />
                {_("addsecondsignature")}{" "}
              </label>
            </div>

            {this.state.isSecondSignatureActive && (
              <>
                <div className="row"></div>
                <div
                  className="columns small-6 columns-padding"
                  style={{ paddingTop: 0 }}
                >
                  <span
                    className="tab-label email-field"
                    style={{ paddingTop: 0 }}
                  >
                    {ucFirst(_("secondSignatureEmail"))}{" "}
                    <span className="required">*</span>
                  </span>
                  <div className="small-12 m-r-xs from-email-select">
                    <UsersTagSuggestion
                      displayEmail={true}
                      selectedTags={this.state.secondFroms}
                      onTagsChange={this.handleTagsChange}
                    />
                  </div>
                </div>

                <div
                  className="columns small-6 columns-padding"
                  style={{ paddingTop: 0 }}
                >
                  <span
                    className="tab-label small-10"
                    style={{ paddingTop: 0 }}
                  >
                    {ucFirst(_("fromName"))} <span className="required">*</span>
                  </span>
                  <div className="small-12">
                    <input
                      className={fromNameCssClass}
                      type="text"
                      onChange={this.handleFromNameChange2}
                      value={secondName}
                    />
                    <span
                      className={
                        this.state.hasError.secondName
                          ? "form-error is-visible"
                          : "form-error"
                      }
                    >
                      {_("invalidFromName")}
                    </span>
                  </div>
                </div>

                <div className="columns small-6 columns-padding">
                  <span className="tab-label small-10">
                    {ucFirst(_("profession"))}{" "}
                    <span className="required">*</span>
                  </span>
                  <div className="small-12">
                    <input
                      className={fromNameCssClass}
                      type="text"
                      onChange={this.handleProfessionChange2}
                      value={secondProfession}
                      style={{ marginBottom: "1.5rem" }}
                    />
                    <span
                      className={
                        this.state.hasError.secondProfession
                          ? "form-error is-visible"
                          : "form-error"
                      }
                    >
                      {_("invalidFunction")}
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="separator" style={{ marginTop: 0 }}></div>
          <div className="row">
            <div className="columns small-6 columns-padding">
              <span className="tab-label">
                {ucFirst(_("language"))} <span className="required">*</span>
                <span
                  className="tooltip-info"
                  data-tip={_("params_lang_info")}
                  data-for="lang-info"
                >
                  <img src="/img/icons/alert-circle.svg" />
                </span>
              </span>
              <ReactTooltip id="lang-info" place="bottom" multiline={true} />
              <div
                className={`small-12 m-r-xs ${language ? "filled-box" : "empty-box"}`}
              >
                {this.renderLang()}
              </div>
            </div>
            <div className="columns small-6 columns-padding">
              <span className="tab-label small-10">
                {ucFirst(_("replyTo"))}
              </span>
              <div
                className={`small-12 tags-input-container ${replyTo && replyTo.length ? "filled-box" : "empty-box"}`}
              >
                <TagsInput
                  value={replyTo}
                  onChange={this.handleReplyToChange}
                  addKeys={[9, 13, 186, 188]}
                  removeKeys={[]}
                  onlyUnique={true}
                  addOnPaste
                  validationRegex={TAG_REGEX}
                  inputProps={{ placeholder: _("emailAdd") }}
                  inputValue={tag}
                  onChangeInput={this.handleChangeInput.bind(this)}
                />
              </div>
              <span
                className={
                  hasError.replyTo ? "form-error is-visible" : "form-error"
                }
              >
                {_("replyToError")}
              </span>
            </div>

            <div style={{ paddingTop: "28px" }}>
              <SunEdit changed={this.handleEditorChange} content={newhtml} autoFocus={false}/>
            </div>
          </div>

          {/*Pieces jointes*/}
          {/* <div className="row">
            <div className="columns columns-padding">
              <span className="tab-label">{ucFirst(_("Attachments"))}</span>
              <div className="attachments-container">
                <AttachmentsContainer
                  attachments={attachments}
                  onDeleteAttachment={this.handleDeleteAttachment}
                />
                <Dropzone
                  className="attachments-dropzone"
                  onDrop={this.handleDrop}
                  multiple={false}
                  accept={ACCEPT_MIME_TYPES.join(",")}
                >
                  <span className="icon icon-cloud-upload" />
                  <p>
                    {_(
                      "Drag and drop some files here, or click to select files to upload."
                    )}
                  </p>
                </Dropzone>
              </div>
            </div>
          </div>*/}
        </div>

        <StepsFooter
          disabled={disabled}
          isPlanPersonalized={isPlanPersonalized}
          currentPage={this.props.currentPage}
        />
      </div>
    );
  }
}

const TAG_REGEX =
  /^[a-zA-Z\s]*<\s*[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?\s*>$|^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;

const EmailOption = (props) => {
  const { email, name, label } = props.data;

  return (
    <components.Option {...props}>
      <div className="label-from">
        <span className="label-from__name">{name ? name : label}</span>
        <span className="label-from__email">{email}</span>
      </div>
    </components.Option>
  );
};
