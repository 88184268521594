import React from "react";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";

import SearchBox from "../common/filter/SearchBox";
import _ from "i18n";
import { fetchTemplates } from "thunks";
import { ThemeCard } from "../ui/ThemeCard";
import { ThemeCardFetching } from "../fetching/ThemeCardFetching";
import NotFound from "../notFound/NotFound";
import {
  initTemplatesList,
  setThemesPaginationPage,
  setThemeSearchWord,
  setThemeScope,
  setThemeType,
} from "../../actions/actions";
import ThemeCategoryFilter from "../theme/filter/ThemeCategoryFilter";
import { COMMUNITY_SCOPE } from "../../constants";
import RecipientType from "../campaign/params/RecipientType";

class SampleSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasMoreItems: true,
      searchWord: "",
      hasError: false,
    };
  }

  componentDidMount() {
    this.props.initSamples();
    this.props.setThemeScope(COMMUNITY_SCOPE);
    this.props.setThemeType(this.props.type);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter != this.props.filter) {
      this.props.fetchSamples({ ...this.props.filter, isBlock: true });
    }
    if (this.props.fetching !== prevProps.fetching) {
      this.setState({
        hasMoreItems: !this.props.fetching,
      });
    }
  }

  handleSearchChange = (searchWord) => {
    this.props.setThemeSearchWord(searchWord);
  };

  loadItems = () => {
    const {
      filter,
      fetching,
      nbResult,
      samples,
      setThemesPaginationPage,
      recipientType
    } = this.props;

    if (!fetching && recipientType && samples?.length < nbResult) {
      let currentPage = filter.paginationPage;
      this.setState({ hasMoreItems: false });
      setThemesPaginationPage(currentPage + 1);
    }
  };

  renderThemesList = () => {
    const { samples, onSelect, currentTheme, recipientType } = this.props;

    return samples?.map((theme) => (
      <div key={theme.id} className="large-3 small-4">
        <ThemeCard
          isActive={theme.id === currentTheme}
          {...theme}
          onSelect={() => {recipientType ? onSelect(theme) : this.setState({...this.state, hasError: true})}}
        />
      </div>
    ));
  };
  handleSetError = (value) => {
    this.setState({ hasError: value});
  };


  render() {
    const {
      samples,
      onSelect,
      addEmptyIcon,
      fetching,
      filter: { scope },
      recipientType
    } = this.props;
    const { hasMoreItems, hasError } = this.state;

    return (
      <div className={"sample-selector"}>
        <ThemeCategoryFilter />
        <div
          className="sample-selector__container"
          ref={(ref) => (this.scrollParentRef = ref)}
        >
          <div className="sample-selector__header">
            <span>
              {scope == COMMUNITY_SCOPE ? _("my models") : _("library")}
            </span>
            <div style={!recipientType ? {display: 'flex', margin: '157px 198px 0 0', transition: 'margin-top 1s ease'} : {display: 'flex', margin: '0 198px 0 0'}}>
              <span className={hasError ? "param__title small-5" : "param__title small-9"}
                    style={{marginTop: '10px'}}>{_('recipientType') + ' :'}</span>
              <div className={hasError ? "small-6" : "small-12"}>
                <RecipientType isSampleSelector={true} handleSetError={this.handleSetError}/>
              </div>
              <span
                className={
                  hasError
                    ? "form-error is-visible"
                    : "form-error"
                }
                style={{width: '130px', marginLeft: '20px'}}
              >
                {hasError &&
                  _("selectType") }
              </span>
            </div>
            <SearchBox onChange={this.handleSearchChange}/>
          </div>
          <InfiniteScroll
            className={"sample-selector__content row"}
            pageStart={0}
            loadMore={this.loadItems}
            hasMore={hasMoreItems}
            useWindow={false}
            getScrollParent={() => this.scrollParentRef}
          >
            {addEmptyIcon && !!recipientType && (
              <div className="large-3 small-4">
                <div
                  className={"theme-card empty-sample"}
                  onClick={() => onSelect(null)}
                >
                  <img src={"/img/icons/" + addEmptyIcon} />
                </div>
              </div>
            )}
            {samples && samples.length > 0 && !!recipientType
              ? this.renderThemesList()
              : !addEmptyIcon &&
                !fetching && recipientType && (
                  <NotFound
                    text={"Oops, no templates were found."}
                    mainClass="small-10"
                  />
                )}
            {fetching && !!recipientType &&
              [1, 2, 3, 4, 5].map((index) => (
                <div
                  key={"theme-fetching-" + index}
                  className="large-3 small-4"
                >
                  <ThemeCardFetching />
                </div>
              ))}
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  samples: store.themes.list.items,
  nbResult: store.themes.list.nbResult,
  filter: store.themes.filter,
  fetching: store.themes.list.fetching,
  categories: store.themes.categories.items,
  lng: store.params.lng,
  recipientType: store.currentCampaign.email.recipientType,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSamples: (filter) => dispatch(fetchTemplates(filter)),
  initSamples: () => dispatch(initTemplatesList()),
  setThemesPaginationPage: (page) => dispatch(setThemesPaginationPage(page)),
  setThemeScope: (scope) => dispatch(setThemeScope(scope)),
  setThemeType: (type) => dispatch(setThemeType(type)),
  setThemeSearchWord: (word) => dispatch(setThemeSearchWord(word)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SampleSelector);
