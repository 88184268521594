import React, { Component } from 'react';
import { connect } from 'react-redux';

import { setTTPDialogCustomData, setThemeContent, setStep } from "actions";
import { saveTheme, updateThemeVisibility, manageShareTheme, softDeleteThemeWithAssociatedCampaigns } from 'thunks';
import { onError } from 'utils';
import _ from 'i18n';
import DIALOG_TYPES from '../../constants/TTPDialog';
import { STEPS, SVG_ICONS } from 'Common';
import { TEMPLATE_TYPES } from 'constants';
import { replaceContentFeed } from '../../services/block/newsletter';
import {onSuccess, SVG_EYE, SVG_EYE_OF} from '../../services/utils';


@connect((store) => ({
  currentClient: store.auth.currentClient,
  updating: store.themes.updating
}))
export default class ThemeActions extends Component {

  replicateTheme(id) {
    let data = { name: _('newTemplate'), originTheme: id, action: 'replicate', actif: 1 };
    this.props.dispatch(saveTheme(data))
      .then(res => {
        if (res.value && res.value.data && res.value.data.data) {
          id = res.value.data.data.id;
        }
      },
        err => {
          onError(err);
        }
      );
  }

  previewTemplate = (content) => {
    let themeContent = content;
    if (this.props.type === TEMPLATE_TYPES['TEMPLATE']) {
      themeContent = replaceContentFeed(content);
    }

    this.props.dispatch(setThemeContent(themeContent));
    this.props.dispatch(setStep(STEPS['THEME_PREVIEW']));
  }

  updateThemeVisibility = (visibility) => {
    const { dispatch, id, associatedNotSentCampaigns } = this.props;
    if (visibility === THEME_VISIBLE) {
      dispatch(updateThemeVisibility(id, visibility));
      return;
    }

    const message = (visibility === THEME_HIDDEN) ? _('Are you sure you want to hide this model') : _('Are you sure you want to delete this model');

    dispatch(setTTPDialogCustomData({
      approvedAction: () => {
        try {
         dispatch(softDeleteThemeWithAssociatedCampaigns(id, undefined, undefined)).then((res) => {
         onSuccess(res, null, 'errorOccured');
         });
        } catch(e){
          onError();
      }},
      approvedSecondAction: () => {
        try {
         dispatch(softDeleteThemeWithAssociatedCampaigns(id, associatedNotSentCampaigns, true)).then((res) => {
         onSuccess(res, null, 'errorOccured');
         });
        } catch(e){
          onError();
      }},
      message: `${message} ?`,
      'type': DIALOG_TYPES["ALERT"],
      associatedNotSentCampaigns: associatedNotSentCampaigns,
      proceedBtnLabel: _('deleteModel'),
      proceedSecondBtnLabel: associatedNotSentCampaigns ? _('delete_all') : '',
    }));
  };

  shareTheme = () => {
    const { dispatch, id } = this.props;
    dispatch(setTTPDialogCustomData({
      approvedAction: () => dispatch(manageShareTheme(id, 1)),
      message: _('share_template_info'),
      type: DIALOG_TYPES["WARNING"],
      proceedBtnLabel: _('share')
    }));
  };

  stopSharingTheme = () => {
    const { dispatch, id } = this.props;
    dispatch(setTTPDialogCustomData({
      approvedAction: () => dispatch(manageShareTheme(id, 0)),
      message: _('stop_share_template_info'),
      type: DIALOG_TYPES["WARNING"],
      proceedBtnLabel: _('Stop sharing')
    }));
  };

  render() {
    const {id, content, isPublic, editTheme, loggedAs, organization, currentClient, type, actif, originality, updating, sendTestTheme} = this.props;
    const isOwner = loggedAs === "ADMIN" && organization && currentClient && organization.id === currentClient.id;
    const showShare = !isPublic && isOwner;
    const showStopShare = isOwner && (isPublic || isPublic === 1);

    return <div className="theme-actions">
      <div className="theme-actions__action" onClick={() => this.previewTemplate(content)} title={_("preview")}>
        <img src={"/img/icons/" + SVG_ICONS["PREVIEW"]} />
      </div>
      {isOwner && (type !== TEMPLATE_TYPES['TEMPLATE'] || originality === "replication") &&
        <div className="theme-actions__action" onClick={editTheme} title={_("edit the model")}>
          <img src={"/img/icons/" + SVG_ICONS["EDIT"]} />
        </div>
      }
      <div className="theme-actions__action" onClick={() => !updating && this.replicateTheme(id)} title={_("duplicate the model")}>
        <img src={"/img/icons/" + SVG_ICONS["DUPLICATE"]} />
      </div>
      {showShare && <div className="theme-actions__action" onClick={this.shareTheme} title={_("share")}>
        <img src={"/img/icons/" + SVG_ICONS["SHARE"]} />
      </div>}
      {showStopShare && <div className="theme-actions__action" onClick={this.stopSharingTheme} title={_("Stop sharing")}>
        <img src={"/img/icons/" + SVG_ICONS["STOP_SHARE"]} />
      </div>}
      {/* {!inGallery && <div className="theme-actions__action" onClick={onSettingClick} title={_("settings")}>
                <img src={"/img/icons/" + SVG_ICONS["LINK"]  }/>
              </div>} */}
      {
        (actif === THEME_VISIBLE) ?
          <div className="theme-actions__action" onClick={() => this.updateThemeVisibility(THEME_HIDDEN)} title={_("mask")}>
            {SVG_EYE}
          </div>
          :
          <div className="theme-actions__action" onClick={() => this.updateThemeVisibility(THEME_VISIBLE)} title={_("unmask")}>
            {SVG_EYE_OF}
          </div>
      }
      <div className="theme-actions__action" onClick={sendTestTheme} title={_("send a test")}>
        <img src={"/img/icons/" + SVG_ICONS["EMAIL_LIGHT"]}/>
      </div>
      {isOwner && <div className="theme-actions__action delete-action" onClick={() => this.updateThemeVisibility(THEME_INVISIBLE)} title={_("delete")}>
        <img src={"/img/icons/" + SVG_ICONS["DELETE"]} />
      </div>}
      {/*!isOwner && <div className="theme-actions__action" onClick={useTheme} title={_("use")}>
        <img src={"/img/icons/" + SVG_ICONS["PLUS"]} />
      </div>*/}
    </div>
  }
}

const THEME_INVISIBLE = 0;
const THEME_VISIBLE = 1;
const THEME_HIDDEN = 2;
