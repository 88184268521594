const initialStateForTextModelReducer = {
  items: [],
  nbResult: 0,
  fetching: false,
  updating: false,
  fetched: false,
  error: null,
  datepickerDateTimeFormat: null,
  datepickerDateFormat: null,
  datepickerTimeFormat: null,
  onlyMain: false,
  filter: {
    pageSize: 8,
    paginationPage: 1,
    language: [],
    targetApp: "",
    scope: [],
    targetType: [],
    searchWord: "",
  },
}
export const textModelReducer = (state = initialStateForTextModelReducer, action) => {
  switch (action.type) {
    case "INIT_TEXT_MODELS": {
      return initialStateForTextModelReducer;
    }
    case 'FETCH_TEXT_MODELS_PENDING': {
      let items = (1 == state.filter.paginationPage) ? [] : state.items;
      return { ...state, items, fetching: true, fetched: false };
      break;
    }

    case 'SET_TEXT_MODELS_PAGINATION_PAGE': {
      return { ...state, filter: { ...state.filter, paginationPage: action.pageNumber } };
      break;
    }

    case 'SET_TEXT_MODELS_SEARCHWORD': {
      return { ...state, filter: { ...state.filter, searchWord: action.searchWord, paginationPage: 1 } };
      break;
    }

    case 'SET_TEXT_MODELS_LANGUAGE': {
      return { ...state, filter: { ...state.filter, language: action.language, paginationPage: 1 } };
      break;
    }

    case 'SET_TEXT_MODELS_ONLY_MAIN':
      return {
        ...state,
        onlyMain: action.payload
      };


    case 'SET_TEXT_MODELS_TARGET_APP': {
      return { ...state, filter: { ...state.filter, targetApp: action.targetApp, paginationPage: 1 } };
      break;
    }

    case 'SET_TEXT_MODELS_TARGET_TYPE': {
      return { ...state, filter: { ...state.filter, targetType: action.targetType, paginationPage: 1 } };
      break;
    }

    case 'SET_TEXT_MODELS_SCOPE': {
      return { ...state, filter: { ...state.filter, scope: action.scope, paginationPage: 1 } };
      break;
    }

    case 'SET_FREE_SECTION_DATE_PICKER_DATE_TIME': {
      return { ...state, datepickerDateTimeFormat: action.dateTime };
      break;
    }

    case 'SET_FREE_SECTION_DATE_PICKER_DATE': {
      return { ...state, datepickerDateFormat: action.date };
      break;
    }

    case 'SET_FREE_SECTION_DATE_PICKER_TIME': {
      return { ...state, datepickerTimeFormat: action.time };
      break;
    }

    case 'SET_SELECTED_TEXT_MODEL': {
      let items = (state.items || []).filter(t => t.id != action.textModel.id);
      items.unshift(action.textModel);
      return { ...state, items };
      break;
    }

    case 'FETCH_TEXT_MODELS_FULFILLED': {
      const { data, nbResult } = action.payload.data;
      const { filter, items } = state;
      let newItems = [];
      if (1 == filter.paginationPage) {
        let seletedTextModels = items.filter(e => e.isAdded);
        if (seletedTextModels && seletedTextModels.length == 1) {
          data.unshift({ ...seletedTextModels[0], isAdded: false })
        }
        newItems = data;
      } else {
        newItems = items;
        newItems.push(...data);
      }

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: newItems,
        nbResult,
      };
      break;
    }
    case 'FETCH_TEXT_MODELS_REJECTED': {
      let { filter, nbResult, items } = state;
      let newItems = items;

      if (filter.paginationPage == 1) {
        newItems = [];
        nbResult = 0;
      }
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404
          }
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        items: newItems,
        nbResult,
        error
      };
      break;
    }
    case 'SAVE_TEXT_MODEL_PENDING': {
      return {
        ...state,
        updating: true,
      };
      break;
    }
    case 'SAVE_TEXT_MODEL_FULFILLED': {
      const { items } = state;
      let newTextModel = action.payload;

      let exist = false;
      let newItems = items.map(item => {
        if (item.id === newTextModel.id) {
          exist = true;
          return { ...item, ...newTextModel };
        }
        let isMain = newTextModel.main === 1 && item.language === newTextModel.language && item.targetApp === newTextModel.targetApp && item.targetType === newTextModel.targetType;

        return isMain ? { ...item, main: 0 } : item;
      });

      if (!exist) {
        newItems.unshift(newTextModel);
      }

      return {
        ...state,
        items: newItems,
        fetching: false,
        fetched: true,
        updating: false,
      };
      break;
    }
    case "SAVE_TEXT_MODEL_REJECTED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        updating: false,
      };
    }
    case 'REMOVE_TEXT_MODEL_FULFILLED': {
      const {payload} = action;
      let updatedItems = state.items;

      if (payload) {
        let deletedTextData = payload.submittedData;
        let newItems = state.items.filter(item => item.id !== payload.deletedId);
        updatedItems = newItems;

        if (deletedTextData.main === 1) {
          let filteredItems = newItems.filter(item => item.language === deletedTextData.language && item.targetApp === deletedTextData.targetApp && item.targetType === deletedTextData.targetType);
          updatedItems = newItems.map(item => {
            if (item.id === filteredItems[0].id) {
              return {...item, main: 1};
            }
            return item;
          });
        }
      }

      return {
        ...state,
        items: updatedItems,
        nbResult: state.nbResult - 1
      };
      break;
    }
    case 'SEND_TEXT_MODEL_TEST_PENDING': {
      return { ...state, currentEmail: { ...state.currentEmail, updating: true } };
    }
    case 'SEND_TEXT_MODEL_TEST_FULFILLED': {
      return { ...state, currentEmail: { ...state.currentEmail, updating: false } };
    }
    case 'SEND_TEXT_MODEL_TEST_REJECTED': {
      return { ...state, currentEmail: { ...state.currentEmail, updating: false } };
    }
    default:
      return state;
  }
};
