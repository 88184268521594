import axios from "../axios-api";
import { throwCatchedError, updateSourceToken } from "utils";

let getClientThemesSourceToken;
let getThemeCategoriesSourceToken;

export const getClientThemes = (
  token,
  clientId,
  data,
  userId,
  sourceToken = null
) => {
  getClientThemesSourceToken = updateSourceToken(
    getClientThemesSourceToken,
    sourceToken
  );
  const fields = ["*", "allowedUsers"];
  const requestUrl = `/mailing/theme`;

  let filter = [];

  if (data.isPublicTheme) {
    filter.push({
      property: "isPublic",
      value: 1,
      operator: "eq"
    });
  }

  if (
    !data.id &&
    !data.isPublicTheme &&
    !(data.scope && data.scope.length > 0)
  ) {
    filter.push({
      property: "organization",
      value: clientId,
      operator: "eq"
    });
  }

  if (data.language && data.language.length > 0) {
    let languages = ["all"];
    if (data.language instanceof Array) {
      languages = languages.concat(data.language);
    } else {
      languages.push(data.language);
    }
    filter.push({
      property: "language",
      value: languages,
      operator: "in"
    });
  }

  if (data.isBlock || data.id || data.type == "TEMPLATE") {
    fields.push("design");
  }

  if (data.getUserAvatar) {
    fields.push("avatar");
  }

  if (data.id) {
    filter.push({
      property: "id",
      value: data.id,
      operator: "eq"
    });
  }

  if (userId) {
    filter.push({
      property: "allowedUsers",
      value: `"${userId}"`,
      operator: "like"
    });
  }
  if (data.searchWord) {
    filter.push({
      property: "name",
      value: data.searchWord,
      operator: "like"
    });
  }
  if (data.application && data.application.length > 0) {
    filter.push({
      property: "targetApp",
      value: data.application,
      operator: "in"
    });
  }
  if (data.type && data.type.length > 0) {
    filter.push({
      property: "type",
      value: data.type,
      operator: "in"
    });
  }

  if (data.category) {
    filter.push({
      property: "themeCategory",
      value: data.category,
      operator: "eq"
    });
  }

  if (data.visibility) {
    filter.push({
      property: "actif",
      value: data.visibility,
      operator: "eq"
    });
  }

  if (data.layoutIds && data.layoutIds.length > 0) {
    filter.push({
      property: "id",
      value: data.layoutIds,
      operator: "in"
    });
  }

  if (data.scope && data.scope.length > 0) {
    let orgOperator = "eqOrNull";
    let orgValue = clientId;

    if (!Array.isArray(data.scope)) {
      if (data.scope === "SHARED_MODELS") {
        filter.push({
          property: "isPublic",
          value: 1,
          operator: "eq"
        });
      } else {
        orgOperator = data.scope === "COMMUNITY" ? "eq" : "null";
        orgValue = data.scope === "COMMUNITY" ? clientId : "";

        filter.push({
          property: "organization",
          value: orgValue,
          operator: orgOperator
        });
      }
    }
  }

  let sort = [];
  sort.push({
    property: "updatedAt",
    dir: data.sortDirection ? data.sortDirection : "desc"
  });

  let params = {
    access_token: token,
    fields: fields.join(","),
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort)
  };
  if (data.pageSize && data.paginationPage) {
    params = {
      ...params,
      limit: data.pageSize,
      start: data.offset,
      organization_id: clientId
    };
  } else {
    params.nolimit = 1;
  }

  return axios
    .get(requestUrl, {
      cancelToken: params.start == 0 && getClientThemesSourceToken.token,
      params
    })
    .catch(function(thrown) {
      throwCatchedError(thrown);
    });
};

export const persistMultiTheme = (token, data) => {
  if (!data.themesIds) {
    return;
  }
  const requestUrl = `/mailing/theme/multi-save`;
  var formData = new FormData();
  formData.append("access_token", token);
  for (let i = 0; i < data.themesIds.length; i++) {
    formData.append("themesIds[]", data.themesIds[i]);
  }
  if (data.allowedUsers) {
    for (let i = 0; i < data.allowedUsers.length; i++) {
      formData.append("allowedUsers[]", data.allowedUsers[i]);
    }
  }
  if (data.approvalTeams) {
    for (let i = 0; i < data.approvalTeams.length; i++) {
      formData.append("approvalTeams[]", data.approvalTeams[i]);
    }
  }
  return axios.post(requestUrl, formData);
};

export const persistTheme = (token, data) => {
  const requestUrl = `/mailing/theme`;
  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("name", data.name);
  formData.append("updatedBy", data.updatedBy);
  formData.append("organization", data.clientId);

  if (data.content) {
    formData.append("content", data.content);
  }
  if (data?.themeObject || data?.themeObject === '') {
    formData.append("themeObject", data.themeObject);
  }
  if (data.language) {
    formData.append("language", data.language);
  }
  if (data.id) {
    formData.append("id", data.id);
  }
  if (data.actif) {
    formData.append("actif", data.actif);
  }
  if (data.originTheme) {
    formData.append("originTheme", data.originTheme);
  }
  if (data.action) {
    formData.append("action", data.action);
  }
  if (data.object) {
    formData.append("object", data.object);
  }
  if (data.design) {
    formData.append("design", JSON.stringify(data.design));
  }
  if (data.type) {
    formData.append("type", data.type);
  }
  if (data.main) {
    formData.append("main", data.main);
  }
  if (data.themeCategory) {
    formData.append("themeCategory", data.themeCategory);
  }

  formData.append(
    "targetApp",
    !data.targetApp || data.targetApp == "all" ? "" : data.targetApp
  );

  if (data.images) {
    for (let i = 0; i < data.images.length; i++) {
      formData.append("images[]", data.images[i]);
    }
  }

  return axios.post(requestUrl, formData);
};

export const persistSoftDeleteThemeWithAssociatedCampaigns = (token, id, associatedCampaigns, deleteCampaigns) => {
  const requestUrl = `/mailing/theme/softDelete`;
  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("theme", id);
  formData.append("campaignIds", JSON.stringify(associatedCampaigns));
  formData.append("deleteCampaigns", deleteCampaigns);
  return axios.post(requestUrl, formData);
};

export const editThemeVisibility = (token, id, visbility) => {
  var formData = new FormData();
  const requestUrl = `/mailing/theme`;
  formData.append("access_token", token);
  formData.append("id", id);
  formData.append("actif", visbility);
  return axios.post(requestUrl, formData);
};

export const getThemesCategories = (token, clientId, sourceToken) => {
  getThemeCategoriesSourceToken = updateSourceToken(
    getThemeCategoriesSourceToken,
    sourceToken
  );
  const requestUrl = `/mailing/theme-category`;
  let filter = [];

  if (clientId) {
    filter.push({
      property: "organization",
      value: clientId,
      operator: "eqOrNull"
    });
  }

  return axios.get(requestUrl, {
    cancelToken: getThemeCategoriesSourceToken.token,
    params: {
      access_token: token,
      fields: "*",
      filter: JSON.stringify(filter),
      nolimit: 1
    }
  });
};

export const sendTest = (token, data) => {
  var formData = new FormData();
  const requestUrl = `/mailing/theme/test`;
  formData.append("access_token", token);
  if ((!data.id && !data.html) || data.emails.length == 0) {
    return;
  }
  if (data.id) {
    formData.append("id", data.id);
  }
  formData.append("html", data.html);
  data.emails.forEach((email, i) => {
    formData.append(`emails[${i}]`, email);
  });

  return axios.post(requestUrl, formData);
};

export const manageShareTemplate = (token, data) => {
  const requestUrl = `/mailing/theme`;
  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("id", data.id);
  formData.append("isPublic", data.isShare);
  formData.append("updatedBy", data.updatedBy);

  return axios.post(requestUrl, formData);
};

export const persistThemeCategory = (token, data) => {
  const requestUrl = `/mailing/theme-category`;
  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("nameFr", data.nameFr);
  formData.append("nameEn", data.nameEn);
  formData.append("nameNl", data.nameNl);
  formData.append("organization", data.clientId);

  return axios.post(requestUrl, formData);
};

export const deleteThemeCategory = (token, id) => {
  var formData = new FormData();
  const requestUrl = `/mailing/theme-category/${id}`;
  formData.append("access_token", token);
  formData.append("_method", "delete");
  return axios.post(requestUrl, formData);
};

export const updateThemeCategory = (token, data) => {
  var formData = new FormData();
  const requestUrl = `/mailing/theme-category/${data.id}`;
  formData.append("access_token", token);
  formData.append("nameFr", data.nameFr);
  formData.append("nameEn", data.nameEn);
  formData.append("nameNl", data.nameNl);
  return axios.post(requestUrl, formData);
};

export const getClientTheme = (token, id) => {
  const fields = ["*", "allowedUsers", "design"];
  const requestUrl = `/mailing/theme/${id}`;

  let params = {
    access_token: token,
    fields: fields.join(",")
  };

  return axios
    .get(requestUrl, {
      params
    })
    .catch(function(thrown) {
      throwCatchedError(thrown);
    });
};
