import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import UserAvatar from './UserAvatar';
import { LANGUAGE_FLAGS } from '../../config/Common';
import _ from 'i18n';
import ThemeActions from './ThemeActions';
import { setCurrentDndTheme, setCurrentSimpleTheme } from "actions";
import SendTestForm from "../common/SendTestForm";
import {sendThemeTest} from "../../actions/thunks/theme";
import { onError, onSuccess } from "utils";

export class ThemeItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSendThemeTest: false
    };
    this.toggleTestForm = () => this.setState({ showSendThemeTest: !this.state.showSendThemeTest });
  }

  useTheme = (action) => {
    const { id, name, content, design, language, object, isPublic, main, gdprThemeId, type, targetApp, setCurrentDndTheme, setCurrentSimpleTheme, themeCategory, organization, themeObject, associatedNotSentCampaigns } = this.props;
    let theme = { name, content, design, language, object, isPublic, main, gdprThemeId, type, targetApp, themeCategory, organization, themeObject, associatedNotSentCampaigns };
    if (action === "EDIT") {
      theme["id"] = id;
    }
    if (type === "SIMPLE") {
      setCurrentSimpleTheme(theme);
      this.props.history.push(`/templates/${id}`);
    } else {
      setCurrentDndTheme(theme);
      this.props.history.push(`/drag-drop`);
    }
  };

  sendTestTheme = (recipients) => {
    const {id, content} = this.props;
    let data = {id, html: content, emails: recipients};
    this.props.sendThemeTest(data).then(
      (res) => {
        this.toggleTestForm();
        onSuccess(res);
      },
      (err) => onError(err)
    )
  };

  render() {
    const { name, originality, language, organization, updatedAt, updatedBy, preview,
      onSettingClick, isPublic, loggedAs, actif, updating, campaignIds } = this.props;

    return (
      <div className={`large-3 medium-4 small-6 theme__item-container`}>
        <div className={`theme__item`}>
          <div className={`theme__item__preview ${(actif === 2) ? 'masked' : ''}`} style={{ backgroundImage: `url(${(preview) ? `${preview}` : 'img/theme/no-theme.png'})` }} />
          <div className="theme__item__status">
            <div className="item-name">
              {name}
              <span className="item-lng">
                {LANGUAGE_FLAGS[language] && <span className={`flag-icon  ${LANGUAGE_FLAGS[language]}`}/>}
              </span>
            </div>
            <div className="item-tags">
              <span className="item-tag">
                <i className={isPublic ? "icon-share" : "icon-lock"}/>{isPublic ? _('shared') : _('private')}
              </span>
              <span className="item-tag"><i className="icon-tag"/>{_(originality)}</span>
            </div>
            <UserAvatar updatedAt={updatedAt} updatedBy={updatedBy} />
            <ThemeActions
              loggedAs={loggedAs}
              {...this.props}
              onSettingClick={onSettingClick} organization={organization}
              useTheme={() => this.useTheme("USE")}
              editTheme={() => this.useTheme("EDIT")}
              sendTestTheme={this.toggleTestForm}
            />
            {this.state.showSendThemeTest &&
            <SendTestForm processing={updating}
              onCloseModal={this.toggleTestForm}
              onSave={this.sendTestTheme}
            />
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  loggedAs: state.auth.loggedAs,
  updating: state.themes.updating,
});
const mapDispatchToProps = dispatch => ({
  setCurrentDndTheme: currentTheme => dispatch(setCurrentDndTheme(currentTheme)),
  setCurrentSimpleTheme: currentTheme => dispatch(setCurrentSimpleTheme(currentTheme)),
  sendThemeTest: data => dispatch(sendThemeTest(data)),
});
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)
  (ThemeItem);
