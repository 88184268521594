import axios from "../axios-api";

import {
  convertReplyTo,
  throwCatchedError,
  updateSourceToken,
  convertDateToUTC,
} from "utils";
import {
  EVENT_RECIPIENT_TYPES,
  PAYMENT_RECIPIENT_TYPES,
} from "../../config/Common";

export * from "./recipient";

let getEmailsSourceToken;
let getCampaignSourceToken;
let getDelegationTypesSourceToken;

export const saveCampaign = (token, data, clientId, userId) => {
  const requestUrl = `/mailing/campaign`;

  const formData = new FormData();
  formData.append("access_token", token);
  formData.append("status", data.status);

  if (data.action && ["TEST", "SEND", "DRAFT"].indexOf(data.action) > -1) {
    if (clientId) {
      formData.append("client", clientId);
    } else if (userId) {
      formData.append("user", userId);
    }
    formData.append("object", data.object);
    formData.append("from", data.from);
    formData.append("fromName", data.fromName);
    formData.append("replyTo", convertReplyTo(data.replyTo));
    formData.append("recipientType", data.recipientType);
    formData.append("uaRecipientType", JSON.stringify(data.uaRecipientType));
    formData.append("language", data.language);
    formData.append("type", data.type);
    formData.append("action", data.action);
    formData.append(
      "totalRecipients",
      data.nbUniqueRecipients ? data.nbUniqueRecipients : data.totalRecipients,
    );
    formData.append("isRebroadcasted", data.isRebroadcasted);
    formData.append("rebroadcastedFrom", data.rebroadcastedFrom);
    formData.append(
      "notifyCommunityChannel",
      data.notifyCommunityChannel ? 1 : 0,
    );
    formData.append("isUAVersion", data.isUAVersion ? 1 : 0);
    formData.append("isUaNewsletter", data.isUAVersion ? 1 : 0);
    formData.append("numberOfNewsletter", data.numberOfNewsletter);
    formData.append("uaFilters", JSON.stringify(data.uaFilters || []));
    formData.append("introduction", data.introduction);
    formData.append("autoSendApprovalNl", data.autoSendApprovalNl ? 1 : 0);
    formData.append("delegationTypes", JSON.stringify(data.delegationTypes));
    if (data.surveyType) {
      formData.append("surveyType", data.surveyType);
    }

    if (data.surveyInstance && data.surveyInstance.id) {
      formData.append("surveyInstance", data.surveyInstance.id);
    }

    if (data.approvalTeams) {
      formData.append("approvalTeams", data.approvalTeams);
    }

    if (data.mailingLists) {
      let lists = data.mailingLists;
      if (!(lists instanceof Array)) {
        lists = lists.split(",");
      }
      for (let i = 0; i < lists.length; i++) {
        formData.append(`mailingListIds[${i}]`, lists[i]);
      }
    }
    formData.append("ezineNl", data.ezineNl);

    if (data.survey) {
      formData.append("survey", data.survey);
    }
    formData.append(
      "scheduledDeliveryAt",
      data.scheduledDeliveryAt
        ? convertDateToUTC(data.scheduledDeliveryAt)
        : "",
    );
    if (data.attachments) {
      for (var i = 0; i < data.attachments.length; i++) {
        if (data.attachments[i] instanceof File) {
          const file = data.attachments[i];
          formData.append(`file[${i}]`, file, file.name);
        }
      }
    }
    if (data.deletedAttachmentsIds) {
      for (var i = 0; i < data.deletedAttachmentsIds.length; i++) {
        formData.append(
          `deletedAttachmentsIds[${i}]`,
          data.deletedAttachmentsIds[i],
        );
      }
    }
    if (
      [
        ...EVENT_RECIPIENT_TYPES,
        ...PAYMENT_RECIPIENT_TYPES,
        "CONTACT",
        "COLLABORATOR",
        "FOLDER",
      ].indexOf(data.recipientType) > -1
    ) {
      const eventFilters =
        data.oeccbbFilters instanceof Array && data.oeccbbFilters
          ? [...data.oeccbbFilters]
          : data.eventFilters;
      formData.append("eventFilters", JSON.stringify(eventFilters || []));
    }
    if (data?.theme?.id) {
      formData.append("theme", data.theme.id);
    }

    if (data.type === "DND_TEMPLATE") {
      formData.append("html", data.html);
      formData.append("design", JSON.stringify(data.design));
      formData.append("newsLetter", data.isUAVersion ? 1 : data.newsLetter);
      formData.append("fields", data.fields);
    }
    if (data.type === "PLAIN_TEXT") {
      formData.append("html", data.html);
      formData.append("textModel", data.textModel);
      formData.append(
        "design",
        typeof data.design === "string"
          ? data.design
          : JSON.stringify(data.design),
      );
      if (data.imgPreview !== undefined && data.imgPreview !== null) {
        formData.append("imgPreview", data.imgPreview);
      }
      
      const signature = {
        name: data.fromName,
        profession: data.profession,
        avatarUrl: data.avatarUrl,
        secondName: data.secondName,
        secondProfession: data.secondProfession,
        secondAvatar: data.secondAvatar,
        secondFrom: data.secondFrom,
      };

      formData.append("signature", JSON.stringify(signature));

      formData.append("mutatedContent", data.mutatedContent);
    } else {
      formData.append("pageUrl", data.pageUrl);
      let isNewsLetter = data.pageUrl.match("/storage/tmpSave/");
      isNewsLetter ? formData.append("newsLetter", 1) : "";
    }
    if (data.action === "TEST") {
      formData.append("isTested", 1);
      formData.append("sendTestOptions", JSON.stringify(data.sendTestOptions));
      formData.append("testEmails", JSON.stringify(data.testEmails));
      formData.append("testComment", data.testComment);
    }
    if (
      data.campaignGroups &&
      data.campaignGroups.length > 0 &&
      data.recipientType !== "MANUAL"
    ) {
      data.campaignGroups.forEach((campaignGroup, i) => {
        if (campaignGroup.id) {
          formData.append(`campaignGroup[${i}][id]`, campaignGroup.id);
        }
        formData.append(`campaignGroup[${i}][group]`, campaignGroup.group);
        formData.append(`campaignGroup[${i}][status]`, campaignGroup.status);
      });
    } else {
      formData.append("campaignGroup", "");
    }

    if (data.manualRecipients) {
      formData.append(
        "manualRecipients",
        JSON.stringify(data.manualRecipients),
      );
    }
    if (data.liteRecipients) {
      formData.append("liteRecipients", JSON.stringify(data.liteRecipients));
    }
    if (data.personalizedRecipients) {
      formData.append(
        "personalizedRecipients",
        JSON.stringify(data.personalizedRecipients),
      );
    }
    if (data.eliminatedRecipients) {
      formData.append(
        "eliminatedRecipients",
        JSON.stringify(data.eliminatedRecipients),
      );
    }

    if (data.broadcastRecipients) {
      formData.append(
        "broadcastRecipients",
        JSON.stringify(data.broadcastRecipients),
      );
    }

    if (
      data.eventFilters &&
      [
        ...EVENT_RECIPIENT_TYPES,
        ...PAYMENT_RECIPIENT_TYPES,
        "CONTACT",
        "COLLABORATOR",
        "FOLDER",
      ].indexOf(data.recipientType) > -1
    ) {
      formData.append("eventFilters", JSON.stringify(data.eventFilters));
    }

    /*
    if (data.recipientType === "CONTACT") {
      let min = "", max = "";
      if (typeof data.commitmentScoreRange !== 'string') {
        min = JSON.stringify(data.commitmentScoreRange.min);
        max = JSON.stringify(data.commitmentScoreRange.max);
      }

      const commitmentScore = (typeof data.commitmentScoreRange === 'string') ? data.commitmentScoreRange : [min, max].join(',');

      formData.append('commitmentScoreRange', commitmentScore);
    }*/
  }
  if (data.articleNewsletterId) {
    formData.append("articleNewsletter", data.articleNewsletterId);
  }

  if (data.id) {
    formData.append("id", data.id);
  }
  if (data.totalUaRecipients) {
    formData.append(
      "totalUaRecipients",
      JSON.stringify(data.totalUaRecipients),
    );
  }
  return axios.post(requestUrl, formData);
};

export const removeCampaign = (token, campaignId) => {
  const requestUrl = `/mailing/campaign-delete/${campaignId}`;
  const formData = new FormData();
  formData.append("access_token", token);
  return axios.post(requestUrl, formData);
};

export const duplicateCampaignData = (token, data) => {
  const requestUrl = `/mailing/campaign/duplicate`;
  const formData = new FormData();
  formData.append("access_token", token);
  formData.append("id", data.id);
  formData.append("content", data.content ? 1 : 0);
  formData.append("theme", data.theme ? 1 : 0);
  formData.append("recipient", data.recipient ? 1 : 0);
  formData.append("tests", data.tests ? 1 : 0);
  formData.append("attachments", data.attachments ? 1 : 0);
  formData.append("signature", data.signature ? 1 : 0);

  return axios.post(requestUrl, formData);
};

export const getCampaigns = (token, data, isNewsletter = false) => {
  const {
    clientId,
    emailPaginationPage,
    emailPageSize,
    status,
    language,
    subject,
    type,
    sortConfig,
    ezineNl,
    userId,
    survey,
    recipientType,
    dateRange,
    newsLetter,
    isUaVersion,
  } = data;

  let fields = [
    "id",
    "object",
    "language",
    "status",
    "type",
    "campaignGroups",
    "totalRecipients",
    "recipientType",
    "attachments",
    "newsLetter",
    "lastTestversion",
    "approved",
    "approvals",
    "uaRecipientType",
    "isUaNewsletter",
    "numberOfNewsletter",
  ];

  if (!isUaVersion) {
    fields.push("theme", "textModel", "rebroadcastedFrom", "*");
  }

  if (newsLetter === 1) {
    fields.push("pageUrl", "from", "fromName", "avatar");
  }
  const requestUrl = `/mailing/campaign`;
  const filter = [];
  if (!clientId && !userId) {
    return;
  }

  if (clientId) {
    filter.push({
      property: "client",
      value: clientId,
      operator: "eq",
    });
  }

  if (userId) {
    filter.push({
      property: "user",
      value: userId,
      operator: "eq",
    });
  }

  if (newsLetter === 1 || isNewsletter === true) {
    filter.push({
      property: "newsLetter",
      value: newsLetter,
      operator: "eq",
    });
  }

  if (status && status.length > 0) {
    filter.push({
      property: "status",
      value: status,
      operator: status instanceof Array ? "in" : "eq",
    });
  }
  if (language && language.length > 0) {
    filter.push({
      property: "language",
      value: language,
      operator: "in",
    });
  }

  if (recipientType && recipientType.length > 0) {
    filter.push({
      property: "recipientType",
      value: recipientType,
      operator: "in",
    });
  }

  if (subject) {
    filter.push({
      property: "object",
      value: subject.replace("'", ""),
      operator: "like",
    });
  }
  if (type && type.length > 0) {
    filter.push({
      property: "type",
      value: type,
      operator: "in",
    });
  }

  if (ezineNl) {
    filter.push({
      property: "ezineNl",
      value: ezineNl,
      operator: "eq",
    });
  }

  if (survey) {
    filter.push({
      property: "survey",
      value: survey,
      operator: "eq",
    });
  }

  if (!isUaVersion) {
    filter.push({
      property: "isRebroadcasted",
      value: 0,
      operator: "eq",
    });
  } else {
    filter.push({
      property: "isUaNewsletter",
      value: 1,
      operator: "eq",
    });
  }
  const sort = [];

  sort.push({
    property: sortConfig ? sortConfig.columnKey : "updatedAt",
    dir: sortConfig ? sortConfig.sortDirection : "desc",
  });

  if (dateRange && dateRange.startDate) {
    filter.push({
      property: "updatedAt",
      operator: "gte",
      value: `SPECIAL_CHAR_TO_REPLACEu0027${convertDateToUTC(
        dateRange.startDate,
      )}SPECIAL_CHAR_TO_REPLACEu0027`,
    });
  }
  if (dateRange && dateRange.endDate) {
    filter.push({
      property: "updatedAt",
      operator: "lte",
      value: `SPECIAL_CHAR_TO_REPLACEu0027${convertDateToUTC(
        dateRange.endDate,
      )}SPECIAL_CHAR_TO_REPLACEu0027`,
    });
  }

  const params = {
    access_token: token,
    fields: fields.join(","),
    limit: emailPageSize || 10,
    start: !emailPaginationPage ? 0 : (emailPaginationPage - 1) * emailPageSize,
  };

  params.filter = JSON.stringify(filter).replace(
    /SPECIAL_CHAR_TO_REPLACE/g,
    "\\",
  );
  params.sort = JSON.stringify(sort);

  return axios.get(requestUrl, { params });
};

export const getCampaign = (token, campaignId, sourceToken = null) => {
  getCampaignSourceToken = updateSourceToken(
    getCampaignSourceToken,
    sourceToken,
  );
  const filter = [{ property: "id", value: campaignId, operator: "eq" }];
  const fields = [
    "*",
    "campaignGroups",
    "theme",
    "mutatedContent",
    "mailingLists",
    "testEmails",
    "attachments",
    "ezineNl",
    "survey",
    "textModel",
    "design",
    "filters" /*'surveyInstance'*/,
    "totalUaRecipients",
    "personalizedRecipients",
    "eliminatedRecipients",
    "articleNewsletter",
    "delegationTypes",
  ];
  const requestUrl = `/mailing/campaign`;

  return axios
    .get(requestUrl, {
      cancelToken: getCampaignSourceToken.token,
      params: {
        access_token: token,
        fields: fields.join(","),
        filter: JSON.stringify(filter),
      },
    })
    .catch((thrown) => {
      throwCatchedError(thrown);
    });
};

export const registerEmail = (token, email) => {
  const requestUrl = `/mailing/verify-email`;
  const formData = new FormData();
  formData.append("access_token", token);
  formData.append("email", email);
  return axios.post(requestUrl, formData);
};

export const getEmails = (token, clientId, data, sourceToken = null) => {
  getEmailsSourceToken = updateSourceToken(getEmailsSourceToken, sourceToken);
  const fields = ["*", "user", "userAvatar"];
  const requestUrl = `/profile/email`;
  const filter = [
    {
      property: "organization",
      value: clientId,
      operator: "eq",
    },
  ];

  let params = {};
  if (data.email) {
    filter.push({
      property: "name",
      value: escape(data.email.trim()),
      operator: "like",
    });
  }

  if (!data.authorizedOnly) {
    filter.push({
      property: "contactClient.client",
      value: data.authUserAdminRoleClients,
      operator: "in",
    });
  }
  if (data.start) {
    params.start = data.start;
    params.limit = data.limit;
  }
  const sort = [
    {
      property: "email",
      dir: "asc",
    },
  ];

  return axios
    .get(requestUrl, {
      cancelToken: getEmailsSourceToken.token,
      params: {
        ...params,
        access_token: token,
        fields: fields.join(","),
        filter: JSON.stringify(filter),
        sort: JSON.stringify(sort),
        start: data.start,
        limit: data.limit,
      },
    })
    .catch((thrown) => {
      throwCatchedError(thrown);
    });
};

export const getMembershipClientsList = (
  token,
  data,
  paginationPage = null,
  pageSize = null,
) => {
  const fields = ["*", "user"];
  const requestUrl = `/organization/membership-order/list`;

  const filter = [];
  if (data.year) {
    filter.push({
      property: "year",
      value: data.year,
      operator: "eq",
    });
  }

  if (data.status || data.statut) {
    filter.push({
      property: "status",
      value: data.status ?? data.statut,
      operator: "in",
    });
  }

  if (data.formulas.length > 0) {
    filter.push({
      property: "formulas",
      value: data.formulas,
      operator: "in",
    });
  }

  if (data.source) {
    filter.push({
      property: "source",
      value: data.source,
      operator: "eq",
    });
  }

  const sort = [
    {
      property: "createdAt",
      dir: "desc",
    },
  ];

  let params = {
    access_token: token,
    fields: fields.join(","),
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort),
    workspace: "admin",
  };

  if (paginationPage && pageSize) {
    params = {
      ...params,
      limit: pageSize,
      start: (paginationPage - 1) * pageSize,
    };
  } else {
    params = { ...params, limit: 10000 };
  }

  return axios
    .get(requestUrl, {
      params,
    })
    .catch((thrown) => {
      throwCatchedError(thrown);
    });
};

export const getFormulasStats = (token, data) => {
  const requestUrl = `/organization/membership-order/stats`;

  let params = {
    access_token: token,
    year: data.year,
    status: data.status,
  };

  if (data.source) {
    params = { ...params, source: data.source };
  }

  return axios
    .get(requestUrl, {
      params,
    })
    .catch((thrown) => {
      throwCatchedError(thrown);
    });
};

export const updateStatus = (token, data) => {
  if (!data.id) {
    return;
  }
  const requestUrl = `/mailing/campaign/${data.id}`;
  const formData = new FormData();
  formData.append("access_token", token);
  formData.append("status", data.status);

  return axios.post(requestUrl, formData);
};

export const completeCampaign = (token, data) => {
  if (!data.id) {
    return;
  }
  const requestUrl = `/mailing/campaign/complete`;
  const formData = new FormData();
  formData.append("access_token", token);
  formData.append("id", data.id);
  if (data.emails) {
    formData.append("emails", JSON.stringify(data.emails));
  }

  return axios.post(requestUrl, formData);
};

export const getCampaignEmails = (token, campaignId) => {
  const requestUrl = `/mailing/campaign/emails`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      campaignId,
    },
  });
};

export const getCampaignBroadcasters = (token, broadcastedCampaign) => {
  const requestUrl = `/mailing/campaign-recipient/getBroadcastRecipients`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      broadcastedCampaign: broadcastedCampaign,
    },
  });
};

export const getCampaignNewTargetsList = (
  token,
  campaignId,
  paginationPage,
  pageSize,
) => {
  const requestUrl = `/mailing/new-targets-list/${campaignId}`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      limit: pageSize,
      start: (paginationPage - 1) * pageSize,
    },
  });
};

export const changeCampaignScheduledAtDate = (token, data) => {
  if (!data.id) {
    return;
  }
  const requestUrl = `/mailing/campaign/change-scheduled-at`;
  const formData = new FormData();
  formData.append("access_token", token);
  formData.append("id", data.id);
  if (data.scheduledDeliveryAt) {
    formData.append(
      "scheduledDeliveryAt",
      convertDateToUTC(data.scheduledDeliveryAt),
    );
  }

  return axios.post(requestUrl, formData);
};

export const saveRecipientsCriteria = (token, data, clientId, userId) => {
  const requestUrl = `/mailing/recipient-criteria`;
  const formData = new FormData();
  formData.append("access_token", token);
  formData.append("client", clientId);
  formData.append("creator", userId);
  formData.append("title", data.title);

  formData.append("criteria", data.criteria);
  formData.append("criteriaFilter", JSON.stringify(data.criteriaFilter));

  return axios.post(requestUrl, formData);
};

export const getRecipientsCriteria = (token) => {
  const requestUrl = `/mailing/recipient-criteria`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
    },
  });
};

export const getLastSentCampaign = (token, organization) => {
  const requestUrl = `/mailing/campaign/last-sent-campaign`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization,
    },
  });
};

export const getCountsRecipients = (
  token,
  organization,
  recipientTypes,
  groupsFilter,
) => {
  const requestUrl = `/mailing/campaign/get-counts-recipients`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization,
      recipientTypes: recipientTypes,
      groupsFilter: groupsFilter,
    },
  });
};

export const getDelegationTypes = (token, clientId, sourceToken = null) => {
  getDelegationTypesSourceToken = updateSourceToken(
    getDelegationTypesSourceToken,
    sourceToken,
  );

  let requestUrl = `/organization/delegationType`;

  let params = {
    access_token: token,
  };

  const filters = [
    {
      property: "organization",
      value: clientId,
      operator: "eq",
    },
    {
      property: "isNlSender",
      value: 1,
      operator: "eq",
    },
  ];

  if (filters.length > 0) {
    params.filter = JSON.stringify(filters);
  }

  return axios.get(requestUrl, {
    cancelToken: getDelegationTypesSourceToken.token,
    params: params,
  });
};
