export const fetchTextModels = (resp) => {
  return {
    type: 'FETCH_TEXT_MODELS',
    payload: resp
  };
};

export const saveTextModel = (resp) => {
  return {
    type: 'SAVE_TEXT_MODEL',
    payload: resp
  };
};

export const initTextModels = () => {
  return {
    type: 'INIT_TEXT_MODELS',
  };
};

export const setTextModelsPaginationPage = (pageNumber) => {
  return {
    type: 'SET_TEXT_MODELS_PAGINATION_PAGE',
    pageNumber
  }
}

export const setTextModelsSearchWord = (searchWord) => {
  return {
    type: 'SET_TEXT_MODELS_SEARCHWORD',
    searchWord
  }
}

export const setTextModelsLanguage = (language) => {
  return {
    type: 'SET_TEXT_MODELS_LANGUAGE',
    language
  }
}

export const setTextModelsOnlyMain = (onlyMain) => ({
  type: 'SET_TEXT_MODELS_ONLY_MAIN',
  payload: onlyMain
});

export const setTextModelsTargetApp = (targetApp = "") => {
  return {
    type: 'SET_TEXT_MODELS_TARGET_APP',
    targetApp
  }
}

export const setTextModelsTargetType = (targetType) => {
  return {
    type: 'SET_TEXT_MODELS_TARGET_TYPE',
    targetType
  }
}

export const setTextModelsScope = (scope) => {
  return {
    type: 'SET_TEXT_MODELS_SCOPE',
    scope
  }
}

export const setSelectedTextModel = (textModel) => {
  return {
    type: 'SET_SELECTED_TEXT_MODEL',
    textModel
  }
}

export const removeTextModel = (resp) => {
  return {
    type: 'REMOVE_TEXT_MODEL',
    payload: resp
  };
};

export const setFreeSectionDatePickerDateTime = (dateTime) => {
  return {
    type: 'SET_FREE_SECTION_DATE_PICKER_DATE_TIME',
    dateTime
  };
};

export const setFreeSectionDatePickerDate = (date) => {
  return {
    type: 'SET_FREE_SECTION_DATE_PICKER_DATE',
    date
  };
};

export const setFreeSectionDatePickerTime = (time) => {
  return {
    type: 'SET_FREE_SECTION_DATE_PICKER_TIME',
    time
  };
};

export const sendTextModelTest = (resp) => {
  return {
    type: 'SEND_TEXT_MODEL_TEST',
    payload: resp
  };
};
