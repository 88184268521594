import * as actions from 'actions';
import { getMediaImages, getMailingImages, persistMedia,persistMediaImage } from 'TamtamAPI';

export const fetchMedias = (mediaType = []) => (
    dispatch,
    getState
) => {

    const { currentClient, token } = getState().auth;
    const { origin, filter } = getState().medias;
    const { searchWord, pageSize, paginationPage, language, tags, scope } = filter;
    const clientId = currentClient.id;
    const getImages = ('MEDIA' == origin) ?
        getMediaImages({
            token,
            clientId,
            pageSize,
            paginationPage,
            searchWord,
            language,
            tags,
            scope,
            docType: 'IMAGE'
        }) :
        getMailingImages({
            token,
            clientId,
            pageSize,
            paginationPage,
            searchWord,
            language,
            mediaType
        });

    return dispatch(
        actions.fetchMedias(
            getImages.then(res => ({ data: ((res && res.data) ? res.data : res), origin }))
        )
    );
};

export const saveMedia = (data) => {
    return (dispatch, getState) => {
        const { token, currentClient } = getState().auth;
        data.clientId = currentClient.id;

        return dispatch(actions.saveMedia(
            persistMedia(token, data)
        ));
    };
};
export const saveMediaImage = (file) => {
    return (dispatch, getState) => {
        const { token } = getState().auth;

        return dispatch(actions.saveMedia(
            persistMediaImage(token, file)
        ));
    };
};


