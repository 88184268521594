import React, { Component } from 'react';
import { connect } from 'react-redux';

import _ from 'i18n';
import { setCurrentEmailCampaignData, setCurrentDndTheme } from '../../../actions/actions';
import { hasValidLength } from '../../../services/utils';

class SubjectBox extends Component {
  state = {
    objectHasError: false,
    currentValue: this.props.currentDndTheme?.themeObject ?? this.props.object ?? ''
  };

  componentDidMount() {
    this.props.onRef ? this.props.onRef(this) : {};
    if (this.state.currentValue){
      this.props.setCurrentEmailCampaignData({ object: this.state.currentValue });
    }
  }

  subjectFocusHandler(e) {
    const savedValue = e.target.value;
    const { onFocus } = this.props;
    e.target.value = "";
    e.target.baseScrollHeight = e.target.scrollHeight;
    e.target.value = savedValue;
    if (onFocus) {
      onFocus();
    }
  }

  subjectInputHandler() {
    let textArElement = document.getElementsByClassName('subject-box');
    if (this.props.object.length && textArElement && textArElement.length > 0) {
      textArElement[0].style.height = "0px";
      textArElement[0].style.height = textArElement.item(0).scrollHeight + 'px';
    }
  }

  handleObjectChange = (e) => {
    const object = (e.target) ? e.target.value : e;
    this.props.setCurrentEmailCampaignData({ object });
    if (this.props.currentDndTheme){
      this.props.setCurrentDndTheme({...this.props.currentDndTheme, themeObject: object});
    } else if (this.props.currentTheme){
      this.props.setCurrentDndTheme({...this.props.currentTheme, themeObject: object});
    }
    this.setState({ objectHasError: !hasValidLength(object, 5, 200), currentValue: object });
  };

  render() {
    const { object, disabled, isDragAndDropEditor } = this.props;
    const { objectHasError, currentValue } = this.state;

    return (
      <div className={`object-container ${this.props.classname ?? ''}`}>
        <textarea
          rows={2}
          className={`subject-box ${disabled ? "disabled" : ""}  ${object || currentValue || isDragAndDropEditor  ? "filled-box" : "empty-box"} ${objectHasError ? 'is-invalid-input' : ""}`}
          placeholder={_('subject')}
          onChange={this.handleObjectChange}
          onFocus={this.subjectFocusHandler.bind(this)}
          onInput={this.subjectInputHandler.bind(this)}
          value={this.state.currentValue}
          style={isDragAndDropEditor ? { minHeight: `15px`, height: '35px' } : { minHeight: `50px` }}
        >{object}</textarea>
        <span className={objectHasError ? 'form-error is-visible' : 'form-error'}> {_('invalidObject')}</span>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  object: state.currentCampaign.email.object,
  currentCampaignTheme: state.currentCampaign.email.theme,
  currentDndTheme: state.themes.currentDndTheme,
})

const mapDispatchToProps = dispatch => ({
  setCurrentEmailCampaignData: data => dispatch(setCurrentEmailCampaignData(data)),
  setCurrentDndTheme: data => dispatch(setCurrentDndTheme(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SubjectBox);
