export const fetchMedias = (resp, docType) => {
  return {
    type: 'FETCH_MEDIAS',
    payload: resp,
    docType
  };
};

export const setMediasPaginationPage = (pageNumber) => {
  return {
    type: 'SET_MEDIAS_PAGINATION_PAGE',
    pageNumber
  };
};

export const setMediasSearchWord = (searchWord) => {
  return {
    type: 'SET_MEDIAS_SEARCH_WORD',
    searchWord
  }
};

export const setMediasLanguage = (language) => {
  return {
    type: 'SET_MEDIAS_LANGUAGE',
    language
  }
};

export const setMediasOrigin = (origin) => {
  return {
    type: 'SET_MEDIAS_ORIGIN',
    origin
  }
};

export const initMediasFilters = () => {
  return {
    type: 'INIT_MEDIAS_FILTERS'
  }
}

export const setMediasTags = (tags) => {
  return {
    type: "SET_MEDIAS_TAGS",
    tags
  }
}

export const setMediasScope = (scope) => {
  return {
    type: "SET_MEDIAS_SCOPE",
    scope
  }
}

export const setMediasListSort = (sortDirection) => {
  return {
    type: 'SET_MEDIAS_LIST_SORT',
    sortDirection
  }
}

export const saveMedia = (resp) => {
  return {
    type: 'SAVE_MEDIA',
    payload: resp
  };
};
export const uploadImage = (resp) => {
  return {
    type: 'SAVE_MEDIA_IMAGE',
    payload: resp
  };
};
export const initMedias = () => {
  return {
    type: 'INIT_MEDIAS',
  }
};

