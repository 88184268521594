import React, { Component } from "react";
import { connect } from 'react-redux';

import _ from 'i18n';
import {setEventsFilters} from 'actions';
import {fetchEvents} from 'thunks';
import { PARTNER_TYPES } from 'Common';
import TTPSelect from "common/TTPSelect";
import {
  sortEventByStartDateTime, transformDateFormat,
  ucFirst
} from "../../../../../services/common";

@connect((store) => {
  return {
    events: store.filters.events.items,
    language: store.params.lng,
    eventFilters: store.currentCampaign.email.eventFilters,
    campaignLanguage: store.currentCampaign.email.language
  }
},(dispatch) => {
  return {
    handleEventChange: (eventId) => dispatch(setEventsFilters({property: 'event.id', operator: 'eq', value: eventId})),
    handleTypeChange: (types) => dispatch(setEventsFilters({property: 'type', operator: 'in', value: (types && types.length>0)?types:""})),
    fetchEvents: () => dispatch(fetchEvents()),
  }
})
export default class PartnerFilters extends Component {

  componentWillMount(){
    this.props.fetchEvents();
  }

  componentWillReceiveProps(nextProps) {
    const {events, handleEventChange, eventFilters} = nextProps;
    if(events && events.length>0 && eventFilters.length == 0){
      handleEventChange(events[0]['id']);
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      eventsType: 'future',
    };
  }

  render () {
    const {events, campaignLanguage, handleEventChange, handleTypeChange, eventFilters} = this.props;
    const { eventsType } = this.state;

    let selectedEvent = null;
    let selectedTypes = null;

    for(let filter of eventFilters){
      if('event.id' == filter.property){
        selectedEvent = filter.value;
      }
      if('type' == filter.property){
        selectedTypes = filter.value;
      }
    }

    let campaignLng = ucFirst(campaignLanguage);
    let sortedEvents = sortEventByStartDateTime(events, 'DESC', eventsType)
    let eventOptions = sortedEvents?.map(event => {
      const getEventName = () => {
        if (campaignLng !== 'All' && event[`name${campaignLng}`]) {
          return event[`name${campaignLng}`];
        }
        // Fallback order: Fr -> Nl -> En
        return event['nameFr'] || event['nameNl'] || event['nameEn'] || 'Unnamed Event';
      };

      const eventName = getEventName();
      const eventStartDate = transformDateFormat(event['startDateTime']);

      return {
        label: `${eventStartDate} || ${eventName}`,
        id: event.id.toString()
      };
    });

    let partnerTypes = PARTNER_TYPES.map((type, index) => {return {label: type.label, id: type.id};});

    return (
      <div className="row align-center">
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('event')}
              <span style={{float: "right"}}>
                <span style={{marginLeft: '10px'}}>
                    <input type="radio" name='future' style={{margin: 'unset'}}
                           defaultChecked={true}
                           checked={this.state.eventsType === 'future'}
                           onChange={() => this.setState({
                             ...this.state,
                             eventsType: 'future'
                           })}/>
                    <span className="ttp-radio"></span>
                    <span style={{marginLeft: '5px'}}>{_('future')}</span>
                  </span>
                  <span style={{margin: '0 10px 0 10px '}}>
                    <input type="radio" name='replay' style={{margin: 'unset'}}
                           defaultChecked={false}
                           checked={this.state.eventsType === 'past'}
                           onChange={() => this.setState({
                             ...this.state,
                             eventsType: 'past'
                           })}/>
                    <span className="ttp-radio"></span>
                    <span style={{marginLeft: '5px'}}>{_('past')}</span>
                  </span>
                </span>
            </h1>
            <TTPSelect
              simple={true}
              notClearable={true}
              values={selectedEvent}
              placeholder={_('Select event')}
              options={eventOptions}
              onChange={handleEventChange}/>
          </div>
        </div>
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">Types</h1>
            <TTPSelect
              values={selectedTypes}
              placeholder={_('all').toUpperCase()}
              options={partnerTypes}
              onChange={handleTypeChange}/>
          </div>
        </div>
      </div>
    );
  }
}
